import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';
import Button from '../t9UIComponents/t9Button';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;



const BookingInfoModal = (props) => {
  // formType {1}: company, {2}: user
  const { t } = useTranslation()
  const { formType, userList, dataObj, modalVisible, setModalVisible, handleFormSubmit} = props
  // const [targetUser, setTargetUser] = useState(0);
  // console.log('modalObj>>>', dataObj)
  // console.log('userList>>>', userList)
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // setTargetUser(dataObj.user_id)
  }, [dataObj]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  // console.log('dataObj.user_id', dataObj.user_id)
  // console.log('check', targetUser)

  const renderCompanyEditForm = () => {
    if (dataObj.status === 1) {
      return (
        <div>
          <Form.Item
            label={t('user')}
            name="user_id"
          >
            <Select
              // onChange={(value) => {
              //   setTargetUser(value);
              // }}
            >
              <Option value={0}> </Option>
              {userList}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={16} style={{textAlign: "right"}}>
            <Button
              type="primary"
              htmlType="submit"
            >
              {t('confirm_booking')}
            </Button>
          </Form.Item>
        </div>
      )
    }
    return (
      <div>
        <Form.Item
          label={t('user')}
          name="user_id"
        >
          <Select disabled>
            {userList}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={16} style={{textAlign: "right"}}>
          <Button
            type="danger"
            htmlType="submit"
          >
            {t('cancel_booking')}
          </Button>
        </Form.Item>
      </div>
    )
  }

  const renderUserEditForm = () => {
    if (dataObj.status === 1) {
      return (
        <Form.Item wrapperCol={16} style={{textAlign: "right"}}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('confirm_booking')}
          </Button>
        </Form.Item>
      )
    }
    if (dataObj.status === 10){
      return (
        <Form.Item wrapperCol={16} style={{textAlign: "right"}}>
          <Button
            type="danger"
            htmlType="submit"
          >
            {t('cancel_booking')}
          </Button>
        </Form.Item>
      )
    }
    return (
      <Form.Item wrapperCol={16} style={{textAlign: "right"}}>
          <Button
            disabled
          >
            {t('fulled')}
          </Button>
        </Form.Item>
    )
  }

  console.log('check', dataObj)

  return (
    <Modal
      title={t('details')}
      // title={dataObj.title}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        form.resetFields()
        // setTargetUser(dataObj.user_id)
        setModalVisible(false)
      }}
      bodyStyle={{paddingBottom: "1px"}}
      style={{width: '70vw', maxWidth: '350px'}}
      forceRender
    >
      <Form
        {...layout}
        form={form}
        name="BookingInfoForm"
        layout="horizontal"
        initialValues={dataObj}
        onFinish={(formData) => {
          let postObj = formData
          postObj.booking_id = dataObj.booking_id
          postObj.status = dataObj.status
          // console.log('postObj>>>>', postObj)
          if (postObj.user_id === 0) {
            notification.error({message: 'No user selected.'});
          } else {
            handleFormSubmit(postObj)
          }
        }}
      >

        <Form.Item label={t('name')}>
          {dataObj.item_name}
        </Form.Item>

        <Form.Item label={t('category')}>
          {dataObj.category_name}
        </Form.Item>

        {/* <Form.Item label={t('item')}>
          {dataObj.title}
        </Form.Item> */}


        <Form.Item label={t('start_booking_time')}>
          {moment.unix(dataObj.start_time).format('YYYY-MM-DD HH:mm')}
        </Form.Item>

        <Form.Item label={t('end_booking_time')}>
          {moment.unix(dataObj.end_time).format('YYYY-MM-DD HH:mm') }
        </Form.Item>

        <Form.Item label={t('section_duration')}>
          {dataObj.section_duration / 60 + "mins"}
        </Form.Item>

        {/* <Form.Item label={t('buffer')}>
          {dataObj.buffer / 60 + "mins"}
        </Form.Item> */}

        <Form.Item label={t('section_price')}>
          {'$' + dataObj.section_price}
        </Form.Item>
        
        {formType === 1 ? renderCompanyEditForm(): renderUserEditForm()}
      </Form>
    </Modal>
  )
}

export default BookingInfoModal;
