import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import debugLib from 'debug';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserQuotaListTable from '../../components/UserQuotaListTable';
import UserQuotaModal from '../../components/UserQuotaModal';
import UserQuotaTable from '../../components/UserQuotaList';

const debug = debugLib('app:pages/company/quota/list');

const selectedKey = "user_quota_management";

const UserQuotaContainer = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()

  const title = t('user_quota_management');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    try {
      // user quota
      let url = "/api/user_quota/list";
      let response = await Service.call('get', url);
      // console.log("response>>>", response);
      dataList = response;
      //dataList = _.orderBy(response, ["status", "expiration_date"], ["aces", "aces"])

      if (dataList != []) {
        //  user
        url = `/api/user/list/${dataList[0]['company_id']}`;
        let userArr = await Service.call('get', url);
        let userArrMap = _.keyBy(userArr, "user_id");

        // company user
        url = '/api/company/user';
        let companyUserArr = await Service.call('get', url);
        let companyUserArrMap = _.keyBy(companyUserArr["companyUsers"], "company_user_id");

        // item info
        url = '/api/item/category/list';
        let categoryArr = await Service.call('get', url);
        let categoryArrMap = _.keyBy(categoryArr, "item_category_id");

        // mapping
        _.each(response, (quotaItem, i) => {
          dataList[i].username = userArrMap[quotaItem.user_id].first_name + ' ' + userArrMap[quotaItem.user_id].last_name;
          // dataList[i].companyUsername = companyUserArrMap[quotaItem.company_user_id].first_name + ' ' + companyUserArrMap[quotaItem.company_user_id].last_name;
          dataList[i].item_category_id = categoryArrMap[quotaItem.item_category_id].item_category_id
          dataList[i].category_name = categoryArrMap[quotaItem.item_category_id].category_name;
        })
      }

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      // console.log(dataList);
      setDataList(dataList);
      setLoading(false)
    }
  }

  const handleFormSubmit = async (submitRecord) => {
    let submit = await Service.call('patch', '/api/user_quota', submitRecord)
    getAllData()
    setModalVisible(false)
    // if (selectedRecord.item_category_id === 0) {
    //   handlePutRecord(submitRecord)
    // }
    // if (selectedRecord.item_category_id > 0) {
    //   handlePatchRecord(submitRecord)
    // }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true)
    let submit = await Service.call('put', '/api/item/category', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      item_category_id: selectedRecord.item_category_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/item/category', patchData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true)
    let postData = {
      item_category_id: deleteRecord,
    }
    let submit = await Service.call('put', '/api/web/delete', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }


  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <UserQuotaTable
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        setModalVisible={(value) => setModalVisible(value)}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        dataList={dataList}
        getAllData={getAllData}
        deleteRecord={(record) => handleDeleteRecord(record)}
        particularUserView={false}
        loading={loading}
      />
    </NavAndSideFrame>
  )
}

export default UserQuotaContainer;