import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Badge, Select, notification
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Main from '../../core/Main';
import BookingInfoModal from '../../components/BookingInfoModal';
// import BookingInfoModal from '../../components/BookingInfoModal';
import 'moment/locale/zh-tw';

const { Option } = Select;

const localizer = momentLocalizer(moment)

const UserBookingListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Item Category";
  const selectedKey = 3;
  const tableIDName = "item_category_id";
  // let [eventList, setEventList] = useState([]);
  // const [eventList2, seteventDataList2] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [eventObj, setEventObj] = useState([]);
  const [userList, setUserList] = useState([])
  const [bookStatus, setbookStatus] = useState([])
  const app = useSelector((state) => state.app);

  const [dataList, getCalendarData] = useState(props.dataObj);
  let { serverAction, BookingItem, callBack, loading,selectDateCallBack } = props
  let formType = 1
  let eventList = []
  if (BookingItem.length != 0) {
    eventList = BookingItem
  } else {
    eventList = []
  }
  // console.log('booking_item', props.BookingItem)

  const lookDetail = async (event) => {

    let categoryArr = await Service.call('get', "/api/item/category/list");
    let categoryArrMap = _.keyBy(categoryArr, "item_category_id")
    event.category_name = categoryArrMap[event.item_category_id].category_name

    let userArr = await Service.call('get', "/api/user/list");
    let userArrMap = []
    _.forEach(userArr, (user) => {
      userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.first_name + " " + user.last_name}</Option>)
    })

    setUserList(userArrMap)

    let getbookStatus = await Service.call('get', "/api/booking_item/status");
    //statusDataList = getbookStatus
    setEventObj(event)

    //setbookStatus()
    setModalVisible(true)
  }

  const renderBackgroundColor = (status) => {
    let backgroundColor = ""
    switch (status) {
      case 1:
        backgroundColor = "#003E80"
        break;
      case 10:
        backgroundColor = "#FF1A53"
        break;
      default:
        backgroundColor = "#FF1A53"
    }
    return backgroundColor
  }

  const calendarStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event.status),
        borderColor: 'rgba(255, 255, 255, 0.3)'
      }
    }
  }

  const handleFormSubmit = async (submitRecord) => {
    // console.log('submitRecord>>>', submitRecord)
    // setLoading(true)

    let patchData = {
      status: submitRecord.status,
      user_id: submitRecord.user_id,
      booking_id: submitRecord.booking_id
    }
    console.log('patchData>>>', patchData)
    let response
    if (patchData && patchData.status === 1) {
      response = await Service.call('patch', '/api/booking_item/confirm', patchData)
    }
    if (patchData && patchData.status === 10) {
      response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    }
    console.log('response>>>', response)
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t('insufficient_quota'),
      });
      return
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      callBack()
      setModalVisible(false)
    }
  }

  // const selectDate = (prevOrNext,focusWeek) => {

  //   let nextWeek = moment(focusWeek).endOf('week').unix()
  //   let previousWeek = moment(focusWeek).startOf('week').unix()
  //   console.log("previousWeek", nextWeek,previousWeek)
  // }



  return (
    <Layout>
      <Calendar
        // selectable
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%", background: "white" }}
        defaultView={Views.WEEK}
        views={['day', 'week']}
        messages={{
          day: t('day'),
          week: t('week'),
          today: t('today'),
          next: t('next'),
          previous: t('previous')
        }}
        // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDate(prevOrNext,focusWeek)}
        onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDateCallBack(prevOrNext,focusWeek)}
        // components={(value)=>{console.log('check', value)}}

        // view="week"
        // views={{ 'month': true}}
        onSelectEvent={(event) => lookDetail(event)}
        // onSelectSlot={(slotInfo) => addNewEvent(slotInfo)}
        eventPropGetter={(event) => calendarStyle(event)}
      // for one day change color
      // dayPropGetter={calendarStyle}
      // defaultDate={new Date(2015, 3, 1)}
      />

      <BookingInfoModal
        formType={formType}
        //userObj={userObj}
        userList={userList}
        dataObj={eventObj}
        //eventObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getCalendarData()
            //getData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />

      {/* <BookingInfoModal
        formType={3}
        userList={userList}
        dataObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getCalendarData()
            //getData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />  */}

    </Layout>


  )

}

export default UserBookingListTable;