import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';
import UserQuotaListTable from './UserQuotaListTable';
import Button from '../t9UIComponents/t9Button';
import UserQuotaModal from './UserQuotaModal';

const UserQuotaList = (props) => {
  const { t } = useTranslation()
  let location = useLocation();
  let { id } = queryString.parse(location.search);
  const { setModalVisible, setSelectedRecord, modalVisible, dataList, getAllData, handleDeleteRecord, loading, selectedRecord, handleFormSubmit, particularUserView, user_id } = props

  if (id) {
    id = _.toInteger(id)
  }

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({
            user_quota_id: 0,
            user_id: id
          })
          // setEditOption(true)
        }}
      >
        {t('add_user_quota')}
      </Button>
      <Divider />
      <UserQuotaListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        modalVisible={modalVisible}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        deleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading}
      />
      <UserQuotaModal
        selectedRecord={selectedRecord}
        categoryList={dataList}
        modalVisible={modalVisible}
        particularUserView={particularUserView}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </div>
  )

}

export default UserQuotaList;
