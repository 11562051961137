import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useSelector } from 'react-redux';

const CompanyUserBookingLogListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "booking_log_id";
  const app = useSelector((state) => state.app);

  const [dataList, setDataList] = useState(props.dataObj);

  let { LogtList, setModalVisible, setSelectedRecord, loading, deleteRecord, isActiveChangeCallBack } = props



  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('user_id'),
        dataIndex: 'user_id',
        sorter: (a, b) => a.user_id - b.user_id,
      },
      {
        title: t('first_name'),
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name - b.first_name,
      },
      {
        title: t('last_name'),
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name - b.last_name,
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name - b.item_name,
      },
      {
        title: t('start_booking_time'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (start_time) => {
          return moment.unix(start_time).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        title: t('end_booking_time'),
        dataIndex: 'end_time',
        sorter: (a, b) => a.end_time - b.end_time,
        render: (end_time) => {
          return moment.unix(end_time).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        title: t('section_duration'),
        dataIndex: 'section_duration',
        sorter: (a, b) => a.section_duration - b.section_duration,
        render: (value) => {
          return Math.round(value / 60) + t('mins')
        },
      },
      {
        title: t('section_price'),
        dataIndex: 'section_price',
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('quota_required'),
        dataIndex: 'quota',
        sorter: (a, b) => a.quota - b.quota,
      },
      {
        title: t('relevant_admin'),
        dataIndex: 'companyUserName',
        sorter: (a, b) => a.companyUserName - b.companyUserName,
      },
      {
        title: t('is_active'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },

      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: t('booking_type'),
        dataIndex: 'booking_type',
        render: (value) => displayChangeType(value),
        sorter: (a, b) => a.booking_type - b.booking_type
      },

      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD')
        },
      }
    ];
    return columns;
  }

  const displayChangeType = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("system");
        tagColor = 'blue';
        break;
      case 2:
        displayStr = t("admin_manual");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayPrebookingType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("one_week_before");
        break;
      case 2:
        displayStr = t("one_month_before");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayRepeatType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("weekly");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 0:
        displayStr = t("hide");
        tagColor = 'red';
        break;
      case 1:
        displayStr = t('available');
        tagColor = 'green';
        break;
      case 10:
        displayStr = t("confirm_booked");
        tagColor = 'blue';
        break;
      case -1:
        displayStr = t('fail');
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={LogtList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>

  )

}

export default CompanyUserBookingLogListTable;