import React, { useState, useEffect } from "react";
import { Divider, Row, Col, Button, Typography, Form, Input, message } from "antd";
import _ from "lodash";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import * as CommonActions from "../../redux/actions/common";
import * as Service from "../../core/Service";
import { MobileView, DesktopView } from "./ResponsiveView";
import { getAssetsFromData } from "./resultAssetHelper";

import QuestionnaireResultImage_zh from "../../assets/330/newlife_CHI_web_4x-46.png";
import QuestionnaireResultImage_en from "../../assets/330/newlife_ENG_web_4x-21.png";
import fb from "../../assets/330/newlife_CHI_web_4x-29.png";
import Result1_en from "../../assets/330/230308_result_ENG_01.png";
import Result1_tc from "../../assets/330/230308_result_CHI_01.png";
import Result2_en from "../../assets/330/230308_result_ENG_02.png";
import Result2_tc from "../../assets/330/230308_result_CHI_02.png";
import Result3_en from "../../assets/330/230308_result_ENG_03.png";
import Result3_tc from "../../assets/330/230308_result_CHI_03.png";

const { Text } = Typography;

const resultAssetMap = new Map([
  [3, {
    image: { en: Result3_en, zh_hant: Result3_tc },
  },
  ],
  [2, {
    image: { en: Result2_en, zh_hant: Result2_tc },
  },
  ],
  [1, {
    image: { en: Result1_en, zh_hant: Result1_tc },
  },
  ],
]);

const AnchorElement = ({ url }) => (
  <a
    target="_blank"
    rel="noreferrer noopener"
    href={url}
    style={{ fontSize: 20, color: "#3366CC" }}
  >
    {url}
  </a>
);

const ResultDisplayBox = ({ resultValue, imageWidth = "100%" }) => {
  const { t, i18n } = useTranslation();

  const { resultText1, textColor, image } =
    resultAssetMap.get(resultValue);

  return (
    <img
      alt=""
      src={image[i18n.language]}
      style={{ width: imageWidth, margin: "auto" }}
    />
  );
};

const QuestionnaireResult = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [typeAsset, setTypeAsset] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInitialData();
    setLoading(false);
    window.scrollTo(0, 0)
  }, []);

  const getInitialData = async () => {
    const data = await Service.call("get", "/api/response/result");
    const assets = getAssetsFromData(data);
    setTypeAsset(assets);
    dispatch(CommonActions.setFormData({}));
  };

  const onFinish = async (formData) => {
    const result = await Service.call('patch', '/api/response_user', formData)
    if (result.status <= 0) {
      return message.error(result.errorMessage)
    }
    message.success(t("submit_success"));
  };

  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=https://dayday330wellbeingindex.newlife330.hk&quote=${t(
    "questionnair_button"
  )}`;
  // console.log('facebookShareLink', facebookShareLink)

  if (loading || !typeAsset || !typeAsset.typeValue) return null;
  // console.log('typeAsset', typeAsset)

  return (
    <Row gutter={[0, 30]} align="middle">
      <Col span={24} style={{ textAlign: "center" }}>
        <MobileView>
          <img
            alt=""
            src={
              i18n.language === "en"
                ? QuestionnaireResultImage_en
                : QuestionnaireResultImage_zh
            }
            style={{ width: "80%", margin: "auto", marginBottom: 20 }}
          />
          <br />
          <div
            style={{
              margin: "30px auto",
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 32,
                fontWeight: "bold",
                whiteSpace: "pre-wrap",
              }}
            >
              {i18n.language === "en"
                ? "Thank you for participating,\nHere are your result"
                : "感謝您的參與,\n您的結果如下"}
            </Text>
          </div>
        </MobileView>
        <DesktopView>
          <img
            alt=""
            src={
              i18n.language === "en"
                ? QuestionnaireResultImage_en
                : QuestionnaireResultImage_zh
            }
            style={{ width: "50%", margin: "auto", marginBottom: 20 }}
          />
          <br />
          <div
            style={{
              margin: "15px auto",
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 30,
                fontWeight: "bold",
              }}
            >
              {t("result_page_title")}
            </Text>
          </div>
        </DesktopView>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 24]}>
          <Col xs={24} sm={24} md={13} lg={13}>
            <MobileView>
              <div
                style={{
                  ...styles.resultBoxContent,
                  maxWidth: "90%",
                  margin: "auto",
                }}
              >
                <ResultDisplayBox
                  indexCategory={1}
                  resultValue={typeAsset.typeValue}
                  imageWidth="95%"
                />
              </div>
            </MobileView>
            <DesktopView>
              <div
                style={{
                  ...styles.resultBoxContent,
                  marginRight: 45,
                }}
              >
                <ResultDisplayBox
                  indexCategory={1}
                  resultValue={typeAsset.typeValue}
                />
              </div>
            </DesktopView>
          </Col>
          <Col xs={24} sm={24} md={11} lg={11}>
            {/* No need margin-top from Text component */}
            <div style={{ ...styles.title, color: "#fff" }}>
              {t(typeAsset.title)}
            </div>
            <br />
            <Text
              style={{ ...styles.text, fontSize: 20, whiteSpace: "pre-wrap" }}
            >
              {t(typeAsset.desc)}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col>
        <Text style={{ ...styles.title, color: "#fff" }}>
          {t("wbs_tips_title")}
        </Text>
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>
          {t("wbs_tips_desc_1")}
        </Text>
        <br />
        <AnchorElement url="https://youtube.com/playlist?list=PLr8HXjSCTh4UD0cs9M2BuHTPSbSESpvS_" />
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>
          {t("wbs_tips_desc_2")}
        </Text>
        <br />
        <AnchorElement url="https://dayday330.newlife330.hk" />
        <br />
        <AnchorElement url="https://www.facebook.com/newlife330hk" />
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20, whiteSpace: "pre-wrap" }}>
          {t("wbs_tips_desc_3")}
        </Text>
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>Android: </Text>
        <AnchorElement url="https://goo.gl/xXcCSC" />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>iOS: </Text>
        <AnchorElement url="http://appstore.com/newlife330" />
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>
          {t("wbs_tips_desc_4")}
        </Text>
        <br />
        <AnchorElement url="https://www.nlpra.org.hk/tc/support/support%20usvolunteers/104" />
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>
          {t("wbs_tips_desc_5")}
        </Text>
        <br />
        <AnchorElement url="https://www.egps.hk" />
        <br />
        <br />
        <Text style={{ ...styles.text, fontSize: 20 }}>
          {t("wbs_tips_desc_6")}
        </Text>
        <br />
        <AnchorElement
          url={`https://www.shallwetalk.hk/${
            i18n.language === "en" ? "en" : "zh"
          }`}
        />
        <br />
        <br />
      </Col>
      <Divider
        style={{
          ...styles.divider,
          borderColor: "#fff",
          borderWidth: 2,
          marginBottom: 30,
        }}
      />
      <Text style={{ ...styles.text, fontSize: 20, marginBottom: 20 }}>
        {t("result_contact_us_1")}
      </Text>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[48, 12]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={<Row style={{ ...styles.label }}>{t("name")}</Row>}
              name="name"
            >
              <Input
                className="custom-input"
                placeholder={t("name")}
                style={{ ...styles.input }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              label={<Row style={{ ...styles.label }}>{t("email")}</Row>}
              name="email"
              rules={[{ type: "email", message: t("email_invalid") }]}
            >
              <Input
                className="custom-input"
                placeholder={t("email")}
                style={{ ...styles.input }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Text style={{ ...styles.text, fontSize: 20 }}>
              {t("result_contact_us_2")}
            </Text>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            style={{ margin: "auto", marginTop: 10, marginBottom: 20 }}
          >
            <Form.Item style={{ ...styles.formItem }}>
              <Button htmlType="submit" style={{ ...styles.button }}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider
        style={{ ...styles.divider, borderColor: "#fff", borderWidth: 2 }}
      />
      <MobileView>
        <Text style={{ ...styles.text, fontSize: 68, fontWeight: "bold" }}>
          SHARE
        </Text>
        <Row gutter={[0, 8]} align="middle">
          <Col span={3}>
            <img alt="" src={fb} style={{ width: "100%", margin: "auto" }} />
          </Col>
          <Col>
            <a href={facebookShareLink} target="_blank" rel="noreferrer">
              <Text style={{ color: "#fff", fontSize: 24, marginLeft: 15 }}>
                {t("share_to")}
                Facebook
              </Text>
            </a>
          </Col>
        </Row>
        <Col style={{ paddingTop: 0 }}>
          <Text style={{ ...styles.text, fontSize: 20 }}>
            {t("share_will_not_display")}
          </Text>
        </Col>
        <Divider
          style={{
            ...styles.divider,
            borderColor: "#fff",
            borderWidth: 2,
            margin: "20px 0px",
          }}
        />
        <Col span={24}>
          <Text style={{ ...styles.text, fontSize: 22 }}>
            {t("form_thankyou_message")}
          </Text>
          <br />
          <a
            style={{ color: "#fff", fontSize: 40 }}
            href="http://dayday330.hk/"
            target="_blank"
          >
            dayday330.hk
          </a>
        </Col>
        <Row gutter={[0, 16]}>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item style={{ ...styles.formItem }}>
              <a
                href="https://dayday330.newlife330.hk/#supportDay"
                target="_blank"
              >
                <Button
                  style={{
                    ...styles.mobileButton,
                    borderColor: "transparent",
                    backgroundColor: "#6e5333",
                    color: "#d4a12c",
                  }}
                >
                  {t("button_sign")}
                </Button>
              </a>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item style={{ ...styles.formItem }}>
              <Button
                style={{ ...styles.mobileButton }}
                onClick={() =>
                  dispatch(CommonActions.setDrawerSelectedItem("contact_us"))
                }
                htmlType="submit"
              >
                {t("contact_us")}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} span={12}>
            <Form.Item style={{ ...styles.formItem }}>
              <Button
                style={{ ...styles.mobileButton }}
                onClick={() => dispatch(CommonActions.setModalVisible(false))}
              >
                {t("button_home")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </MobileView>

      <DesktopView>
        <Col span={24}>
          <Row gutter={[48, 0]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Text style={{ ...styles.text, fontSize: 20 }}>
                    {t("form_thankyou_message")}
                  </Text>
                  <br />
                  <Text
                    style={{ ...styles.title, color: "#fff", fontSize: 32 }}
                  >
                    <a
                      style={{ ...styles.title, color: "#fff", fontSize: 32 }}
                      href="http://dayday330.hk/"
                      target="_blank"
                    >
                      dayday330.hk
                    </a>
                  </Text>
                </Col>
                <Col span={24}>
                  <Form.Item style={{ ...styles.formItem }}>
                    <a
                      href="https://dayday330.newlife330.hk/#supportDay"
                      target="_blank"
                    >
                      <Button
                        style={{
                          ...styles.button,
                          borderColor: "transparent",
                          backgroundColor: "#6e5333",
                          color: "#d4a12c",
                          fontSize: 20,
                        }}
                      >
                        {t("button_sign")}
                      </Button>
                    </a>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row>
                <Col style={{ paddingBottom: 0 }}>
                  <Row gutter={[0, 8]} align="middle">
                    <Col span={24}>
                      <Text
                        style={{
                          ...styles.text,
                          fontSize: 42,
                          fontWeight: "bold",
                        }}
                      >
                        SHARE
                      </Text>
                    </Col>
                    <Col span={3}>
                      <img src={fb} style={{ width: "70%", margin: "auto" }} />
                    </Col>
                    <Col>
                      <a
                        href={facebookShareLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text
                          style={{
                            ...styles.title,
                            color: "#fff",
                            fontSize: 18,
                          }}
                        >
                          {t("share_to")} Facebook
                        </Text>
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ paddingTop: 8 }}>
                  <Text style={{ ...styles.text, fontSize: 18 }}>
                    {t("share_will_not_display")}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[48, 12]}>
            <Col xs={24} sm={24} md={12} span={12}>
              <Form.Item style={{ ...styles.formItem }}>
                <Button
                  style={{ ...styles.button }}
                  onClick={async () => {
                    await Service.call("get", "/api/session/reset");
                    dispatch(CommonActions.setModalVisible(false));
                  }}
                >
                  {t("button_home")}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} span={12}>
              <Form.Item style={{ ...styles.formItem }}>
                <Button
                  style={{ ...styles.button }}
                  onClick={() =>
                    dispatch(CommonActions.setDrawerSelectedItem("contact_us"))
                  }
                  htmlType="submit"
                >
                  {t("contact_us")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </DesktopView>
    </Row>
  );
};

const styles = {
  button: {
    height: 50,
    fontWeight: "bold",
    borderColor: "#fff",
    color: "#d7633e",
    fontSize: 22,
    width: "100%",
    borderRadius: 12,
  },
  mobileButton: {
    height: 60,
    fontWeight: "bold",
    borderColor: "#fff",
    color: "#d7633e",
    fontSize: 24,
    width: "100%",
    borderRadius: 14,
  },
  title: {
    color: "#d7633e",
    fontSize: 26,
    fontWeight: "bold",
  },
  text: {
    color: "#6e5333",
    fontSize: 14,
  },
  divider: {
    margin: "5px 0px",
    borderTop: "1px solid #6e5333",
  },
  formItem: {
    marginBottom: 0,
  },
  resultBoxContent: {
    textAlign: "center",
    backgroundColor: "#F7F3ED",
    borderRadius: 15,
  },
  label: {
    color: '#6e5333',
    fontSize: 22,
  },
  input: {
    color: '#fff',
    borderColor: '#fff',
    fontSize: 22,
    backgroundColor: 'transparent',
    width: '100%',
    borderWidth: 2,
    borderRadius: 10,
    height: 40,
    paddingLeft: 20,
  },
};

export default QuestionnaireResult;
