import React, { useState, useEffect } from 'react'
import {
  Divider, Row, Col, Modal
} from 'antd';
import _ from 'lodash';
import {
  CloseOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';

import QuestionnaireForm from './QuestionnaireForm';
import BackgroundInfo from './BackgroundInfo';
import ContactUs from './ContactUs';
import TNC from './TNC';
import { useTranslation } from 'react-i18next';


const type = {
  drawer_list: {
    backgroundColor: '#6e5333'
  },
  background_info: {
    backgroundColor: '#e4d5b1'
  },
  questionnaire_form: {
    backgroundColor: '#d4a12c'
  },
  contact_us: {
    backgroundColor: '#6e5333'
  },
  tnc_statement: {
    backgroundColor: '#6e5333'
  }
}

const AppDrawer = () => {
  const dispatch = useDispatch()
  const modalVisible = useSelector((state) => state.app.modalVisible);
  const drawer_selected_item = useSelector((state) => state.app.drawer_selected_item);
  const [config, setConfig] = useState({
    maxWidth: 900,
    minHeight: '100vh'
  });

  useEffect(() => {
    if (!modalVisible) {
      dispatch(CommonActions.setDrawerSelectedItem('drawer_list'));
    }
  }, [modalVisible])

  useEffect(() => {
    if (drawer_selected_item === 'contact_us') {
      return setConfig({
        maxWidth: 600,
        minHeight: '50vh'
      })
    }

    setConfig({
      maxWidth: 900,
      minHeight: '100vh'
    });
  }, [drawer_selected_item])

  return (
    <Modal
      visible={modalVisible}
      style={{ maxWidth: config.maxWidth, margin: 'auto', height: '60%', minHeight: config.minHeight, top: 10 }}
      width="94%"
      centered
      closable={false}
      maskClosable={false}
      className="drawer"
      bodyStyle={{ maxWidth: config.maxWidth, margin: 'auto', backgroundColor: type[drawer_selected_item].backgroundColor, borderRadius: 15, borderColor: type[drawer_selected_item].backgroundColor, minHeight: config.minHeight }}
      footer={null}
      onCancel={() => {
        dispatch(CommonActions.setDrawerSelectedItem('drawer_list'));
        dispatch(CommonActions.setModalVisible(false));
      }}
      destroyOnClose
      closeIcon={null}
    >
      <DrawerContainer />
    </Modal>
  )
}

const DrawerContainer = () => {
  const drawer_selected_item = useSelector((state) => state.app.drawer_selected_item);

  const getItem = () => {
    switch (drawer_selected_item) {
      case 'drawer_list': return (<DrawerList />);
      case 'background_info': return (<BackgroundInfo />);
      case 'questionnaire_form': return (<QuestionnaireForm />);
      case 'contact_us': return (<ContactUs />);
      case 'tnc_statement': return (<TNC />);
      default: return <DrawerList />;
    }
  }

  return (
    <>{getItem()}</>
  )
}

const DrawerList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Row style={{ marginBottom: 60 }}>
        <CloseOutlined style={{ fontSize: 48, color: '#fff' }} onClick={() => dispatch(CommonActions.setModalVisible(false))} />
      </Row>
      <Row justify="center">
        <Col
          style={{ ...styles.text, fontSize: i18n.language === 'en' ? 20 : 26}}
          onClick={() => {
            dispatch(CommonActions.setDrawerSelectedItem('background_info'));
          }}
        >
          {t('background_info')}
          </Col>
      </Row>
      <Divider style={{ borderWidth: 2 }} />
      <Row justify="center">
        <Col 
          style={{ ...styles.text, fontSize: i18n.language === 'en' ? 20 : 26}}
          onClick={() => {
            dispatch(CommonActions.setDrawerSelectedItem('questionnaire_form'));
          }}
        >
          {t('know_your_well_being')}
        </Col>
      </Row>
      <Divider style={{ borderWidth: 2 }} />
      <Row justify="center">
        <Col 
          style={{ ...styles.text, fontSize: i18n.language === 'en' ? 20 : 26}}
          onClick={() => {
            dispatch(CommonActions.setDrawerSelectedItem('contact_us'));
          }}
        >{t('contact_us')}</Col>
      </Row>
      <Divider style={{ borderWidth: 2 }} />
      <Row justify="center">
        <Col 
          style={{ ...styles.text, fontSize: i18n.language === 'en' ? 20 : 26}}
          onClick={() => {
            dispatch(CommonActions.setDrawerSelectedItem('tnc_statement'));
          }}
        >
          {t('tnc')}
        </Col>
      </Row>
    </>
  )
}

const styles = {
  icon: {
    color: '#141E29',
    marginRight: 20,
    fontSize: 20,
    transform: 'translateY(20%)'
  },
  button: {
    height: 50,
    fontWeight: 'bold',
    borderColor: '#fff',
    color: '#d7633e',
    fontSize: 24,
    width: '100%',
    borderRadius: 12
  },
  text: {
    fontSize: 26,
    color: '#fff',
    fontWeight: '700'
  }
}

export default AppDrawer;
