import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useSelector } from 'react-redux';

const CompanyUserQuotaLogListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "company_holiday_id";
  const app = useSelector((state) => state.app);

  const [dataList, setDataList] = useState(props.dataObj);

  let { LogtList, setModalVisible, setSelectedRecord, loading, deleteRecord, isActiveChangeCallBack } = props

  console.log("call Log",LogtList)

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('user_id'),
        dataIndex: 'user_id',
        sorter: (a, b) => a.user_id - b.user_id,
      },
      {
        title: t('first_name'),
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name - b.first_name,
      },
      {
        title: t('last_name'),
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name - b.last_name,
      },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name - b.category_name,
      },
      {
        title: t('change_amount'),
        dataIndex: 'change_amount',
        sorter: (a, b) => a.change_amount - b.change_amount,
      },
      {
        title: t('balance'),
        dataIndex: 'balance',
        sorter: (a, b) => a.balance - b.balance
      },
      {
        title: t('relevant_admin'),
        dataIndex: 'companyUserName',
        sorter: (a, b) => a.companyUserName - b.companyUserName,
      },
      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: t('change_type'),
        dataIndex: 'change_type',
        render: (value) => displayChangeType(value),
        sorter: (a, b) => a.change_type - b.change_type
      },
     
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD')
        },
      }
    ];
    return columns;
  }

  const displayChangeType = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("system");
        tagColor = 'blue';
        break;
      case 2:
        displayStr = t("admin_manual");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  
  
  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayPrebookingType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("one_week_before");
        break;
      case 2:
        displayStr = t("one_month_before");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayRepeatType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("weekly");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 2:
        displayStr = t("waiting");
        tagColor = 'dark grey';
        break;
      case 1:
        displayStr = t('success');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("waiting");
        tagColor = 'red';
        break;
      case -1:
        displayStr = t('fail');
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={LogtList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>

  )

}

export default CompanyUserQuotaLogListTable;