import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Button, Divider, Form, Input,
  message, Modal, notification, Radio, Row, Col,
  Select, Spin, Switch, Table, Upload
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import debugLib from 'debug';
import * as Service from '../../core/Service';
import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import ReactQuill, { Quill } from 'react-quill';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 }
  },
};

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
    },
    sm:
    {
      offset: 0,
    },
    md:
    {
      offset: 0,
    }
  },
};

const editorStyle = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24, },
    md: { span: 15, }
  },
};

// const QuillNoSSRWrapper = dynamic(
//   () => import('react-quill'),
//   { ssr: false }
// )

const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'font': [] }],
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                // text direction
  [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
  [{ 'align': [] }],
  ['link', 'image', 'video'],

  ['clean']  // remove formatting button
];

const StaticForm = (props) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)
  const history = useHistory()
  const { t } = useTranslation()

  let location = useLocation();
  const { meta_key, id } = queryString.parse(location.search);
  const { query, editMethod } = props;
  const [postContent, setPostContent] = useState('')
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    if (_.isUndefined(id)) {
      return
    }


    const resp = await Service.call('get', `/api/admin/meta?site_meta_id=${id}`)
    const { data } = resp
    let [metaData] = data
    let {
      meta_value
    } = metaData;
    form.setFieldsValue({
      ...metaData
    })

    setPostContent(meta_value)
  }

  const onFinish = async (value) => {
    let { pathname, meta_value, meta_key } = value
    let data = { pathname, meta_value, meta_key }
    data.meta_value = postContent
    // let submit
    if (id !== undefined) {
      data.site_meta_id = id
      await Service.call('patch', '/api/admin/meta', data)
    }
    message.success('成功')
    history.push('/company/setting');

    // else {
    //   submit = await Service.call('put', '/api/admin/meta', data)
    // }
    // if (submit.status == 1) {
    //   openNotificationWithIcon('success', t('Success'), '')
    //   Router.push("/admin/setting")
    // }
    // else {
    //   openNotificationWithIcon('error', t('Failed'))
    // }
  }

  const onCancel = () => {
    // Router.push("/admin/setting")
  }

  return (
    <NavAndSideFrame {...props} title={t('setting')} selectedKey="setting">
      <Form
        form={form}
        labelAlign="left"
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      >
        <Form.Item label={t('common:pathname')} name="pathname"
          rules={[
            {
              required: true,
              message: `${t('common:pathname')}${t('is_required')}`
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item label={t('title')} name="meta_key"
          rules={[
            {
              required: true,
              message: `${t('title')}${t('is_required')}`
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          {...editorStyle}
          label={t('common:static_content')}
          rules={[
            {
              required: true,
              message: `${t('common:content')}${t('common:is_required')}`
            },
          ]}
        >
          {/* {props.meta_key === 'media' ? <Input value={postContent} onChange={(evt) => setPostContent(evt.target.value)} /> : */}
          <ReactQuill
            styles={{minHeight: 800}}
            modules={{
              toolbar: toolbarOptions
            }}
            value={postContent}
            theme="snow"
            onChange={setPostContent}
          />
          {/* } */}
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Row gutter={[5, 5]}>
            <Col>
              <Button type="primary" htmlType="submit">
                {t('common:Submit')}
              </Button>
            </Col>
            <Col>
              <Button type="dashed" onClick={onCancel}>
                {t('common:Cancel')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </NavAndSideFrame>
  )
}

export default StaticForm;
