import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
} from "antd";
import moment from "moment";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../components/form/FormUploadFile";
import { useSelector } from "react-redux";
import Button from '../../t9UIComponents/t9Button';

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ItemInfoModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    categoryList,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );
  let isActive = selectedRecord.is_active
  if (selectedRecord) {
    selectedRecord.utime = moment.unix(selectedRecord.utime).format("YYYY-MM-DD HH:mm");
    selectedRecord.ctime = moment.unix(selectedRecord.ctime).format("YYYY-MM-DD HH:mm");
    if (selectedRecord.section_duration) {
      selectedRecord.section_duration /= 60
    }
    if (selectedRecord.buffer && selectedRecord.buffer !== 0) {
      selectedRecord.buffer /= 60
    }
    selectedRecord.start_end_booking_day = selectedRecord.start_day
      ? [
        moment.unix(selectedRecord.start_day),
        moment.unix(selectedRecord.end_day),
      ]
      : [null, null];
    selectedRecord.start_end_booking_time = selectedRecord.start_time
      ? [
        moment(selectedRecord.start_time, "HH:mm"),
        moment(selectedRecord.end_time, "HH:mm"),
      ]
      : [null, null];
    //selectedRecord.last_success_check_time = moment.unix(selectedRecord.last_success_check_time).format('YYYY-MM-DD HH:mm')
  }

  const [ItemInfoForm] = Form.useForm();

  useEffect(() => {
    ItemInfoForm.resetFields();
    if (selectedRecord.item_feature_image) {
      setImageURL(`${STATIC_SERVER_URL}/media/${selectedRecord.item_feature_image}`);
    } else {
      setImageURL("");
    }
  }, [selectedRecord]);

  const uploadOnChange = (info) => {
    // const {t} = this.props
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("common:Uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };

        let path = "../../" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
        // this.setState(
        //   {
        //     imageURL: path,
        //     fileinfo: data,
        //   }
        // )
      } else {
        message.error(t("common:Failed"));
      }
    }
  };

  return (
    <Modal
      title={
        selectedRecord.item_id === 0 ? t("add_new_record") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.item_feature_image = fileinfo.filename
            ? fileinfo.filename
            : null;
          formData.company_id = selectedRecord.company_id;
          handleFormSubmit(formData);
        }}
      >
        { selectedRecord.item_id === 0 ?  
          null :
        (
        // <Form.Item label={t("company_id")} name="company_id">
        //   <Input disabled />
        // </Form.Item>

        <Form.Item label={t("item_id")} name="item_id">
          <Input disabled />
        </Form.Item>)}

        {/* <Form.Item
          label={t('active_status')}
          name="is_active"
          initialValue={0}
        >
          <Select
            disabled={selectedRecord.item_id === 0}
          >
            <Option value={1}>{t('active')}</Option>
            <Option value={0}>{t('inactive')}</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          label={t("name")}
          name="item_name"
          rules={[{ required: true, message: "Cannot leave empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("desc")}
          name="item_desc"
          rules={[{ required: true, message: "Cannot leave empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t("image")} name="item_feature_image">
          <FormUploadFile
            data={{ scope: "private" }}
            onChange={uploadOnChange}
            imageURL={imageURL}
          />
        </Form.Item>

        <Form.Item
          label={t("category")}
          name="item_category_id"
          rules={[{ required: true, message: "Cannot leave empty" }]}
        >
          <Select
            disabled={isActive}
          >
            {categoryList.map((category) => {
              return (
                <Option value={category.item_category_id} key={category.item_category_id}>
                  {category.category_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("section_price")}
          name="section_price"
          rules={[{ required: true, message: "Need a number" }]}
        >
          <InputNumber
            disabled={isActive}
          />
        </Form.Item>

        <Form.Item
          label={t("section_duration") + "(" + t("mins") + ")"}
          name="section_duration"
          rules={[{ required: true}]}
        >
          <Select
            disabled={isActive}
          >
            <Option key={15} value={15}>15</Option>
            <Option key={30} value={30}>30</Option>
            <Option key={60} value={60}>60</Option>
            <Option key={90} value={90}>90</Option>
            <Option key={120} value={120}>120</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t("buffer")}
          name="buffer"
          rules={[{ required: true, message: "Need a number" }]}
        >
          <InputNumber
            disabled={isActive}
          />
        </Form.Item>

        <Form.Item
          label={t("quota_required")}
          name="quota"
          rules={[{ required: true, message: "Need a number" }]}
        >
          <InputNumber
            disabled={isActive}
          />
        </Form.Item>

        <Form.Item label={t("repeat_type")} name="repeat_type">
          <Select
            disabled={isActive}
          >
            <Option value={1}>Weekly</Option>
            <Option value={2}>Monthly</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t("start_end_booking_day")}
          name="start_end_booking_day"
        >
          <DatePicker.RangePicker
            // showTime
            disabled={isActive}
            format="YYYY-MM-DD"
            onChange={(value) => console.log(value)}
          />
        </Form.Item>

        <Form.Item
          label={t("start_end_booking_time")}
          name="start_end_booking_time"
        >
          <TimePicker.RangePicker
            disabled={isActive}
            showTime
            format="HH:mm"
            // onChange={(value) => console.log(value)}
          />
        </Form.Item>

        <Form.Item label={t("prebooking_type")} name="prebooking_type">
          <Select
            disabled={isActive}
          >
            <Option value={1}>{t("one_week_before")}</Option>
            <Option value={2}>{t("one_month_before")}</Option>
          </Select>
        </Form.Item>
        <h4>{t("available_day")}</h4>
        <Row>
          <Col span={6}>
            <Form.Item
              label={t("monday")}
              name="available_mon"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("tuesday")}
              name="available_tue"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("wednesday")}
              name="available_wed"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("thursday")}
              name="available_thu"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item
              label={t("friday")}
              name="available_fri"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("saturday")}
              name="available_sat"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={t("sunday")}
              name="available_sun"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("available_public_holiday")}
          name="available_public_holiday"
          valuePropName="checked"
        >
          <Switch
            disabled={isActive}
          />
        </Form.Item>

        {selectedRecord.item_id !== 0 ? (
          <Form.Item label={t("create_time")}>
            <p>{selectedRecord.ctime}</p>
          </Form.Item>
        ) : null}

        {selectedRecord.item_id !== 0 ? (
          <Form.Item label={t("utime")}>
            <p>{selectedRecord.utime}</p>
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ItemInfoModal;
