import React, { Component } from 'react';
import {
  Layout, Button, Avatar, Row, Col
} from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux';
import { MenuOutlined } from '@ant-design/icons'
import { Redirect} from "react-router-dom";
import { ActionCreators } from '../redux/actions';
import logo from '../assets/330/330_logo.png';
import LanguageSelector from './LanguageSelector';
import { setSider } from '../redux/actions/common';

const { Header } = Layout;

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      redirectHome: false,
    };
  }

  redirectHome() {
    this.setState({redirectHome: true})
  }

  render() {
    const {
      admin,
      company_admin,
      company_user
    } = this.props.app;

    // console.log(`user >> `, user);
    let nickname = '';
    if (company_user.company_user_id != null) {
      nickname = company_user.nickname;
    }

    if (company_admin.company_admin_id != null) {
      nickname = company_admin.nickname;
    }

    if (admin.admin_id != null) {
      nickname = admin.nickname;
    }

    return (
      <Header
        className="header"
        style={{background: 'linear-gradient(to right, #000046, #1cb5e0)'}}
      >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{width: 180}}>
            {/* <a href="/company/home"> */}
              <img style={{width: 50, height: 50}} src={logo} alt="" />
            {/* </a> */}
          </div>
          <Row align="middle">
            <Col xs={0} sm={24}>
              {/* <LanguageSelector /> */}
              <Avatar style={{margin: '0 10px 0 20px'}}>{nickname ? nickname.charAt(0) : ''}</Avatar>
              <span style={{color: "white"}}>{nickname}</span>
            </Col>
            <Col xs={24} sm={0}>
              <Button
                // ghost
                size="large"
                icon={<MenuOutlined />}
                style={{color: 'white', border: 'none', background: 'black', opacity: '0.5'}}
                onClick={() => {
                  this.props.toggleSider();
                }}
              />
            </Col>
          </Row>
        </div>
      </Header>
    )
  }
}

function mapStateToProps(state) {
  return {...state};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
