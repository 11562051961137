import React, { Component } from 'react';
import { LockOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setCompanyAdmin, setCompanyUser } from '../../redux/actions/common'
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../../core/Service';
import logo from '../../assets/330/330_logo.png';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Login = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector(state=> state.app.config)
  const auth = useSelector(state => state.app.auth)

  const onFinish = async (formData) => {
    try {
      let {email, password} = formData;
      let data = await Service.call('post', `/api/login/company_user`, {
        email, password
      });

      if (data.status === 1) {
        message.success(`${t("login_success")}`);
        dispatch(setCompanyUser(data.userData));
        dispatch(setAuth(true));
        history.push('/company/survey')
      } else {
        message.error(`${t("login_fail")}`);
      }
    } catch (error) {
      message.error(`${t("login_fail")}`);
    }
  }

  const displayLogin = () => {
    return (
      <div className="" style={{display: 'flex', justifyContent: 'center', marginBottom: '150px'}}>
        <Form 
          className="login-form col-12 mt-2"
          style={{width: '100%'}}
          form={form}
          onFinish={onFinish}
        >
          {/* <Form.Item
            name="company_key"
            rules={[{ required: true, message: 'Please input company key.' }]}
          >
            <Input
              prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Company Key"
            />
          </Form.Item> */}
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input email.', type: 'email' }]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          {/* <Form.Item
            name="mobile"
            rules={[{ required: true, message: 'Please input mobile.' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Mobile"
            />
          </Form.Item> */}

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input password.' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          <Button
            style={{width: "100%"}}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            登入
          </Button>
          {/* <p className="forgot-password text-right">
              Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
          </p> */}
        </Form>
      </div>
    );
  }

  return (
    <Layout>
      <Layout.Content>
        <div style={{height: "10px", background: 'linear-gradient(to right, #000046, #1cb5e0)'}} />
        <Row type="flex" justify="center" align="middle" style={{padding: '50px', minHeight: '100vh', background: 'white'}}>
          <Col xs={24} sm={16} md={14} lg={10} xl={8} style={{alignItems: 'center', justifyContent: 'center'}}>
            <div style={{textAlign: 'center', marginBottom: '50px'}}>
              <div>
                <img alt="" src={logo} style={{width: '100%', maxWidth: '200px', marginBottom: '30px'}} />
              </div>
              <h2>330 Questionnaire</h2>
              <h3>User Login</h3>
            </div>
            {
              displayLogin()
            }
          </Col>
        </Row>
        {/* <Row type="flex" justify="center">
          <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
            <a href={`${config.STATIC_SERVER_URL}/admin/login`}>Technine admin login</a> 
          </Col>
        </Row> */}
      </Layout.Content>
    </Layout>
  )
}

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);

