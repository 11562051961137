import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../core/Service';
import { useHistory } from 'react-router-dom';

const UserAccountTable = (props) => {
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  // const [selectedRecord, setSelectedRecord] = useState([]);
  // const [modalVisible, setModalVisible] = useState(false);
  const tableIDName = "user_id";
  // const admin = useSelector(state => state.app.admin);
  // console.log('Table Data', props.dataObj);

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: '操作',
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/company/user_account_form?user_id=${value}`)
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: `名字`,
        dataIndex: `first_name`,
      },
      {
        title: `姓氏`,
        dataIndex: `last_name`,
      },
      {
        title: `電話`,
        dataIndex: `mobile`,
      },
      {
        title: `電郵`,
        dataIndex: `email`,
      },
    ];
    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  )
}

export default UserAccountTable;