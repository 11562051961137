import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const debug = require('debug')('app:admin:client:src:pages:company:UserQuotaListTable');

const UserQuotaListTable = (props) => {
  const { t } = useTranslation()
  const tableIDName = "user_quota_id";
  const app = useSelector((state) => state.app);


  const [dataList, setDataList] = useState(props.dataObj);

  let { setModalVisible, modalVisible, setEditOption, setSelectedRecord, loading, deleteRecord } = props

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                    // setEditOption(true)
                    // EditOption = true
                  }}
                />
              </Tooltip>

            </span>
          )
        }
      },
      {
        title: t('username'),
        dataIndex: 'username',
        sorter: (a, b) => a.username.localeCompare(b.username)
      },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.itemName - b.itemName,
      },
      {
        title: t('quota_amount'),
        dataIndex: 'quota_amount',
        sorter: (a, b) => a.quota_amount - b.quota_amount,
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>

  )

}

export default UserQuotaListTable;