import React, { Component, useEffect } from 'react'
import { Layout, Menu, Row, Col, Grid } from 'antd';
import {
  Redirect,
  Link
} from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  LogoutOutlined,
  SettingOutlined,
  CarryOutOutlined,
  AppstoreAddOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { ActionCreators } from '../redux/actions';
import * as Service from '../core/Service';
import LanguageSelector from './LanguageSelector';

const { useBreakpoint } = Grid;
const { Sider } = Layout;
const { SubMenu } = Menu;

let dataList;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      logout: false,
    };
  }

  async toggle() {
    let { collapsed } = this.state
    this.setState({ collapsed: !collapsed });
  }

  async logout() {
    await Service.logout();
    this.props.setAdmin({})
    this.props.setCompanyAdmin({})
    this.props.setCompanyUser({})
    this.props.setUser({})
    this.props.setAuth(false)
    // this.props.history.push('/login');
    this.setState({ logout: true });
  }

  renderMenuItem() {
    let admin = false;
    admin = _.isEmpty(this.props.app.company_admin);
    if (!_.isEmpty(this.props.app.company_user)) {
      admin = false;
    }
    if (!_.isEmpty(this.props.app.user)) {
      admin = false;
    }

    let isCompanyUser = false;
    isCompanyUser = !_.isEmpty(this.props.app.company_user);

    let isUser = false;
    isUser = !_.isEmpty(this.props.app.user);

    return dataList.map((pathData, i) => {
      if (pathData.submenu && admin) {
        return (
          <SubMenu
            key={pathData.key}
            icon={pathData.icon}
            title={pathData.title}
          >
            {pathData.submenu.map((subPathData, i) => {
              return (
                <Menu.Item key={subPathData.key}>
                  <Link to={subPathData.path}>
                    {subPathData.icon}
                    {subPathData.title}
                  </Link>
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      }

      //  privilege remark: all / admin / company / user
      if (!admin && pathData.privilege === 'admin') { //  Company user not allow to access admin page
        return;
      }

      if (admin && pathData.privilege === 'company') { //  Admin user not allow to access company user page
        return;
      }

      if (admin && pathData.privilege === 'user') { //  Admin user not allow to access user page
        return;
      }

      if (isCompanyUser && pathData.privilege === 'user') { //  Admin user not allow to access company user page
        return;
      }

      if (isUser && pathData.privilege === 'company') { //  Admin user not allow to access company user page
        return;
      }

      let redirectPath = pathData.path;
      // if (!admin) {
      //   redirectPath = pathData.companyPath;
      // }
      if (isCompanyUser) {
        redirectPath = pathData.companyPath;
      }
      if (isUser) {
        redirectPath = pathData.userPath;
      }

      return (
        <Menu.Item
          key={pathData.key}
          onClick={this.handleClick}
          className={
            pathData.className ? pathData.className.join(' ') : ''
          }
        >
          <Link to={redirectPath}>
            {pathData.icon}
            <span>{pathData.title}</span>
          </Link>
        </Menu.Item>
      )
    })
  }

  render() {
    let { defaultSelectedKeys, t } = this.props;
    
    // console.log('this.props.app>>>', this.props.app)

    dataList = [
      // { key: 'dashboard', title: t('dashboard'), path: '/admin/home', companyPath: '/company/home', userPath: '/user/home', icon: <DashboardOutlined />, privilege: 'all' },
      { key: 'survey', title: "問卷", path: '/', companyPath: '/company/survey', icon: <FileTextOutlined />, privilege: 'company' },
      { key: 'setting', title: t('setting'), path: '/', companyPath: '/company/setting', icon: <SettingOutlined />, privilege: 'company' },



      // { key: 'member_user', title: t('member') + t('list'), path: '/admin/member/list', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'access_log', title: t('access_log'), path: '/admin/access_log', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'admin_user', title: t('admin_user'), path: '/admin/list', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'admin_company', title: "comp", path: '/admin/company/list', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'company_user', title: t('staff_user'), path: '/admin/company/user', companyPath: '/admin/company/user', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'item_info_management', title: t('item_info_management'), path: '/', companyPath: '/company/item/list', icon: <UnorderedListOutlined />, privilege: 'company' },
      // { key: 'item_category_management', title: t('item_category_management'), path: '/', companyPath: '/company/item/category/list', icon: <ApartmentOutlined />, privilege: 'company' },
      // { key: 'booking_management', title: t('booking_management'), path: '/', companyPath: '/company/booking_info', icon: <CalendarOutlined />, privilege: 'company' },
      // { key: 'booking_log', title: t('booking_log'), path: '/', companyPath: '/company/booking_log', icon: <CalendarOutlined />, privilege: 'company' },
      // { key: 'user_quota_management', title: t('user_quota_management'), path: '/', companyPath: '/company/quota/list', icon: <AppstoreAddOutlined />, privilege: 'company' },
      // { key: 'user_quota_log', title: t('user_quota_log'), path: '/', companyPath: '/company/quota/list/log', icon: <CarryOutOutlined />, privilege: 'company' },
      // { key: 'user_info', title: t('user_info'), path: '/', companyPath: '/company/user/form', icon: <UserOutlined />, privilege: 'company' },
      // { key: 'user_management', title: t('user_management'), path: '/', companyPath: '/company/user/list', icon: <UserOutlined />, privilege: 'company' },
      // { key: 'holiday_management', title: t('holiday_management'), path: '/', companyPath: '/company/holiday/list', icon: <CarryOutOutlined />, privilege: 'company' },

      // { key: 'account_setting', title: t('account_setting'), path: '/', companyPath: '/company/account_setting', icon: <SettingOutlined />, privilege: 'company' },
      // { key: 'company_setting', title: t('company_setting'), path: '/', companyPath: '/company/company_setting', icon: <SettingOutlined />, privilege: 'company' },
    
      // user sidebar
      // { key: 'my_booking', title: t('my_booking'), path: '/', userPath: '/user/my_booking', icon: <UserOutlined />, privilege: 'user' },
      // { key: 'company_holiday', title: t('company_holiday'), path: '/', userPath: '/user/my_booking', icon: <UserOutlined />, privilege: 'company' },
      // { key: 'company_holiday', title: t('company_holiday'), path: '/', userPath: '/company/holiday/list', icon: <UserOutlined />, privilege: 'company' },
      // { key: 'available_booking', title: t('available_booking'), path: '/', userPath: '/user/available_booking', icon: <UserOutlined />, privilege: 'user' },

      
      // { key: 'user_setting', title: t('user_setting'), path: '/', userPath: '/user/user_account_form', icon: <UserOutlined />, privilege: 'user' },


      
      // { key: 'product_item', title: t('product_information'), path: '/', companyPath: '/company/product_item', icon: <ShoppingCartOutlined />, privilege: 'company' },
      // { key: 'invoice_info', title: t('invoice_information'), path: '/', companyPath: '/company/invoice_info', icon: <MoneyCollectOutlined />, privilege: 'company' },
      // { key: 'receipt_info', title: t('receipt_information'), path: '/', companyPath: '/company/receipt_info', icon: <MoneyCollectOutlined />, privilege: 'company' },
      // { key: 'setting', title: t('setting'), path: '/', companyPath: '/company/setting', icon: <SettingOutlined />, privilege: 'company' },
      // { key: 'option_setting', title: t('option_setting'), path: '/', companyPath: '/company/option_setting', icon: <SettingOutlined />, privilege: 'company' },
      // { key: 'user_account', title: t('user_information'), path: '/admin/user_account', companyPath: '/company/user_account', icon: <TeamOutlined />, privilege: 'all' },
    ]

    return (
      <Sider
        // collapsed={this.state.collapsed}
        collapsed={this.props.app.sidebarCollapse}
        breakpoint="sm"
        collapsedWidth="0"
        width={250}
        style={{
          height: '100%', opacity: 1, position: 'relative', backgroundColor: '#eee', minHeight: '95vh'
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={['1']}
          style={{ paddingBottom: '50px', paddingTop: '20px', backgroundColor: '#eee' }}
        >
          {this.renderMenuItem()}
        </Menu>
        <Menu mode="inline" style={{backgroundColor: '#eee'}}>
          {/* <Divider /> */}
          <Menu.Item key="99" onClick={() => { this.logout(); }}>
            <LogoutOutlined />
            <span>{t('logout')}</span>
          </Menu.Item>
          <Menu.Item key="100">
            <Row>
              <Col xs={24} sm={0}>
                <LanguageSelector
                  // style={{margin: "0px 20px"}}
                />
              </Col>
            </Row>
          </Menu.Item>
        </Menu>

        {
          this.state.logout === true
            ? <Redirect to="/admin/login" />
            : null
        }
        <ScreenDetector />
      </Sider>
    )
  }
}

function ScreenDetector(props) {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const screens = useBreakpoint();

  useEffect(() => {
    const isMobile = screens.xs;
    if (isMobile) {
      dispatch(ActionCreators.toggleSider(true))
    } else {
      dispatch(ActionCreators.toggleSider(false))
    }
  }, [screens]);

  return null;
}

function mapStateToProps(state) {
  return {...state};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sidebar));
