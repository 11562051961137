import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
// import CompanyListTable from './CompanyListTable';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

//const involvedModelName = "company";
const title = "公司列表";
//const selectedKey = 3;
//const tableIDName = "company_user_id";

const CompanyList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    let dataList = [];
    try {
      let url = "/api/user/company/list";
      let data = await Service.call('get', url);
     // console.log(data);
       dataList = data;

    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataList);
    }
  }

  return (
    <NavAndSideFrame title={title} selectedKey="admin_company">
      <Link
        to={{
           pathname: "/admin/company/details/",
           search: "?id=0",
        }}
      >
        <Button
          type="primary"
        >
          新增公司會員
        </Button>
      </Link>
      <Divider />
      {/* <CompanyListTable
        dataObj={dataList}
        adminChangeData={getAllData}
      /> */}
    </NavAndSideFrame>
  )
}

export default CompanyList;