import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useSelector } from 'react-redux';

const CompanyUserAcListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "user_id";
  const app = useSelector((state) => state.app);

  const [dataList, setDataList] = useState(props.dataObj);
  const { defaultActiveKey } = props
  let { holidayList, setModalVisible, setSelectedRecord, loading, deleteRecord, isActiveChangeCallBack } = props

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Link
                  to={{
                    pathname: "/company/user/form",
                    search: `?id=${value}&key=${defaultActiveKey}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  // onClick={() => {
                  //   setModalVisible(true);
                  //   setSelectedRecord(record)
                  // }}
                  />
                </Link>
              </Tooltip>
              {/* <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    isActiveChangeCallBack(record.item_id, is_active)
                  }}
                />
              </Tooltip> */}
              {/* <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deleteRecord(record.company_holiday_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"

                    icon={<DeleteOutlined />}

                  />
                </Tooltip>
              </Popconfirm> */}
            </span>
          )
        }
      },

      {
        title: t('email'),
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email)
        // render: (date) => {
        //   return moment.unix(date).format('YYYY-MM-DD')
        // },
      },
      {
        title: t('last_name'),
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name)
      },
      {
        title: t('first_name'),
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name)
      },
      {
        title: t('telephone'),
        dataIndex: 'mobile',
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
        // ...getColumnSearchProps('mobile'),
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('confirmation_status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayPrebookingType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("one_week_before");
        break;
      case 2:
        displayStr = t("one_month_before");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayRepeatType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("weekly");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 2:
        displayStr = t("waiting");
        tagColor = 'dark grey';
        break;
      case 1:
        displayStr = t('success');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("waiting");
        tagColor = 'red';
        break;
      case -1:
        displayStr = t('fail');
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={holidayList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>

  )

}

export default CompanyUserAcListTable;