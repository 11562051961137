import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, Row, Col, Spin, Table, Modal
} from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';


const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const selectedKey = "survey";
const tableIDName = "question_id";
const title = "問卷紀錄";

const SurveyFormContainer = () => {
  let location = useLocation();
  const { t } = useTranslation()
  const { survey_id, session_id } = queryString.parse(location.search);

  const [responseTypeMap, setResponseTypeMap] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true);

  const [tabPanes, setTabPanes] = useState(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await getInitialData();
      setLoading(false);
    }
    init();
  }, []);

  useEffect(() => {
    setLoading(true);
    getTabPanElements();
    setLoading(false);
  }, [responseTypeMap])

  const getInitialData = async () => {
    const surveyQuestions = await Service.call('get', `/api/question?survey_id=${survey_id}`);
    const responseList = await Service.call('get', `/api/response?session_id=${session_id}`);
    const userData = await Service.call('get', `/api/response_user?session_id=${session_id}`);

    const questionMap = _.keyBy(surveyQuestions, 'question_id');

    responseList.map((item) => _.assign(item, questionMap[item.question_id]))

    setResponseTypeMap(_.groupBy(responseList, 'type'));
    setDataSource(_.orderBy(dataSource, ['type', 'sort_order'], ['desc', 'asc']))
    setUser(userData)
  }

  const getTabPanElements = () => {
    let tabPanElements = [
      <TabPane tab="CWBS F1" key={0}>
        <ResponseList dataSource={responseTypeMap.cwbs_f1} loading={loading} />
      </TabPane>,
      <TabPane tab="CWBS F2" key={1}>
        <ResponseList dataSource={responseTypeMap.cwbs_f2} loading={loading} />
      </TabPane>
    ]
    // console.log('tabPanElements', JSON.stringify(tabPanElements, null, 2))
    setTabPanes(tabPanElements)
  }

  if (loading) return (
    <NavAndSideFrame title={`${user.name} 的${title}`} selectedKey={selectedKey}>
      <Spin />
    </NavAndSideFrame>
  );

  return (
    <NavAndSideFrame title={`${user.name} 的${title}`} selectedKey={selectedKey}>
      <Link to={`/company/survey/form?survey_id=${survey_id}`}>
        <Button type="primary" style={{ marginBottom: 30 }}>返回上頁</Button>
      </Link>
      <Tabs defaultActiveKey="0">
        {tabPanes}
      </Tabs>
    </NavAndSideFrame>
  )
}

const ResponseList = ({dataSource}) => {
  const sortedData = _.orderBy(dataSource, ['sort_order'], ['asc'])

  const getColumns = () => {
    return [
      {
        title: '排序',
        dataIndex: 'sort_order',
      },
      {
        title: '問題編號',
        dataIndex: 'question_id',
      },
      {
        title: '問題(中文)',
        dataIndex: 'title_zh_hant',
      },
      {
        title: '問題(英文)',
        dataIndex: 'title_en',
      },
      {
        title: '分數',
        dataIndex: 'answer',
        fixed: 'right'
      }
    ]
  }
  // console.log('sortedData', JSON.stringify(sortedData, null, 2))
  return (
    <>
      <Spin
        indicator={<LoadingOutlined />}
        spinning={!dataSource}
      >
        <Row gutter={[0, 8]}>
          <Col style={{ fontSize: 16 }}>此部分總分： {_.sumBy(sortedData, 'answer')}</Col>
        </Row>
        <Table
          rowKey="response_id"
          scroll={{ x: 'max-content' }}
          dataSource={sortedData}
          columns={getColumns()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
          loading={!dataSource}
        />
      </Spin>
    </>
  )
}

export default SurveyFormContainer;
