import React, { useState, useEffect } from 'react'
import {
  Divider, Row, Col, Input, Button, Modal, Typography,
} from 'antd';
import _ from 'lodash';
import {
  CloseOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';
import { useTranslation } from 'react-i18next';

import fb from '../../assets/330/newlife_CHI_web_4x-29.png';
import instagram from '../../assets/330/newlife_CHI_web_4x-30.png';
import youtube from '../../assets/330/newlife_CHI_web_4x-31.png';
import MediaGroup from './MediaGroup';

const { Text } = Typography;

const ContactUs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Row gutter={[0, 8]} style={{padding: '0px 20px'}}>
      <Col span={24} style={{ marginBottom: 30 }}>
        <Row justify="end">
          <Col>
            <CloseOutlined style={{ fontSize: 24, color: '#fff' }} onClick={() => dispatch(CommonActions.setModalVisible(false))} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Text style={{ ...styles.title }}>{t('contact_us')}</Text>
      </Col>
      <Col span={24}>
        <Text style={{ ...styles.text }}>
          {t('fax')}: <a style={{ color: '#fff' }} href="tel:+85227822812">(852) 2782 2812</a>
        </Text>
      </Col>
      <Col span={24}>
        <Text style={{ ...styles.text }}>
          {t('fax')}:(852) 2782 1886
        </Text>
      </Col>
      <Col span={24} style={{ marginBottom: 20 }}>
        <Text style={{ ...styles.text }}>
          {t('email')}:<a style={{ color: '#fff', }} href="mailto:privacy@nlpra.org.hk">dayday330@nlpra.org.hk</a>
        </Text>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <MediaGroup />
      </Col>
    </Row>
  )
}


const styles = {
  icon: {
    color: '#141E29',
    marginRight: 20,
    fontSize: 20,
    transform: 'translateY(20%)'
  },
  button: {
    height: 50,
    fontWeight: 'bold',
    borderColor: '#fff',
    color: '#d7633e',
    fontSize: 22,
    width: '100%',
    borderRadius: 12
  },
  title: {
    color: '#d4a12c',
    fontSize: 28,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 18,
    color: '#fff',
    fontWeight: '500'
  }
}


export default ContactUs;
