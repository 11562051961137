import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Card,
} from "antd";
import moment from "moment";
import * as Main from "../core/Main";
import * as Service from "../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const UserAcInfoForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const [number, setNumber] = useState("");
  const history = useHistory();

  const { initialValues } = props;

  const [initialValuesForm, setInitialValues] = useState({
    mobilePrefix: "+852",
  });

  const [UserForm] = Form.useForm();

  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues };
      setInitialValues(newObject);
      setNumber(initialValues.mobile);
    }
  }, [initialValues]);

  useEffect(() => {
    UserForm.resetFields();
  }, [initialValuesForm]);

  const onFinish = async (formData) => {
    let postData = formData;

    // if (!_.isUndefined(postData.mobilePrefix && postData.mobile)) {
    //   postData.mobile = _.toString(`${postData.mobilePrefix}-${postData.mobile}`)
    // }

    console.log("postData>>>", postData);

    postData.user_id = id;
    postData.user_company_info_id = initialValuesForm.user_company_info_id;

    let response;
    if (id > 0) {
      response = await Service.call("patch", `/api/user`, postData);
    } else {
      response = await Service.call("put", `/api/user`, postData);
    }

    if (response.status === -1) {
      // if (response.errorCode === -40102) {
      //   notification.error({
      //     message: t("Contact First Name ,Contact Last Name , Company Mobile and Company Email Duplicated"),
      //   });
      //   return;
      // }
      // if (response.errorCode === -40101) {
      //   notification.error({
      //     message: t("Contact First Name ,Contact Last Name and Company Mobile Duplicated"),
      //   });
      //   return;
      // }

      notification.error({
        message: t("error"),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });

    if (id === 0) {
      history.push("/company/user/list");
    } else {
      history.push(`/company/user/list?key=${key}`);
    }
  };

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setNumber(value);
      UserForm.setFieldsValue({ mobile: value });
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValuesForm}
            onFinish={onFinish}
          >
            <Form.Item label={t("first_name_en")} name="first_name">
              <Input />
            </Form.Item>

            <Form.Item label={t("last_name_en")} name="last_name">
              <Input />
            </Form.Item>

            {/* <Form.Item label={t("first_name_ch")} name="first_name">
              <Input />
            </Form.Item>

            <Form.Item label={t("last_name_ch")} name="last_name">
              <Input />
            </Form.Item> */}

            <Form.Item label={t("nickname_en")} name="nickname">
              <Input />
            </Form.Item>

            <Form.Item label={t("nickname_zh")} name="nickname_zh">
              <Input />
            </Form.Item>

            <Form.Item label={t("gender")} name="gender">
              <Select>
                <Option value="M">{t('male')}</Option>
                <Option value="F">{t('female')}</Option>
              </Select>
            </Form.Item>

            <Form.Item label={t("email")} name="email">
              <Input />
            </Form.Item>

            <Form.Item
              // contact_person_mobile
              label={t("moblie")}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "必須填寫",
                },
              ]}
            >
              <Tooltip>
                <Input
                  value={number}
                  onChange={onNumberChange}
                  addonBefore={
                    // contactPersonMobilePrefix
                    <Form.Item name="mobilePrefix" noStyle>
                      <Select style={{ width: 80 }}>
                        <Option value="+852">+852</Option>
                        <Option value="+86">+86</Option>
                      </Select>
                    </Form.Item>
                  }
                  style={{ width: "100%" }}
                />
              </Tooltip>
            </Form.Item>

            <Form.Item
              // contact_person_mobile
              label={t("telephone")}
              name="telephone"
            >
              <Tooltip>
                <Input
                  value={number}
                  onChange={onNumberChange}
                  addonBefore={
                    // contactPersonMobilePrefix
                    <Form.Item name="mobilePrefix" noStyle>
                      <Select style={{ width: 80 }}>
                        <Option value="+852">+852</Option>
                        <Option value="+86">+86</Option>
                      </Select>
                    </Form.Item>
                  }
                  style={{ width: "100%" }}
                />
              </Tooltip>
            </Form.Item>

            <Form.Item
              label={t('addr_area')}
              name="addr_area"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('addr_district')}
              name="addr_district"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('addr_region')}
              name="addr_region"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('address')}
              name="address"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('preferred_language')}
              name="preferred_language"
            >
              <Select>
                <Option value=""></Option>
              </Select>
            </Form.Item>



            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserAcInfoForm;
