import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Avatar, Button, Divider, Layout, Menu, Modal, Popconfirm, Table } from 'antd';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const { Header, Content, Sider } = Layout;

export default class NavAndSideFrame extends React.PureComponent {
  render() {
    let {title, children, selectedKey} = this.props;
    return (
      <Layout style={{width: '100%'}}>
        <Navbar {...this.props} />
        <Layout>
          <Sidebar {...this.props} defaultSelectedKeys={selectedKey} />
          <Layout style={{width: '100%', background: 'white'}}>
            <Content
              style={{
                margin: 0,
                minHeight: '95vh'
              }}
            >
              {/* <div
                style={{
                  background: "#F4D35E",
                }}
              > */}
                <div style={{padding: 24}}>
                  <span style={{marginTop: '5px', fontSize: '1.2rem', fontWeight: '500', color: 'black'}}>{title}</span>
                </div>
              {/* </div> */}
              <div style={{padding: 24}}>
                {children}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

NavAndSideFrame.defaultProps = {
  title: "Title",
  children: null
}
