import React, { Component } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setAdmin } from '../../redux/actions/common'
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../../core/Service';
import logo from '../../assets/330/330_logo.png'
import _ from 'lodash';
import { useHistory } from 'react-router-dom';


const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const config = useSelector(state => state.app.config);
    const onFinish =  async (formData) => {
      let {email, password} = formData;
      let data = await Service.call('post', `/api/login/admin`, {
        email, password
      });
      if (data.errorMessage) return message.error(data.errorMessage);
      
      let adminData = await Service.call('get', `/api/admin`);
      if (adminData.errorMessage) return dispatch(setAuth(false));
      if (_.isEmpty(adminData.userData)) return dispatch(setAuth(false));

      dispatch(setAdmin(adminData.userData[0]));
      dispatch(setAuth(true));
      history.push('/admin/home')
    }

    const displayLogin = () => {
      return (
        <div className="" style={{display: 'flex', justifyContent: 'center'}}>
          <Form 
            className="login-form col-12 mt-2"
            style={{width: '100%'}}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input email.', type: 'email' }]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input password.' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>
            <Button
              style={{width: "100%"}}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              登入
            </Button>
            {/* <p className="forgot-password text-right">
                Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
            </p> */}
          </Form>
        </div>
      );
    }

  return (
      <Layout>
        <Layout.Content style={{padding: '50px'}}>
          <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <div style={{textAlign: 'center', marginTop: '15px'}}>
                <div>
                  <img alt="" src={logo} style={{width: '100%', maxWidth: '200px', marginBottom: '30px'}} />
                </div>
                <h2>Membership Management System</h2>
                <h3>Admin Panel</h3>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{marginTop: '15px'}}>
            <Col
              xs={22}
              sm={16}
              md={14}
              lg={10}
              xl={8}
              style={{alignItems: 'center', justifyContent: 'center'}}
            >
              {
                displayLogin()
              }
            </Col>
          </Row>
          {/* <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <a href={`${config.STATIC_SERVER_URL}/company/login`}>Company admin login</a> 
            </Col>
          </Row> */}
        </Layout.Content>
      </Layout>
    )
}

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);

