import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserBookingLogListTable from './CompanyUserBookingLogListTable';
// import CompanyHolidayInfoModal from './CompanyHolidayInfoModal';


const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = "booking_log";
const tableIDName = "company_user_id";

const CompanyUserBookingLogList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const [LogtList, setLogList] = useState([]);

  const title = t('booking_log');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)

    let userDataLog = []
    try {
      let url = "/api/booking_item/log";
      let logArr = await Service.call('get', url);
      userDataLog = logArr

      // get first /last name by using user_id ,and map data
      let UserArr = await Service.call('get', "/api/user/list")
      let UserArrMap = _.keyBy(UserArr, "user_id")

      _.each(logArr, (user, i) => {
        if (user.user_id !== 0) {
          userDataLog[i].first_name = UserArrMap[user.user_id].first_name
          userDataLog[i].last_name = UserArrMap[user.user_id].last_name
        }
      })

      // // item name by using item_category_id ,and map data
      let itemArr = await Service.call('get', "/api/item/list")
      let itemArrMap = _.keyBy(itemArr, "item_id")
      _.each(logArr, (Item, i) => {
        userDataLog[i].item_name = itemArrMap[Item.item_id].item_name
      })

      let companyUserArr = await Service.call('get', "/api/company/user")
      let companyUserArrMap = _.keyBy(companyUserArr, "company_user_id")
      console.log('companyUserArrMap>>>', companyUserArrMap)
      _.each(logArr, (companyUser, i) => {
        if (companyUser.company_user_id !== 0) {
          userDataLog[i].companyUserName = companyUserArrMap[companyUser.company_user_id].first_name + " " + companyUserArrMap[companyUser.company_user_id].last_name
        }
      })

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setLogList(userDataLog);
      setLoading(false)
    }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <CompanyUserBookingLogListTable
        LogtList={LogtList}
        loading={loading}
      />
    </NavAndSideFrame>
  )
}

export default CompanyUserBookingLogList;