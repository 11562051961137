import React, { useState, useEffect } from 'react';
import {
  Layout, Row, Col, List, Affix, Button, BackTop, Modal
} from 'antd';
import {
  VerticalAlignTopOutlined,
  WhatsAppOutlined,
  FacebookFilled
} from '@ant-design/icons';
import _ from 'lodash';
import { useSelector } from 'react-redux'
import TNContent from '../../t9UIComponents/TNContent';
import CompanyLogo from './CompanyLogo';
import MediaGroup from './MediaGroup';
import logo from '../../assets/330/newlife_CHI_web_4x-33.png'
import { MobileView, DesktopView } from "../../components/330/ResponsiveView";

const { Footer } = Layout;
const AppFooter = (props) => {
  return (
    <>
      <TNContent fullWidth color="#dbaf3f">
        <UpperFooter />
      </TNContent>
      <TNContent fullWidth color="#d4a12c">
        <LowerFooter />
      </TNContent>
    </>
  )
}

const UpperFooter = (props) => {
  return (
    <TNContent fullWidth color="#dbaf3f">
      <Footer style={styles.upperFooter}>
        <Row style={{
          height: '100%'
        }}
          justify="space-between"
          align="middle"
        >
          <Col style={{ fontSize: 24, fontWeight: '500', color: '#fff' }}>
            <a style={{ color: '#fff', fontSize: 24 }} href="http://dayday330.hk/" target="_blank">dayday330.hk</a>
          </Col>
          <Col>
            <MediaGroup />
          </Col>
        </Row>
      </Footer>
    </TNContent>
  )
}

const LowerFooter = (props) => {
  return (
    <TNContent fullWidth color="#d4a12c">
      <Footer style={styles.lowerFooter}>
        <Row
          style={{ height: '100%' }}
          justify="start"
          align="middle"
        >
          <Col>
            <img src={logo} style={{ width: 230 }} />
          </Col>
        </Row>
      </Footer>
    </TNContent>
  )
}

const styles = {
  text: {
    fontSize: 14,
    color: '#000',
  },
  upperFooter: {
    textAlign: 'center',
    margin: 0,
    width: '100%',
    height: '8vh',
    padding: '0 30px',
    // padding: '50px 5% 30px 5%',
    paddingBottom: 0,
    backgroundColor: '#dbaf3f',
    zIndex: 5
  },
  lowerFooter: {
    textAlign: 'center',
    margin: 0,
    height: '12vh',
    padding: '0 30px',
    // padding: '10px 5% 30px 5%',
    backgroundColor: '#d4a12c',
    zIndex: 5
  },
  icon: {
    color: '#FFFFFF',
    marginRight: 50,
    fontSize: 24,
    transform: 'translateY(20%)'
  }
}

export default AppFooter
