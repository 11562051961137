import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider, Table, Tooltip, Row, Col, Spin
} from 'antd';
import {
  LoadingOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as debugLib from 'debug';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';

const debug = debugLib('app:pages/UserbookingList');

const involvedModelName = "survey";
const selectedKey = "survey";
const tableIDName = "survey_id";
const title = "問卷";

const SurveyList = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getInitialData();
    setLoading(false)
  }, []);

  const getInitialData = async () => {
    let dataSource = await Service.call('get', '/api/survey/list');
    setDataSource(dataSource)
  }

  const getColumns = () => {
    return [
      {
        title: "操作",
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <Row>
              <Col>
                <Tooltip title="編緝">
                <Link
                  to={{
                    pathname: "/company/survey/form",
                    search: `?survey_id=${value}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                    
                  />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: '問卷名稱',
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'description'
      },
      {
        title: '開始日期',
        dataIndex: 'start_date',
        render: (value) => moment.unix(value).format('YYYY-MM-DD')
      },
      {
        title: '結束日期',
        dataIndex: 'end_date',
        render: (value) => moment.unix(value).format('YYYY-MM-DD')
      },

    ]
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataSource}
          columns={getColumns()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
          loading={loading}
        />
      </Spin>
      <Divider />
    </NavAndSideFrame>
  )
}

export default SurveyList;
