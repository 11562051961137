import React, { useState, useEffect } from 'react'
import {
  Divider, Row, Col, Input, Button, Modal, Typography,
} from 'antd';
import _ from 'lodash';
import {
  CloseOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';
import * as Service from '../../core/Service'
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';


import fb from '../../assets/330/newlife_CHI_web_4x-29.png';
import instagram from '../../assets/330/newlife_CHI_web_4x-30.png';
import youtube from '../../assets/330/newlife_CHI_web_4x-31.png';

const { Text } = Typography;

const TNC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [content, setContent] = useState('');
  useEffect(() => {
    getInitialData();
  }, [])

  const getInitialData = async () => {
    const resp = await Service.call('get', `/api/meta_by_key?meta_key=static`);
    let { data } = resp;
    let static_item = _.keyBy(data, 'meta_key')
    let key = `static_tnc_${i18n.language}`
    setContent(static_item[key].meta_value)
  }

  return (
    <Row gutter={[0, 30]}>
      <Col span={24} style={{}}>
        <Row justify="end">
          <Col>
            <CloseOutlined style={{ fontSize: 24, color: '#fff' }} onClick={() => dispatch(CommonActions.setModalVisible(false))} />
          </Col>
        </Row>
      </Col>
      <div className="drawer-content-body">
        <Col span={24}>
          <Text style={{ ...styles.title }}>
            {t('tnc_title')}
        </Text>
        </Col>
        <ReactQuill
          className="tnc-content"
          value={content}
          readOnly
          theme={null}
        />
        {/* {content} */}

        {/* <Col span={24}>
          <Text style={{ ...styles.text }}>
            新 生 精 神 康 復 會( 下 稱「 新 生 會 」)及 dayday330行動載於本網站的資料，只作一 般性質及說明用途。雖然新生會已盡力確保 本網站的資料準確，但無法作出保證。故新生 會特此聲明，新生會不會就任何錯誤、遺漏、 或錯誤陳述或失實陳述(不論明示或默示的) 承擔任何法律責任、義務或責任。對任何因使 用或不當使用或倚賴本網站及/或本網站所 載的資料而引致或所涉及的損失、毀壞損害 或傷害(包括但不限於相應而生的損失、毀壞
            、損害或傷害)，新生會概不承擔任何責任。此 外，新生會保留權利，但並不承諾，不時(並可 在未經通知的情況下)修訂、修改或更新本網 頁、其所載的資料和任何部分。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            對任何於本網站載有的資料所產生或與之相 關的損失、毀壞、損害或傷害(包括但不限於 相應而生的損失、毀壞、損害或傷害)，新生會 概不承擔任何法律責任、義務或責任。新生會 保留權利按其獨有酌情權及在毋須給予任何 理由或事先通知的情況下隨時刪除、暫時停 載或編輯在本網站編撰或刊載的一切資料。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            為方便網頁使用者查閱由第三者或通過第三 者提供的資料，本網站可通過其網頁提供或 協助提供超連結至外界網站。提供或協助提 供該等超連結，並不構成新生會贊同或沒有 不贊任何同該等網站之內容的任何明示或暗 示的聲明、陳述或保證;對任何因使用或不當 使用由或通過本網站傳遞或提供的任何外界 網站的內容而引致或所涉及的損失、毀壞、損 害或傷害(包括但不限於相應而生的損失、毀 壞、損害或傷害)，新生會概不承擔任何法律 責任、義務或責任。網頁使用者在使用該等外 界網站時，應參閱該等網站的條款及細則。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            這項自我評估僅為幫助閣下瞭解閣下個人的 身心靈健康狀況，絕非專業診斷。有關的資訊 只供個人參考用途，不能用以取代臨床心理 學服務或精神科專科服務或其他相關的專業 診斷或服務。如有任何需要，請尋求專業人士 的協助。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            新生會以及其職員及/或其他與新生會相關 的人士不會就使用過本網站的人士或其他人 士因應本網站或本網站所載的資訊而作出的 決定或行動所導致的任何損失、毀壞、損害或 傷害，承擔任何法律責任、義務或責任。前述 損失、毀壞、損害或傷害包括但不限於直接、 間接、特別、附帶或相應而生的損失、毀壞、損 害或傷害。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            本網站載有的所有內容、創作及設計(包括但 不限於文字、照片、圖像、圖畫、平面圖像、圖 片、音頻檔案和影像檔案)的版權均屬新生精 神康復會全權擁有，任何人士或機構/團體 不得透過任何方法複製、分發或以其他方式 使用本網站的任何部分或所有內容。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            根據《個人資料(私隱)條例》及新生會的《保 障個人資料(私隱)政策》所述的規例及原則， 新生會採用以下條款及措施，以確保網頁使 用者之個人資料得到保障。新生會所收集及 使用的個人資料只會作任何與內部進行統計 和分析以及檢視和制訂服務/活動直接有關 的用途，在未得到網頁使用者同意的情況下， 新生會絕不會向其他機構或人士披露任何個 人資料。惟新生會保留不時修改及/或增補 條款及措施而毋須作出事先通知的權利。條 款及措施包括:
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            新生會從網頁收集得來的資料乃源於網頁使 用者自願提供，而資料有可能包括可作個人 識別的資料(包括但不限於姓名、電郵地址及 其他聯絡資料等)。所收集的個人資料就有關 的目的而言應是必需的、足夠的，但不超乎適 度。網頁伺服器及與網站運作有關之系統會 將網頁使用者的一般資料記錄及儲存，資料 包括網頁使用者的領域名稱、所閱覽的網頁、 所使用瀏覽器的類別及填寫問卷時的回應。 而新生會不會出售、租借或傳送已收集的資 料予其他機構或人士。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            從網頁伺服器及與網站運作有關之系統取用 收集所得及已儲存的資料是受到限制的，只 有獲新生會授權的人士或有需要進行統計分 析/合法行動或任何其他職責的代理人才可 提取及使用該些資料。任何違反《個人資料( 私隱)條例》或新生會的《保障個人資料(私隱 )政策》之員工或代理人或會受到處分或法律 追究。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            在某些情況下，新生會可能有必要披露網頁 使用者的個人資料予第三方以:(i)遵循法律 規定、因應法庭傳召、頒令或其他司法程序; (ii)保障新生會及其僱員、義工或與新生會就 本網站運作有關之協作機構及代理人的利益
            、權利、安全或財產;或(iii)解決詐騙、安全或 技術問題，或要確保本網站及其相關系統正 常運作。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            新生會採用Secure Sockets Layer (SSL) 的 保密協定，以加密方式在互聯網上傳送資料， 惟經由互聯網資料傳送並非絕對保密或安全
            ，儘管新生會已採取相應措施以保護網頁使 用者的個人資料，但沒有透過互聯網傳輸的 數據是可保證完全安全的，而網頁使用者與 新生會通訊的私隱亦非完全可確保。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            新生會可能會在本網站使用「曲奇」網絡跟蹤 器(cookies) 或類似方法，以便網頁使用者繼 續瀏覽若干內容，以及新生會能建立網頁使 用者的資料數據及收集本網站的統計資料包 括瀏覽人次及網頁使用者對本網站的喜好。 如網頁使用者不允許「曲奇」網絡跟蹤器的使 用，網頁使用者可在其互聯網瀏覽器上進行 相關設定，惟有可能因此未能使用部分功能。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            根據《個人資料(私隱)條例》，網頁使用者有 權查閱或更改任何經由此網頁提供予新生會 的個人資料。網頁使用者如欲提出查閱或更 改 要 求，請 致 函 至 以 下 地 址 或 電 郵 至 <a style={{ color: '#fff', textDecoration: 'underline' }} href="mailto:privacy@nlpra.org.hk">dayday330@nlpra.org.hk</a>
          </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            根據《個人資料(私隱)條例》，網頁使用者有 權查閱或更改任何經由此網頁提供予新生會 的個人資料。網頁使用者如欲提出查閱或更 改 要 求，請 致 函 至 以 下 地 址 或 電 郵 至 <a style={{ color: '#fff', textDecoration: 'underline' }} href="mailto:privacy@nlpra.org.hk">dayday330@nlpra.org.hk</a>
            <br />
          新生精神康復會
          <br />
          九龍南昌街332號
          <br />
          (註明: dayday330行動)
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            新生會有權就處理個人資料的查閱或更改要 求收取合理費用。本網站雖沒有提供任何刪 除個人資料的途徑，但網頁使用者仍可隨時 透過信件或電子郵件聯絡提出將個人資料從 網頁伺服器及與網站運作有關之系統移除的 要求。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            若網頁使用者希望停止接收新生會包括 dayday330行動的推廣通訊及/或提出問題 或投訴，請透過以下途徑聯絡新生精神康復 會:
        <br />
        電郵: <a style={{ color: '#fff', textDecoration: 'underline' }} href="mailto:privacy@nlpra.org.hk">privacy@nlpra.org.hk</a>
            <br />
        郵寄至:香港九龍南昌街332號
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            當網頁使用者一旦使用本網站或其任何網頁 ，即表示網頁使用者無條件接受本免責聲明、 使用條款及私隱政策聲明，以及任何新生會 在毋須事先通知的情況下而可隨時對其作出 的修改及/或增補。請定期瀏覽此網頁查閱 任何可作出的修改及/或增補。所有修改及 /或增補後的條款及細則於刊載於本網站均
            即時生效。
        </Text>
        </Col>
        <Col span={24}>
          <Text style={{ ...styles.text }}>
            如本免責聲明、使用條款及私隱政策聲明的 英文版本和中文版本之間存在差異，則以英 文版本為準。
        </Text>
        </Col>
        <Col span={24} style={{ padding: 0 }}>
          <Text style={{ ...styles.text }}>
            @2021版權屬新生精神康復會所有
        </Text>
        </Col> */}
      </div>
    </Row>
  )
}


const styles = {
  icon: {
    color: '#141E29',
    marginRight: 20,
    fontSize: 20,
    transform: 'translateY(20%)'
  },
  button: {
    height: 50,
    fontWeight: 'bold',
    borderColor: '#fff',
    color: '#d7633e',
    fontSize: 22,
    width: '100%',
    borderRadius: 12
  },
  title: {
    color: '#d4a12c',
    fontSize: 26,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '500'
  }
}


export default TNC;
