import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Badge, notification
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Main from '../../core/Main';
import BookingInfoModal from '../../components/BookingInfoModal';
const localizer = momentLocalizer(moment)

const AvailableBookingCalendar = (props) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false);
  const [eventObj, setEventObj] = useState([]);
  const [bookStatus, setbookStatus] = useState([])
  const app = useSelector((state) => state.app);

  const [dataList, getCalendarData] = useState(props.dataObj);
  let { serverAction, BookingItem, callBack, loading,selectDateCallBack } = props
  let formType = 2
  let eventList = []
  if (BookingItem.length != 0) {
    eventList = BookingItem
  } else {
    eventList = []
  }

  const lookDetail = async (event) => {

    let categoryArr = await Service.call('get', "/api/item/category/list");
    let categoryArrMap = _.keyBy(categoryArr, "item_category_id")
    event.category_name = categoryArrMap[event.item_category_id].category_name

    setEventObj(event)

    //setbookStatus()
    setModalVisible(true)
  }

  const renderBackgroundColor = (status) => {
    let backgroundColor = ""
    switch (status) {
      case 1:
        backgroundColor = "#003E80"
        break;
      case 10:
        backgroundColor = "#FF1A53"
        break;
      case 999:
        backgroundColor = "#8C8C8C"
        break;
      default:
        backgroundColor = "#FF1A53"
    }
    return backgroundColor
  }

  const calendarStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event.status),
        border: 'none'
      }
    }
  }

  const handleFormSubmit = async (submitRecord) => {
    // console.log('submitRecord>>>', submitRecord)
    // setLoading(true)

    let patchData = {
      status: submitRecord.status,
      user_id: app.user.user_id,
      booking_id: submitRecord.booking_id
    }
    console.log('patchData>>>', patchData)
    let response
    if (patchData && patchData.status === 1) {
      response = await Service.call('patch', '/api/booking_item/confirm', patchData)
    }
    if (patchData && patchData.status === 10) {
      response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    }
    console.log('response>>>', response)
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t('insufficient_quota'),
      });
      return
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      callBack()
      setModalVisible(false)
    }
  }

  return (
    <Layout>
      <Calendar
        // selectable
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%", background: "white" }}
        defaultView={Views.WEEK}
        views={['day', 'week']}
        messages={{
          day: t('day'),
          week: t('week'),
          today: t('today'),
          next: t('next'),
          previous: t('previous')
        }}
        // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDate(prevOrNext,focusWeek)}
        onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDateCallBack(prevOrNext,focusWeek)}
        // components={(value)=>{console.log('check', value)}}

        // view="week"
        // views={{ 'month': true}}
        onSelectEvent={(event) => lookDetail(event)}
        // onSelectSlot={(slotInfo) => addNewEvent(slotInfo)}
        eventPropGetter={(event) => calendarStyle(event)}
      // for one day change color
      // dayPropGetter={calendarStyle}
      // defaultDate={new Date(2015, 3, 1)}
      />

      <BookingInfoModal
        formType={formType}
        //userObj={userObj}
        // userList={userList}
        dataObj={eventObj}
        //eventObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getCalendarData()
            //getData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </Layout>
  )
}

  // let user_id = useSelector(state => state.app.user.user_id)

export default AvailableBookingCalendar;