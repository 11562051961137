import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
} from "antd";
import moment from "moment";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../components/form/FormUploadFile";
import { useSelector } from "react-redux";

const debug = debugLib("app:pages/company/webStatusModal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const CompanyHolidayInfoModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    categoryList,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  if (selectedRecord) {
    selectedRecord.utime = moment
      .unix(selectedRecord.utime)
      .format("YYYY-MM-DD HH:mm");
    selectedRecord.ctime = moment
      .unix(selectedRecord.ctime)
      .format("YYYY-MM-DD HH:mm");
    selectedRecord.section_duration = selectedRecord.section_duration
      ? selectedRecord.section_duration / 60
      : null;
    selectedRecord.buffer = selectedRecord.buffer
      ? selectedRecord.buffer / 60
      : null;
    selectedRecord.start_end_booking_day = selectedRecord.start_day
      ? [
        moment.unix(selectedRecord.start_day),
        moment.unix(selectedRecord.end_day),
      ]
      : [null, null];
    selectedRecord.start_end_booking_time = selectedRecord.start_time
      ? [
        moment(selectedRecord.start_time, "HH:mm"),
        moment(selectedRecord.end_time, "HH:mm"),
      ]
      : [null, null];
    //selectedRecord.last_success_check_time = moment.unix(selectedRecord.last_success_check_time).format('YYYY-MM-DD HH:mm')
  }

  const [ItemInfoForm] = Form.useForm();

  useEffect(() => {
    ItemInfoForm.resetFields();
    if (selectedRecord.item_feature_image) {
      setImageURL(`${STATIC_SERVER_URL}/media/${selectedRecord.item_feature_image}`);
    } else {
      setImageURL("");
    }
  }, [selectedRecord]);

  const uploadOnChange = (info) => {
    // const {t} = this.props
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("common:Uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };

        let path = "../../" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
        // this.setState(
        //   {
        //     imageURL: path,
        //     fileinfo: data,
        //   }
        // )
      } else {
        message.error(t("common:Failed"));
      }
    }
  };

  return (
    <Modal
      title={
        selectedRecord.item_id === 0 ? t("add_new_record") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.item_feature_image = fileinfo.filename
            ? fileinfo.filename
            : null;
          formData.company_id = selectedRecord.company_id;
          handleFormSubmit(formData);
        }}
      >
        {selectedRecord.item_id === 0 ?
          null :
          (
            // <Form.Item label={t("company_id")} name="company_id">
            //   <Input disabled />
            // </Form.Item>

            <Form.Item label={t("item_id")} name="item_id">
              <Input disabled />
            </Form.Item>)}

        {/* <Form.Item
          label={t('active_status')}
          name="is_active"
          initialValue={0}
        >
          <Select
            disabled={selectedRecord.item_id === 0}
          >
            <Option value={1}>{t('active')}</Option>
            <Option value={0}>{t('inactive')}</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          label={t("date")}
          name="date"
          rules={[{ required: true, message: "Cannot leave empty" }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={t("desc")}
          name="description"
          rules={[{ required: true, message: "Cannot leave empty" }]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CompanyHolidayInfoModal;
