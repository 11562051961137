import React, { useState, useEffect } from 'react';
import {
  notification, Button
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import { useTranslation } from 'react-i18next';

import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserAccountTable from '../../components/UserAccountTable';

const UserAccount = (props) => {
  const { t } = useTranslation()

  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      let result = await Service.call('get', `/api/user/list`);
      // console.log('data', data);

      if (result.status !== 1) {
        notification.warning({ message: '獲取資料失敗' });
        return;
      }

      let { data } = result;
      setDataList(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title="用戶列表" selectedKey="user_account">

      <Button
        type="primary"
        onClick={() => {
          Main.exportToCSV(dataList, 'excel')
        }}
      >
        {t('export')}
      </Button>

      <UserAccountTable
        dataObj={dataList}
        companyChangeData={getAllData}
      />
    </NavAndSideFrame>
  )
}

export default UserAccount;
