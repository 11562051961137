import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Login from './pages/admin/Login';
import Home from './pages/admin/Home';
import CompanyUserList from './pages/admin/CompanyUserList';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import CompanyAdminLogin from './pages/company/Login';
import CompanyAdminHome from './pages/company/Home';

import ItemInfoList from './pages/company/ItemInfoList';
import CategoryInfoList from './pages/company/CategoryInfoList';
import UserBookingList from './pages/company/UserbookingList';
import CompanyUserBookingLogList from './pages/company/CompanyUserBookingLogList';

import UserQuotaContainer from './pages/company/UserQuotaContainer';
import CompanyHolidayList from './pages/company/CompanyHolidayList';

import CompanyUserQuotaLogList from './pages/company/CompanyUserQuotaLogList';


import CompanyUserAcList from './pages/company/CompanyUserAcList';
import CompanyUserAcContainer from './pages/company/CompanyUserAcContainer';


import UserAccount from './pages/company/UserAccount';
import UserAccountContainer from './pages/company/UserAccountContainer';
import CompanySettingContainer from './pages/company/CompanySettingContainer';

import Setting from './pages/company/Setting';
import SiteLogoForm from './pages/company/SiteLogoForm';
import StaticForm from './pages/company/StaticForm';
// import OptionSetting from './pages/company/OptionSetting';

import AdminAccountList from './pages/admin/AdminAccountList';
import CompanyList from './pages/admin/CompanyList'
// import CompanyDetails from './pages/admin/CompanyDetail'

import UserLogin from './pages/user/UserLogin';
import UserAccountProfileContainer from './pages/user/UserAccountProfileContainer';
import AvailableBookingCalendarContainer from './pages/user/AvailableBookingCalendarContainer';
import MyBookingCalendarContainer from './pages/user/MyBookingCalendarContainer';


import Home330 from './pages/330/Home';
import SurveyList from './pages/company/SurveyList';
import SurveyForm from './pages/company/SurveyForm';
import SurveyResponseList from './pages/company/SurveyResponseList';





class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          {/* 330 */}
          <PublicRoute restricted path="/330/home" component={Home330} exact />

          
          {/* Admin Router */}
          <PublicRoute restricted path="/admin/login" component={Login} exact />
          <PrivateRoute restricted path="/admin/home" component={Home} privilege={'admin'} exact />
          {/* <PrivateRoute restricted path="/controller_passcode_list" component={ControllerPasscodeList} exact /> */}
          {/* <PrivateRoute restricted path="/admin/controller_passcode_list" component={ControllerPasscodeList} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/controller_access_log" component={ControllerAccessLog} exact /> */}
          {/* <PrivateRoute restricted path="/admin/controller_access_log" component={ControllerAccessLog} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/controller_device_list" component={ControllerDeviceList} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/company/list" component={CompanyList} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/company/door" component={CompanyDoor} exact /> */}
          {/* <PrivateRoute restricted path="/admin/company/door" component={CompanyDoor} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/admin/company/user_role" component={CompanyUserRole} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/admin/user/door" component={CompanyUserDoor} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/admin/user/access_log" component={CompanyUserAccessLog} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/admin/user/door_qrcode" component={CompanyUserDoorQRCode} privilege={'admin'} exact /> */}
          <PrivateRoute restricted path="/admin/company/user" component={CompanyUserList} privilege={'admin'} exact />
          {/* <PrivateRoute restricted path="/admin/company/details" component={CompanyDetails} privilege={'admin'} exact /> */}
          {/* <PrivateRoute restricted path="/user_qrcode" component={UserQRCode} exact /> */}
          <PrivateRoute restricted path="/admin/list" component={AdminAccountList} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/user_account" component={UserAccount} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/company/list" component={CompanyList} privilege={'admin'} exact />


          {/* Company Router */}
          <PrivateRoute restricted path="/company/account_setting" component={UserAccountContainer} privilege={'company'} exact />
          <PublicRoute restricted path="/company/login" component={CompanyAdminLogin} exact />
          <PrivateRoute restricted path="/company/home" component={CompanyAdminHome} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/item/list" component={ItemInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/item/category/list" component={CategoryInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/booking_info" component={UserBookingList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/booking_log" component={CompanyUserBookingLogList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/quota/list" component={UserQuotaContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/holiday/list" component={CompanyHolidayList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/quota/list/log" component={CompanyUserQuotaLogList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user/list" component={CompanyUserAcList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user/form" component={CompanyUserAcContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/company_setting" component={CompanySettingContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/setting" component={Setting} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/logo/form" component={SiteLogoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/banner/form" component={SiteLogoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/static_content_form" component={StaticForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/survey" component={SurveyList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/survey/form" component={SurveyForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/survey/user/response" component={SurveyResponseList} privilege={'company'} exact />

          {/* User Router */}
          {/* <PublicRoute restricted path="/user/login" component={UserLogin} exact /> */}
          {/* <PrivateRoute restricted path="/user/home" component={CompanyAdminHome} privilege={'user'} exact /> */}
          {/* <PrivateRoute restricted path="/user/my_booking" component={MyBookingCalendarContainer} privilege={'user'} exact /> */}
          {/* <PrivateRoute restricted path="/user/available_booking" component={AvailableBookingCalendarContainer} privilege={'user'} exact /> */}
          {/* <PrivateRoute restricted path="/user/user_account_form" component={UserAccountProfileContainer} privilege={'user'} exact /> */}

          <Redirect exact from="/admin/*" to="/admin/home" />
          <Redirect exact from="*" to="/330/home" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
