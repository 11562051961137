import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

const LanguageSelector = (props) => {
  const { t, i18n } = useTranslation();
  const { size, style } = props

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Select
      style={{ ...style, width: 150 }}
      defaultValue={i18n.language}
      onChange={(value) => {
        changeLanguage(value)
      }}
      value={i18n.language}
      size={size}
    >
      <Option value="en">English</Option>
      <Option value="zh_hant">中文（繁體）</Option>
      {/* <Option value="zh_hans">中文（简体）</Option> */}
    </Select>
  )
}

export default LanguageSelector;
