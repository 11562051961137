import React, { useEffect, useState } from 'react';
import { Layout, Affix, Row, Col, Button, Grid } from 'antd';
import { } from '@ant-design/icons';
import _ from 'lodash';
import TNContent from '../../t9UIComponents/TNContent';
import AppDrawer from '../../components/330/AppDrawer';
import Logo_zh from '../../assets/330/newlife_CHI_web_4x-25.png';
import Logo_en from '../../assets/330/newlife_ENG_web_4x-13.png';
import {
	MenuOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';
import { MobileView, DesktopView } from '../330/ResponsiveView';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;
const { useBreakpoint } = Grid;

const AppHeader = ({ device }) => {
	return (
		<>
			<Affix offsetTop={0} style={styles.affix}>
				<UpperHeader device={device || 'mobile'} />
			</Affix>
		</>
	);
};

export const UpperHeader = () => {
	const dispatch = useDispatch();
	let { t, i18n } = useTranslation();
	const screens = useBreakpoint();
	const [isMobile, setMobilView] = useState(false);

	useEffect(() => {
		const isMobile = (screens.xs || screens.sm) && !screens.md;
		if (isMobile) {
			setMobilView(true)
		} else {
			setMobilView(false)
		}
	}, [screens]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	return (
		<TNContent fullWidth color='transparent'>
			{isMobile &&
				<Header style={{ ...styles.header, height: 60, margin: '20px auto' }}>
					<Row align='middle' justify='space-between' style={{ ...styles.header, height: 60 }}>
						<Col>
							<MenuOutlined color="#fff" style={{ fontSize: 26, color: '#fff' }} onClick={() => dispatch(CommonActions.setModalVisible(true))} />
						</Col>
						<Col>
							<img style={{ width: '35vw' }} src={i18n.language === 'en' ? Logo_en : Logo_zh} />
						</Col>
						<Col>
							<Row justify='end' gutter={[12, 0]}>
								<Col span={12} style={{ cursor: 'pointer' }} onClick={() => changeLanguage('zh_hant')}>
									<span style={{ ...styles.content, fontSize: 20 }}>中</span>
								</Col>
								<Col span={12} style={{ cursor: 'pointer' }} onClick={() => changeLanguage('en')}>
									<span style={{ ...styles.content, fontSize: 20 }}>EN</span>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* <Row justify='center'>
						<Col>
							<img style={{ width: '35vw' }} src={i18n.language === 'en' ? Logo_en : Logo_zh} />
						</Col>
					</Row> */}
				</Header>
			}
			{!isMobile &&
				<Header style={styles.header}>
					{/* <Row justify='center'>
						<Col style={{ width: 300, margin: 'auto'}}>
							<img style={{ width: '100%', margin: 'auto' }} src={i18n.language === 'en' ? Logo_en : Logo_zh} />
						</Col>
					</Row> */}
					<Row gutter={[20, 0]} align='middle' justify='space-between' style={{...styles.header, height: undefined}}>
						<div style={{ display: 'flex', flexDirection: 'row'}}>
								<Col style={{ ...styles.content }}>
									<Button
										type="text"
										style={{ ...styles.button, fontSize: i18n.language === 'en' ? 15: 16  }}
										onClick={() => {
											dispatch(CommonActions.setDrawerSelectedItem('background_info'));
											dispatch(CommonActions.setModalVisible(true));
										}}
									>
										{t('background_info')}
									</Button>
								</Col>
								<Col style={styles.content}>
									<Button
										type="text"
										style={{ ...styles.button, fontSize: i18n.language === 'en' ? 15: 16 }}
										onClick={() => {
											dispatch(CommonActions.setDrawerSelectedItem('questionnaire_form'));
											dispatch(CommonActions.setModalVisible(true));
										}}
									>
										{t('know_your_well_being')}
									</Button>
								</Col>
								<Col style={styles.content}>
									<Button
										type="text"
										style={{ ...styles.button, fontSize: i18n.language === 'en' ? 15: 16  }}
										onClick={() => {
											dispatch(CommonActions.setDrawerSelectedItem('contact_us'));
											dispatch(CommonActions.setModalVisible(true));
										}}
									>
										{t('contact_us')}
									</Button>
								</Col>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row'}}>
							<Col style={{ ...styles.content, textAlign: 'end',  }}>
								<Button
									type="text"
									style={{ ...styles.button, fontSize: i18n.language === 'en' ? 15: 16 }}
									onClick={() => {
										dispatch(CommonActions.setDrawerSelectedItem('tnc_statement'));
										dispatch(CommonActions.setModalVisible(true));
									}}
								>
									{t('tnc')}
								</Button>
							</Col>
							<Col style={{ cursor: 'pointer', textAlign: 'end'  }} onClick={() => changeLanguage('zh_hant')}>
								<span style={{ ...styles.content, fontSize: i18n.language === 'en' ? 15: 16 }}>中</span>
							</Col>
							<Col style={{ cursor: 'pointer', textAlign: 'end'  }} onClick={() => changeLanguage('en')}>
								<span style={{ ...styles.content, fontSize: i18n.language === 'en' ? 15: 16 }}>EN</span>
							</Col>
						</div>
					</Row>
					{/* <Col> */}
						<Row justify='center'>
							<Col style={{ width: 300, margin: 'auto'}}>
								<img style={{ width: '100%', margin: 'auto' }} src={i18n.language === 'en' ? Logo_en : Logo_zh} />
							</Col>
						</Row>
					{/* </Col> */}
				</Header>
			}
			<AppDrawer />
		</TNContent >
	);
};

const styles = {
	content: {
		color: '#fff',
		fontSize: 16,
	},
	button: {
		color: '#fff',
		fontSize: 16,
		padding: 0
	},
	header: {
		// paddingTop: 8,
		// paddingBottom: 8,
		// lineHeight: 3,
		// padding: 0,
		fontFamily: 'NotoSansTC',
		margin: 0,
		backgroundColor: 'transparent',
		height: 100,
	},
	icon: {
		color: '#707070',
		marginRight: 50,
		// marginTop: 50,
		fontSize: 20,
		transform: 'translateY(20%)',
	},
	affix: {
		zIndex: 10,
		position: 'sticky',
		position: '-webkit-sticky',
		width: '100%',
	},
};

export default AppHeader;
