import React, { useState, useEffect } from "react";
import { Button, Layout, Row, Col, Form, Radio } from "antd";
import { UpperHeader } from "../../components/330/AppHeader";
import AppFooter from "../../components/330/AppFooter";
import background330_WEB from "../../assets/330/newlife_CHI_web_4x-50.png";
import background330_MOBILE from "../../assets/330/newlife_CHI_mobile_4x-49.png";
import Wellcome_title_zh from "../../assets/330/newlife_CHI_web_4x-26.png";
import Wellcome_title_en from "../../assets/330/newlife_ENG_web_4x-14.png";
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';
import * as Service from '../../core/Service';
import _ from 'lodash';

import TNContent from "../../t9UIComponents/TNContent";
import { MobileView, DesktopView } from "../../components/330/ResponsiveView";

const settings = {
  // className: "banner-slider variable-width",
  // centerMode: true,
  variableWidth: true,
  dots: false,
  infinite: true,
  arrows: false,
  fade: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const Home = () => {
  return (
    <Layout>
      <MobileView>
        <TNContent fullWidth>
          <Row>
            <Col style={styles.bodyContainer}>
              <SliderComponent mode="mobile" />
              <HeaderComponent mode="mobile" />
              <SliderContent mode="mobile" />
              <StaticBackgroundImage mode="mobile" />
            </Col>
          </Row>
        </TNContent>
      </MobileView>
      <DesktopView>
        <TNContent fullWidth>
          <Row>
            <Col style={styles.bodyContainer}>
              <SliderComponent mode="desktop" />
              <HeaderComponent mode="desktop" />
              <SliderContent mode="desktop" />
              <StaticBackgroundImage mode="desktop" />
            </Col>
          </Row>
        </TNContent>
      </DesktopView>
      <AppFooter />
    </Layout>
  );
};

const SliderComponent = ({ mode }) => {
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL)

  const [images, setImages] = useState([]);
  useEffect(() => {
    getInitialData();
  }, [])

  const getInitialData = async () => {
    const resp = await Service.call('get', `/api/meta_by_key?meta_key=banner_landing`);
    let images = [];
    _.map(resp.data, (item) => {
      if (!item.meta_value) return;
      images.push(
        <div key={item.meta_value}>
          <img style={{ ...styles.img, height: "100vh" }} src={`/${item.meta_value}`} />
        </div>
      )
    })

    setImages(images)
  }

  if (mode === "mobile") {
    return (
      <div>
        <Slider {...settings} className="banner-slider-mobile">
          {images}
        </Slider>
      </div>
    );
  }

  if (mode === "desktop") {
    return (
      <div>
        <Slider {...settings} className="banner-slider">
          {images}
        </Slider>
      </div>
    );
  }

  return <></>;
};

const SliderContent = ({ mode }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()

  if (mode === "mobile") {
    return (
      <div style={{ ...styles.sliderContentDiv, left: 0, top: "16%", fontFamily: i18n.language === 'en' ? 'DINNextRoundedLTPro' : 'DFYuanHK-W8' }}>
        <Row gutter={[0, 20]} justify="center">
          <Col>
            <img alt="" src={i18n.language === 'en' ? Wellcome_title_en : Wellcome_title_zh} style={{ width: '60vw' }} />
          </Col>
          <div style={{ marginTop: 20 }}>
            <img alt="" style={{ maxWidth: "60vw" }} src={background330_MOBILE} />
          </div>
        </Row>
        <Row justify="center" align="middle" gutter={[0, 18]}>
          <Col span={18}>
            <Button
              size="large"
              style={styles.buttonMobile}
              onClick={() => {
                dispatch(CommonActions.setDrawerSelectedItem('questionnaire_form'));
                dispatch(CommonActions.setModalVisible(true));
              }}
            >
              <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                {i18n.language === 'en'
                 ? <div style={{fontSize: 22, lineHeight: 1.2}}>Get to Know <br /> your 330 Well-being</div>
                 : <div style={{fontSize: 28, marginTop: 5}}>瞭解您的身心靈健康</div>
                }
              </div>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  if (mode === "desktop") {
    return (
      <div>
        <div style={{ ...styles.sliderContentDiv, fontFamily: i18n.language === 'en' ? 'DINNextRoundedLTPro' : 'DFYuanHK-W8' }}>
          <Row gutter={[0, 20]}>
            <Col
              style={{
                // color: "#fff",
                // fontSize: 80,
                // fontWeight: "bold",
                // wordSpacing: "0.1em",
              }}
            >
              <img src={i18n.language === 'en' ? Wellcome_title_en : Wellcome_title_zh} style={{ width: '30vw' }} />
              {/* <span style={{ fontSize: 50 }}>{t('home_title_1')}</span>
              <br />
              {t('home_title_2')}
              <br />
              {t('home_title_3')} */}
            </Col>
          </Row>
          <Row align="middle" gutter={[0, 18]}>
            <Col span={18}>
              <Button
                size="large"
                className="home-btn"
                style={{ ...styles.button, maxWidth: 300, height: 'auto', padding: i18n.language === 'en' ? '10px 0px 0px 0px' : '10px auto auto auto' }}
                onClick={() => {
                  dispatch(CommonActions.setDrawerSelectedItem('questionnaire_form'));
                  dispatch(CommonActions.setModalVisible(true));
                }}
              >
                {i18n.language === 'en' ? <span style={{ fontSize: 22, lineHeight: 1.2 }}>Get to Know <br /> your 330 Well-being</span> : '瞭解您的身心靈健康 '}
                {/* {t('home_button_1')} */}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <></>;
};

const StaticBackgroundImage = ({ mode }) => {
  if (mode === 'desktop') {
    return (
      <div style={styles.staticBackgroundDiv}>
        <img style={{ width: 800, maxWidth: "80vw" }} src={background330_WEB} />
      </div>
    )
  }
  return (<></>)
}


const HeaderComponent = () => {
  return (
    <div style={styles.headerDiv}>
      <UpperHeader />
    </div>
  );
};

const styles = {
  bodyContainer: {
    position: "relative",
    width: "100vw"
  },
  img: {
    width: "100vw",
    // maxHeight: "80vh",
    objectFit: "cover",
  },
  button: {
    height: 65,
    width: "100%",
    // padding: 0,
    paddingTop: 12,
    backgroundColor: "#fff",
    borderRadius: 15,
    color: "#d7633d",
    fontSize: 26,
    fontWeight: "bold",
    fontFamily: "DINNextRoundedLTPro",
  },
  buttonMobile: {
    // minheight: 70,
    height: "min-content",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 15,
    color: "#d7633d",
    fontSize: '5vw',
    fontWeight: "bold",
    fontFamily: "DINNextRoundedLTPro",
  },
  headerDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  sliderContentDiv: {
    fontFamily: "DFYuanHK-W8",
    position: "absolute",
    top: "30%",
    left: "18%",
    width: '100%',
    maxWidth: 800,
    lineHeight: 1.2,
    zIndex: 3,
    wordSpacing: 3,
  },
  staticBackgroundDiv: {
    position: "absolute",
    top: "55%",
    left: "52%",
    transform: `translate(-45%, -48%)`,
    textAlign: "center",
    zIndex: 2,
  },
  staticBackgroundDivMobile: {
    position: "absolute",
    bottom: "8%",
    left: "0%",
    zIndex: 2,
  },
};

export default Home;
