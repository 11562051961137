import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Divider,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Form,
  Radio,
  message,
  notification
} from 'antd';
import _ from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../redux/actions/common';
import * as Service from '../../core/Service';
import { MobileView, DesktopView } from './ResponsiveView';

import { QuestionItemPart1 } from './QuestionItem';
import QuestionnaireFormStage1 from '../../assets/330/newlife_CHI_web_4x-43.png';
import QuestionnaireFormStage2 from '../../assets/330/newlife_CHI_web_4x-44.png';
import QuestionnaireResult from './QuestionnaireResult';

const { Text } = Typography;

const QuestionnaireForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [stage, setStage] = useState('basic_info');
  // const [stage, setStage] = useState('questionnaire_form_1'); // debug only
  // const [stage, setStage] = useState('result'); // debug only

  const [questionList, setQuestionList] = useState({});
  const [formValues, setFormValues] = useState({}); // Workaround for antd form job_other

  // Had to manually scroll back to top upon stage change
  useEffect(() => {
    // console.log('stage change scroll to top')
    setTimeout(
      () => document.getElementsByClassName("ant-modal-wrap")[0].scrollTo(0, 0),
      100
    );
  }, [stage]);

  useEffect(() => {
    getInitialData()
  }, []);

  const getInitialData = async () => {
    await Service.call("get", "/api/session/reset");
    let result = await Service.call('get', `/api/question/list`);
    // console.log('QuestionnaireForm result', result);
    result = _.orderBy(result, ['sort_order', 'asc']);
    setQuestionList(result);
  }
  // console.log('questoinContainerRef', questoinContainerRef.current.scrollTop = 0);

  return (
    <Form
      className='questionnaire_form'
      form={form}
      onValuesChange={(changedValues, values) => setFormValues(values)}
      layout='vertical'
    >
      <Col span={24} style={{}}>
        <Row justify='end'>
          <Col>
            <CloseOutlined
              style={{ fontSize: 24, color: '#fff' }}
              onClick={() => dispatch(CommonActions.setModalVisible(false))}
            />
          </Col>
        </Row>
      </Col>
      <div className='drawer-content-body'>
        <QuestionnaireContainer
          stage={stage}
          setStage={setStage}
          form={form}
          questionList={questionList}
        />
      </div>
    </Form>
  );
}

const QuestionnaireContainer = ({
  stage,
  setStage,
  form,
  postData,
  questionList,
}) => {
  const getItem = () => {
    // stage = 'result';
    switch (stage) {
      case 'basic_info':
        return (
          <BasicInfoForm
            form={form}
            setStage={setStage}
            postData={postData}
          />
        );
      case 'questionnaire_form_1':
        return (
          <QuestionnaireFormPart1
            form={form}
            setStage={setStage}
            postData={postData}
            questionList={questionList}
          />
        );
      case 'result':
        return (
          <QuestionnaireResult
            form={form}
            setStage={setStage}
          />
        );
      default:
        return (
          <BasicInfoForm
            form={form}
            setStage={setStage}
          />
        );
    }
  };

  return <>{getItem()}</>;
};

const BasicInfoForm = ({ setStage, form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form_data = useSelector((state) => state.form.form_data);

  const errorHandling = (formData, cb) => {
    let messages = [];

    if (formData.job === 'job_other' && formData.job_other === undefined) {
      notification.warn({
        message: `${t('job_other')} ${t('must_input')}`
      })
      return cb(true);
    }

    _.each(_.pick(formData, ['name', 'email', 'age', 'gender', 'agreement']), (value, key) => {
      if (value) {
        return
      }

      if (key === 'agreement' && !value) {
        return messages.push(`${t("info_disclaimer_title")} ${t("cannot_be_empty")}`)
      }

      return messages.push(`${t(key)} ${t('must_input')}`)
    })

    if (!_.isEmpty(messages)) {
      _.map(messages, (value) => {
        notification.warn({
          message: value
        })
      })
      return cb(true);
    }

    cb(false)
  }

  const next = () => {
    let formData = form.getFieldsValue();
    const emailErrorCount = form.getFieldError('email').length
    dispatch(
      CommonActions.setFormData({
        ...form_data,
        ...formData,
      })
    );
    console.log(formData);
    let hasError = false;
    errorHandling(formData, (data) => hasError = data);

    if (emailErrorCount > 0) {
      hasError = true;
      notification.warn({
        message: t('email_invalid')
      })
    }

    console.log('hasError', hasError)
    if (hasError) return;

    setStage('questionnaire_form_1');
  }

  return (
    <Row gutter={[40, 20]} align='middle'>
      <Col>
        <Text style={{ ...styles.title, color: '#fff' }}>
          {t('home_button_1')}
        </Text>
      </Col>
      <Col style={{ width: '100%', textAlign: 'center' }}>
        <MobileView>
          <img
            alt=""
            src={QuestionnaireFormStage1}
            style={{ width: '50%', margin: 'auto' }}
          />
        </MobileView>
        <DesktopView>
          <img
            alt=""
            src={QuestionnaireFormStage1}
            style={{ width: '25%', margin: 'auto' }}
          />
        </DesktopView>
      </Col>
      <Col span={24}>
        <Text style={{ ...styles.title, color: '#fff' }}>{t('about_you')}</Text>
        <br />
        <Text style={{ ...styles.text }}>* {t('must_input')}</Text>
      </Col>
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('age')}*</span>}
          name='age'
          style={{ ...styles.formItem }}
        >
          <Radio.Group className='custom-radio'>
            <Radio value="<20">{`<20`}</Radio>
            <Radio value="20-34">20-34</Radio>
            <Radio value="35-54">35-54</Radio>
            <Radio value=">55">{`>55`}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('gender')}*</span>}
          name='gender'
          style={{ ...styles.formItem }}
        >
          <Radio.Group className='custom-radio'>
            <Radio value="male">{t('male')}</Radio>
            <Radio value="female">{t('female')}</Radio>
            <Radio value="other">{t('gender_undisclosed')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('marital_status')}</span>}
          name='marital_status'
          style={{ ...styles.formItem }}
        >
          <Radio.Group className='custom-radio'>
            <Radio value="marital_status_never">{t('marital_status_never')}</Radio>
            <Radio value="marital_status_married">{t('marital_status_married')}</Radio>
            <Radio value="marital_status_divorced">{t('marital_status_divorced')}</Radio>
            <Radio value="marital_status_widowed">{t('marital_status_widowed')}</Radio>
            <Radio value="other">{t('other')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('edu_level')}</span>}
          name='edu_level'
          style={{ ...styles.formItem }}
        >
          <Radio.Group className='custom-radio' style={{ width: '100%' }}>
            <Radio value="edu_level_primary">{t('edu_level_primary')}</Radio>
            <Radio value="edu_level_low_secondary">{t('edu_level_low_secondary')}</Radio>
            <Radio value="edu_level_upper_scondary">{t('edu_level_upper_scondary')}</Radio>
            <Radio value="edu_level_assc">{t('edu_level_assc')}</Radio>
            <Radio value="edu_level_bachelor">{t('edu_level_bachelor')}</Radio>
            <Radio value="other">{t('other')}</Radio>
            <Radio value="edu_level_none">{t('edu_level_none')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('job')}</span>}
          name='job'
          style={{ ...styles.formItem }}
        >
          <Radio.Group className='custom-radio'>
            <Radio value="job_employer">{t('job_employer')}</Radio>
            <Radio value="job_administration">{t('job_administration')}</Radio>
            <Radio value="job_professionals">{t('job_professionals')}</Radio>
            <Radio value="job_clerical">{t('job_clerical')}</Radio>
            <Radio value="job_service">{t('job_service')}</Radio>
            <Radio value="job_sales">{t('job_sales')}</Radio>
            <Radio value="job_logistics">{t('job_logistics')}</Radio>
            <Radio value="job_homemaker">{t('job_homemaker')}</Radio>
            <Radio value="job_student">{t('job_student')}</Radio>
            <Radio value="job_retired">{t('job_retired')}</Radio>
            <Radio value="job_unemployed">{t('job_unemployed')}</Radio>
            <Radio value="job_other">{t('job_other')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="job_other" style={{display: form.getFieldValue('job') !== 'job_other' ? 'none' : 'block' }}>
          <Input className="custom-input" placeholder={t('job_other')} style={{ ...styles.input }} />
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('is_signed')}</span>}
          name='is_signed'
          style={{ ...styles.formItem }}
        >
          <Radio.Group
            className='custom-radio'
            onChange={(e) => {
              if (e.target.value === 'no') {
                return form.setFieldsValue({ signed_time: 'no' })
              }
              return form.setFieldsValue({ signed_time: null })
            }}
          >
            <Radio value="yes">{t('yes')}</Radio>
            <Radio value="no">{t('no')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col span={24}>
        <Form.Item
          label={<span style={{ ...styles.label }}>{t('signed_time')}</span>}
          name='signed_time'
          style={{ ...styles.formItem }}
        >
          <Radio.Group
            className='custom-radio'
            onChange={(e) => {
              if (e.target.value === 'no') {
                return form.setFieldsValue({ is_signed: 'no' })
              }
              return form.setFieldsValue({ is_signed: 'yes' })
            }}
          >
            <Radio value="one_month_before">{t('one_month_before')}</Radio>
            <Radio value="two_month_before">{t('two_month_before')}</Radio>
            <Radio value="three_month_before">{t('three_month_before')}</Radio>
            <Radio value="one_year_before">{t('one_year_before')}</Radio>
            <Radio value="other">{t('other')}</Radio>
            <Radio value="no">{t('no')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Divider style={{ ...styles.divider }} />
      <Col>
        <Text style={{ ...styles.label }}>
          {t("info_disclaimer_title")}
        </Text>
        <br />
        <Text style={{ ...styles.label, fontSize: 16 }}>
          {t("info_disclaimer_desc")}
        </Text>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          style={{ marginTop: 15 }}
        >
          <Checkbox>
            <Text style={{ fontSize: 12, color: "white", fontWeight: "bold" }}>
              {t("info_disclaimer_checkbox")}
            </Text>
          </Checkbox>
        </Form.Item>
        <Text style={{ ...styles.label, fontSize: 16, whiteSpace: "pre-wrap" }}>
          {t("info_disclaimer_privacy")}
        </Text>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        style={{ margin: 'auto', marginTop: 10 }}
      >
        <Form.Item style={{ ...styles.formItem }}>
          <Button
            htmlType="submit"
            style={{ ...styles.button }}
            onClick={next}
          >
            {t('button_next')}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

const QuestionnaireFormPart1 = ({ setStage, form, questionList }) => {
  // console.log('part 1 questionList', questionList)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const form_data = useSelector((state) => state.form.form_data);
  const [loading, setLoading] = useState(false);

  const errorHandling = (formData, cb) => {
    let messages = [];
    _.each(formData, (item, key) => {
      if (item.answer === null || item.answer === undefined) {
        return messages.push(item)
      }
    })

    if (!_.isEmpty(messages)) {
      messages.map((value) => {
        notification.warn({
          message: `${t("question")}${value.question_id} ${t("cannot_be_empty")}`
        })
      })
      return cb(true);
    }


    cb(false)
  }

  const goBack = () => {
    dispatch(
      CommonActions.setFormData({
        ...form_data,
        ...form.getFieldsValue(),
      })
    );
    setStage('basic_info');
  }

  const onFinish = async () => {
    let formData = {
      ...form_data,
      ...form.getFieldsValue(),
      job: form.getFieldValue('job') === 'job_other' ? form.getFieldValue('job_other') : form.getFieldValue('job')
    }
    dispatch(
      CommonActions.setFormData(formData)
    );

    let questions = [];
    _.pickBy(formData, (value, key) => {
      if (_.startsWith(key, 'question')) {
        let question_id = _.split(key, '_', 2)[1];
        questions.push({ question_id, answer: value });
      }
    });

    let hasError = false;
    errorHandling(questions, (data) => hasError = data);

    console.log('hasError', hasError)
    if (hasError) return;

    setLoading(true);
    let result = await Service.call('post', '/api/response_user', formData)
    if (result.status <= 0) {
      return message.error(result.errorMessage)
    }
    result = await Service.call('post', '/api/response', { questions });
    if (result.status <= 0) {
      return message.error(result.errorMessage)
    }
    setLoading(false)
    setStage('result')
  }


  return (
    <Row gutter={[0, 20]}>
      <Col>
        <Text style={{ ...styles.title, color: '#fff' }}>
          {t('home_button_1')}
        </Text>
      </Col>
      <Col style={{ width: '100%', textAlign: 'center' }}>
        <MobileView>
          <img
            alt=""
            src={QuestionnaireFormStage2}
            style={{ width: '50%', margin: 'auto' }}
          />
        </MobileView>
        <DesktopView>
          <img
            alt=""
            src={QuestionnaireFormStage2}
            style={{ width: '25%', margin: 'auto' }}
          />
        </DesktopView>
      </Col>
      <Col>
        <Text style={{ ...styles.text, fontSize: 18 }}>
          {t('well_being_scale_desc')}
        </Text>
      </Col>
      <Divider
        style={{ ...styles.divider, borderColor: '#fff', borderWidth: 2 }}
      />
      <Col span={24}>
        <Row gutter={[48, 0]}>
          {_.map(questionList, (question) => <QuestionItemPart1 question={question} key={question.question_id} />)}
        </Row>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Row gutter={[12, 0]} justify='center'>
          <Col xs={12} sm={12} md={6} lg={5}>
            <Form.Item style={{ ...styles.formItem }}>
              <Button
                style={{ ...styles.button }}
                onClick={goBack}
              >
                {t('button_back')}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5}>
            <Form.Item style={{ ...styles.formItem }}>
              <Button
                style={{ ...styles.button }}
                loading={loading}
                onClick={onFinish}
              >
                {t('submit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const styles = {
  button: {
    height: 50,
    fontWeight: 'bold',
    borderColor: '#fff',
    color: '#d7633e',
    fontSize: 22,
    width: '100%',
    borderRadius: 12,
  },
  text: {
    fontSize: 26,
    color: '#fff',
    fontWeight: '500',
  },
  title: {
    color: '#d7633e',
    fontSize: 26,
    fontWeight: 'bold',
  },
  text: {
    color: '#6e5333',
    fontSize: 14,
  },
  label: {
    color: '#6e5333',
    fontSize: 22,
  },
  divider: {
    margin: '5px 0px',
    borderTop: '1px solid #6e5333',
  },
  formItem: {
    marginBottom: 0,
  },
  input: {
    color: '#fff',
    borderColor: '#fff',
    fontSize: 22,
    backgroundColor: 'transparent',
    width: '100%',
    borderWidth: 2,
    borderRadius: 10,
    height: 40,
    paddingLeft: 20,
  },
};

export default QuestionnaireForm;
