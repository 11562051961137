import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useSelector } from 'react-redux';

const ItemInfoListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "item_id";
  const app = useSelector((state) => state.app);

  const [dataList, setDataList] = useState(props.dataObj);

  let { categoryList, setModalVisible, setSelectedRecord, loading, deleteRecord, isActiveChangeCallBack } = props

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    isActiveChangeCallBack(record.item_id, is_active)
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deleteRecord(record.item_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    // style={{ marginRight: 8}}
                    icon={<DeleteOutlined />}
                    // onClick={() => {
                    //   setSelectedRecord(record)
                    // }}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      },
      {
        title: t('id'),
        dataIndex: 'item_id',
        sorter: (a, b) => a.item_id - b.item_id
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name)
      },
      // {
      //   title: t('desc'),
      //   dataIndex: 'item_desc',
      //   sorter: (a, b) => a.item_desc.localeCompare(b.item_desc)
      // },
      {
        title: t('category'),
        dataIndex: 'item_category_id',
        render: (value) => {
          return categoryList.map(
            (category) => {
              if (category.item_category_id === value) {
                return category.category_name
              }
            }
          )
        },
        sorter: (a, b) => a.item_category_id - b.item_category_id,
      },
      {
        title: t('section_price'),
        dataIndex: 'section_price',
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('section_duration'),
        dataIndex: 'section_duration',
        sorter: (a, b) => a.section_duration - b.section_duration,
        render: (value) => {
          return Math.round(value / 60) + t('mins')
        },
      },
      {
        title: t('buffer'),
        dataIndex: 'buffer',
        sorter: (a, b) => a.buffer - b.buffer,
        render: (value) => {
          return Math.round(value / 60) + t('mins')
        },
      },
      {
        title: t('repeat_type'),
        dataIndex: 'repeat_type',
        sorter: (a, b) => a.repeat_type - b.repeat_type,
        render: (value) => {
          return displayRepeatType(value)
        },
      },
      {
        title: t('start_booking_time'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (value) => {
          return moment(value, "HH:mm").format("HH:mm")
        },
      },
      {
        title: t('end_booking_time'),
        dataIndex: 'end_time',
        sorter: (a, b) => a.end_time - b.end_time,
        render: (value) => {
          return moment(value, "HH:mm").format("HH:mm")
        },
      },
      {
        title: t('start_booking_day'),
        dataIndex: 'start_day',
        sorter: (a, b) => a.start_day - b.start_day,
        render: (value) => {
          return moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('end_booking_day'),
        dataIndex: 'end_day',
        sorter: (a, b) => a.end_day - b.end_day,
        render: (value) => {
          return moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('prebooking_type'),
        dataIndex: 'prebooking_type',
        sorter: (a, b) => a.prebooking_type - b.prebooking_type,
        render: (value) => {
          return displayPrebookingType(value)
        },
      },
      {
        title: t('quota_required'),
        dataIndex: 'quota',
        sorter: (a, b) => a.quota - b.quota,
        // srender: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('mon'),
        dataIndex: 'available_mon',
        sorter: (a, b) => a.available_mon - b.available_mon,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('tue'),
        dataIndex: 'available_tue',
        sorter: (a, b) => a.available_tue - b.available_tue,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('wed'),
        dataIndex: 'available_wed',
        sorter: (a, b) => a.available_wed - b.available_wed,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('thu'),
        dataIndex: 'available_thu',
        sorter: (a, b) => a.available_thu - b.available_thu,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('fri'),
        dataIndex: 'available_fri',
        sorter: (a, b) => a.available_fri - b.available_fri,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('sat'),
        dataIndex: 'available_sat',
        sorter: (a, b) => a.available_sat - b.available_sat,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('sun'),
        dataIndex: 'available_sun',
        sorter: (a, b) => a.available_sun - b.available_sun,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('ph'),
        dataIndex: 'available_public_holiday',
        sorter: (a, b) => a.available_public_holiday - b.available_public_holiday,
        render: (value) => {
          return (value) ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayPrebookingType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("one_week_before");
        break;
      case 2:
        displayStr = t("one_month_before");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayRepeatType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("weekly");
        break;
      case 0:
        displayStr = t("error");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 2:
        displayStr = t("waiting");
        tagColor = 'dark grey';
        break;
      case 1:
        displayStr = t('success');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("waiting");
        tagColor = 'red';
        break;
      case -1:
        displayStr = t('fail');
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{pageSize: 100}}
        loading={loading}
      />
    </Layout>

  )

}

export default ItemInfoListTable;