import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from '../components/ModalLayout';
import { useSelector } from 'react-redux';

const { Option } = Select;

const CompanyUserForm = (props) => {
  const [form] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const company_admin = useSelector(state => state.app.company_admin);

  useEffect(() => {
    getCompanyUserRole();
    form.resetFields();
  }, [props.dataObj]);

  const getCompanyUserRole = async () => {
    let roles = [];
    let companyList = [];
    try {
      // TODO :: remove company_id after have session
      // let company_id = 1;
      let company_id = 0;
      if (props.dataObj.company_id) {
        company_id = props.dataObj.company_id;
      }
      let companyData = await Service.call('get', `/api/company/list`);
      let data = await Service.call('get', `/api/company/user_role/${company_id}`);
      let { userRoles } = data;
      roles = _.orderBy(userRoles, ['utime'], ['desc']);
      roles = _.uniqBy(roles, 'company_user_role');
      companyList = _.orderBy(companyData, ['company_id']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setRoleList(roles);
      setCompanyList(companyList);
    }
  }

  const onFinish = async (postObj) => {
    let url = `/api/company/user`;
    if (company_admin.company_admin_id != null) {
      postObj.company_id = company_admin.company_id;
      postObj.company_admin_id = company_admin.company_admin_id;
    }
    //Patch
    if (props.dataObj.company_user_id > 0) {
      postObj.company_admin_id = props.dataObj.company_admin_id;
      postObj.company_user_id = props.dataObj.company_user_id;
      let data = await Service.call('patch', url, postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // POST
    postObj.create_time = moment().unix();
    let data = await Service.call('post', url, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };

  const getCompanyRole = async (company_id) => {
    let roles = [];
    try {
      let data = await Service.call('get', `/api/company/user_role/${company_id}`);
      let { userRoles } = data;
      roles = _.orderBy(userRoles, ['utime'], ['desc']);
      roles = _.uniqBy(roles, 'company_user_role');
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setRoleList(roles);
    }
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={props.dataObj}
    >
      {/* <Form.Item
        label="Company"
        name="company_id"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Select
          placeholder="- select one -"
          disabled={props.dataObj.company_id}
          onChange={(value)=> {
            getCompanyRole(value)
          }}
        >
          {companyList.map(({ company_id, company_name }) =>
            (<Option value={company_id}>{`(${company_id}) `+company_name}</Option>)
          )}
        </Select>
      </Form.Item> */}
      <Form.Item
        label="用戶名稱"
        name="username"
        rules={[{ required: true, message: 'Please input username.' }]}
      >
        <Input disabled={props.dataObj.username} />
      </Form.Item>
      <Form.Item
        label="名字"
        name="first_name"
        rules={[{ required: true, message: 'Please input first name.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="姓氏"
        name="last_name"
        rules={[{ required: true, message: 'Please input last name.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="暱稱"
        name="nickname"
        rules={[{ required: false, message: 'Please input nickname.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="性別"
        name="gender"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Radio.Group>
          <Radio value="M">Male</Radio>
          <Radio value="F">Female</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="手機號碼"
        name="mobile"
        rules={[{ required: true, message: 'Please input mobile.' }]}
      >
        <Input disabled={props.dataObj.mobile} />
      </Form.Item>
      <Form.Item
        label="電子郵件"
        name="email"
        rules={[{ required: true, message: 'Please input email.', type: 'email' }]}
      >
        <Input disabled={props.dataObj.email} />
      </Form.Item>
      {/* <Form.Item
        label="QR Code Permission"
        name="privilege_scan_qrcode"
        rules={[{ required: true, message: 'Please select one.', type: 'number' }]}
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item> */}
      {/* <Form.Item
        label="Admin Permission"
        name="is_admin"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item> */}
      {/* <Form.Item
        label="Company User Role"
        name="company_user_role"
        rules={[{ required: true, message: 'Please select one.' }]}
      >
        <Select placeholder="- select one -">
          {roleList.map(({ company_user_role }) =>
            (<Option value={company_user_role}>{company_user_role}</Option>)
          )}
        </Select>
      </Form.Item> */}

      <Form.Item
        label="密碼"
        name="password"
        rules={[
          {
            required: true,
            message: "密碼必填",
          },
        ]}
      >
        <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Form.Item>

      <Form.Item
        label="確認密碼"
        name="passwordConfirm"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: "確認密碼必填",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject("密碼不相同");
            },
          }),
        ]}
      >
        <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Form.Item>

      <Form.Item
        label="活動"
        name="is_active"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value={1}>活動</Radio>
          <Radio value={0}>不活動</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyUserForm;
