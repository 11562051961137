import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Divider, Form, Input, Tag, Row, Col, Card
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const CompanySettingForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation()
  const { initialValues } = props

  const [UserForm] = Form.useForm();

  useEffect(() => {

  }, [])

  useEffect(() => {
    UserForm.resetFields()
  }, [initialValues])

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t('yes');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("no");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={async (postObj) => {
              // let method = 'post';
              // if (!_.isUndefined(invoice_info_id)) {
              //   method = 'patch';
              //   postObj.invoice_info_id = invoice_info_id;
              // }

              // let results = await Service.call(method, '/api/invoice_info', postObj)
              // // console.log('results', results)

              // if (results.status !== 1) {
              //   notification.error({ message: '提交失敗', description: results.errorMessage });
              //   return;
              // }

              // notification.success({ message: '提交成功' });
              // history.push(`/company/invoice_info`);
            }}
          >
            <Form.Item
              label={t('company_name')}
            >
              {initialValues.company_name}
            </Form.Item>

            <Form.Item
              label={t('first_name')}
              name="first_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('last_name')}
              name="last_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('username')}
              name="username"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('nickname')}
              name="nickname"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('email')}
              name="email"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('mobile')}
              name="mobile"
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                {t('edit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col md={24} lg={8}>
        <Card>
          <h4>{t('last_active_time')}</h4>
          <p>{moment.unix(initialValues.last_active_time).format('YYYY-MM-DD HH:mm')}</p>
          <h4>{t('create_time')}</h4>
          <p>{moment.unix(initialValues.create_time).format('YYYY-MM-DD HH:mm')}</p>
          <h4>{t('is_verified_email')}</h4>
          <p>{displayStatus(initialValues.is_verified_email)}</p>
          <h4>{t('is_verified_mobile')}</h4>
          <p>{displayStatus(initialValues.is_verified_mobile)}</p>
        </Card>
      </Col>
    </Row>
  )
}

export default CompanySettingForm;
