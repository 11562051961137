import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Card,
} from "antd";
import moment from "moment";
import * as Main from "../core/Main";
import * as Service from "../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const UserAcResetPasswordForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const [number, setNumber] = useState("");
  const history = useHistory();

  const { initialValues } = props;

  const [UserForm] = Form.useForm();

  useEffect(() => {
    UserForm.resetFields();
  }, []);

  const onFinish = async (formData) => {
    formData.user_id = id;

    if (id > 0) {
      let response = await Service.call(
        "patch",
        `/api/user/password`,
        formData
      );
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t("error"),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      if (id === 0) {
        history.push("/company/user/list");
      } else {
        history.push(`/company/user/list?key=${key}`);
      }
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label={t("edit_password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: "必須填寫",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("confirm_password")}
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "必須填寫",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserAcResetPasswordForm;
