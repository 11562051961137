import React, { useState, useEffect } from 'react';
import {
  Tabs
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

import * as Service from '../../core/Service';

import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserAccountForm from './UserAccountForm';

const { TabPane } = Tabs;

const UserAccountProfileContainer = (props) => {
  const { t } = useTranslation();

  const [userObj, setUser] = useState({});
  let user_id = useSelector(state => state.app.user.user_id)

  useEffect(() => {
    fetchUser()
  }, []);

  const fetchUser = async () => {
    try {
      let result = await Service.call('get', `/api/user/${user_id}`);
      setUser(result);
      console.log('result>>>', result)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={`${t('edit')} ${t('user')}`} selectedKey="user_account">
      <Tabs type="card" defaultActiveKey="user_form" onChange={(key) => null}>
        <TabPane tab={t('user_info')} key="user_form">
          <UserAccountForm
            initialValues={userObj}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default UserAccountProfileContainer;

