import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import CompanyUserTable from '../../components/CompanyUserTable';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const title = "員工列表";
const selectedKey = 3;
const tableIDName = "company_user_id";

const CompanyUserList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordRecord, setPasswordRecord] = useState({});

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    let dataList = [];
    try {
      let url = `/api/${involvedModelName}/user`;
      let data = await Service.call('get', url);
      let { companyUsers, companies } = data;
      // dataList = Main.mergeByKey(companyUsers, companyAdmins, 'company_admin_id');
      dataList = Main.mergeByKey(companyUsers, companies, 'company_id');
      dataList = _.orderBy(dataList, ['company_user_id']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataList);
    }
  }

  // const setTableHeader = () => {
  //   const columns = [
  //     {
  //       title: 'Operation',
  //       dataIndex: tableIDName,
  //       render: (value, record) => {
  //         console.log('Record>>>>>', record);
  //         let status = (record.status === 1);
  //         let color = '#000000';
  //         let icon = '';
  //         let wordings = '';
  //         if (status) {
  //           color = '#AA0000'
  //           icon = 'stop';
  //           wordings = 'Disable';
  //         } else {
  //           color = '#00AA00'
  //           icon = 'check';
  //           wordings = 'Enable';
  //         }
  //         return (
  //           <span>
  //             <Tooltip title="Edit">
  //               <Button
  //                 shape="circle"
  //                 style={{ marginRight: 8, color: '#000000' }}
  //                 icon={<EditOutlined />}
  //                 onClick={() => {
  //                   setModalVisible(true);
  //                   setSelectedRecord(record)
  //                 }}
  //               />
  //             </Tooltip>
  //             <Tooltip title="Password">
  //               <Button 
  //                 shape = "circle"
  //                 style={{marginRight: 8, color: '#000000'}}
  //                 icon={<KeyOutlined />}
  //                 onClick={() => {
  //                   setPasswordVisible(true);
  //                   setPasswordRecord(record);
  //                 }}
  //               />
  //             </Tooltip>
  //             <Link to={{
  //               pathname: '/user/access_log',
  //               state: {
  //                 company_user_id: record.company_user_id
  //               }
  //             }}>
  //               <Tooltip title="Access Log">
  //                 <Button
  //                   style={{ marginRight: 8, color: '#000000' }}
  //                   shape="circle"
  //                   icon={<FileTextOutlined />}
  //                 />
  //               </Tooltip>
  //             </Link>
  //             <Link to={{
  //               pathname: '/user/door',
  //               state: {
  //                 company_user_id: record.company_user_id,
  //                 company_id: record.company_id,
  //                 company_name: record.company_name
  //               }
  //             }}>
  //               <Tooltip title="User Doors">
  //                 <Button
  //                   style={{ marginRight: 8, color: '#000000' }}
  //                   shape="circle"
  //                   icon={<BankOutlined />}
  //                 />
  //               </Tooltip>
  //             </Link>
  //             <Link to={{
  //               pathname: '/user/door_qrcode',
  //               state: {
  //                 company_user_id: record.company_user_id,
  //                 company_name: record.company_name
  //               }
  //             }}>
  //               <Tooltip title="User QR Codes">
  //                 <Button
  //                   style={{ color: '#000000' }}
  //                   shape="circle"
  //                   icon={<QrcodeOutlined />}
  //                 />
  //               </Tooltip>
  //             </Link>
  //           </span>
  //         )
  //       }
  //     },
  //     {
  //       title: `Company User ID`,
  //       dataIndex: `company_user_id`,
  //       sorter: (a, b) => a.company_user_id - b.company_user_id,
  //     },
  //     {
  //       title: 'Status',
  //       dataIndex: 'status',
  //       render: (value) => displayIsActive(value),
  //       sorter: (a, b) => a.status - b.status
  //     },
  //     // {title: 'Username', dataIndex: 'username', align: 'center'},
  //     // {title: 'Password', dataIndex: 'password'},
  //     {
  //       title: 'First Name',
  //       dataIndex: 'first_name',
  //       sorter: (a, b) => a.first_name.localeCompare(b.first_name)
  //     },
  //     {
  //       title: 'Last Name',
  //       dataIndex: 'last_name',
  //       sorter: (a, b) => a.last_name.localeCompare(b.last_name)
  //     },
  //     {
  //       title: 'Company',
  //       dataIndex: `company_name`,
  //       sorter: (a, b) => a.company_name.localeCompare(b.company_name)
  //     },
  //     // {title: 'Nickname', dataIndex: 'nickname'},
  //     {
  //       title: 'Role',
  //       dataIndex: 'company_user_role',
  //       sorter: (a, b) => a.company_user_role.localeCompare(b.company_user_role)
  //     },
  //     {
  //       title: 'Admin Permission',
  //       dataIndex: 'is_admin',
  //       render: (value) => displayStatus(value),
  //       sorter: (a, b) => a.is_admin - b.is_admin,
  //       align: 'center'
  //     },
  //     {
  //       title: 'QR Ccode Permission',
  //       dataIndex: 'privilege_scan_qrcode',
  //       render: (value) => displayStatus(value),
  //       sorter: (a, b) => a.privilege_scan_qrcode - b.privilege_scan_qrcode,
  //       align: 'center'
  //     },
  //     {
  //       title: 'Gender',
  //       dataIndex: 'gender',
  //       sorter: (a, b) => a.gender.localeCompare(b.gender),
  //       align: 'center'
  //     },
  //     {
  //       title: 'Mobile',
  //       dataIndex: 'mobile',
  //       sorter: (a, b) => a.mobile.localeCompare(b.mobile)
  //     },
  //     {
  //       title: 'Mobile Verify',
  //       dataIndex: 'is_verified_mobile',
  //       render: (value) => displayStatus(value),
  //       sorter: (a, b) => a.is_verified_mobile - b.is_verified_mobile,
  //       align: 'center'
  //     },
  //     {
  //       title: 'Email',
  //       dataIndex: 'email',
  //       sorter: (a, b) => a.email.localeCompare(b.email)
  //     },
  //     {
  //       title: 'Email Verify',
  //       dataIndex: 'is_verified_email',
  //       render: (value) => displayStatus(value),
  //       sorter: (a, b) => a.is_verified_email - b.is_verified_email,
  //       align: 'center'
  //     },
  //     // {title: 'Is active', dataIndex: 'is_active', render: (value) => displayStatus(value)},
  //   ];
  //   return columns;
  // }

  // const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD') => {
  //   let displayTime = '';
  //   if (unixTime !== 0) {
  //     displayTime = moment.unix(unixTime).format(outputFormat);
  //   } else {
  //     displayTime = '-'
  //   }
  //   return displayTime;
  // }

  // const displayIsActive = (value) => {
  //   let displayStr = '';
  //   let tagColor = 'green';
  //   let statusValue = _.toInteger(value);
  //   switch (statusValue) {
  //     case 1:
  //       displayStr = "Active";
  //       tagColor = 'green';
  //       break;
  //     case 0:
  //       displayStr = "Inactive";
  //       tagColor = 'red';
  //       break;
  //     default:
  //       displayStr = "Error"
  //       tagColor = '#f50';
  //       break;
  //   }
  //   return <Tag color={tagColor}>{displayStr}</Tag>;
  // }

  // const displayStatus = (value) => {
  //   let displayStr = '';
  //   let tagColor = 'green';
  //   let statusValue = _.toInteger(value);
  //   switch (statusValue) {
  //     case 1:
  //       displayStr = "Yes";
  //       tagColor = 'green';
  //       break;
  //     case 0:
  //       displayStr = "No";
  //       tagColor = 'red';
  //       break;
  //     case -1:
  //       displayStr = "Disable";
  //       tagColor = '#9A9A9A';
  //       break;
  //     default:
  //       displayStr = "Error"
  //       tagColor = '#f50';
  //       break;
  //   }
  //   return <Tag color={tagColor}>{displayStr}</Tag>;
  // }

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey}>
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_user_id: 0 });
          setModalVisible(true);
        }}
      >
        新增員工
      </Button>
      <Divider />
      {/* <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}

      /> */}
      <CompanyUserTable
      dataObj = {dataList}
      adminChangeData = {getAllData}
      />
      <Modal
        title="新增"
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width={'90%'}
        onCancel={() => { setModalVisible(false) }}
      >
        <CompanyUserForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              getAllData();
              setModalVisible(value)
            }
          }
        />
      </Modal>
      {/* <Modal
        title="Edit"
        visible={passwordVisible}
        footer={null}
        style={{maxWidth: 800}}
        width={'90%'}
        onCancel={() => { setPasswordVisible(false)}}
      >
          <CanpanyUserPasswordForm
            dataObj= {passwordRecord}
            openModal={
              (value) => {
                getAllData();
                setPasswordVisible(value)
              }
            }
        />
      </Modal> */}
      
    </NavAndSideFrame>
  )
}

export default CompanyUserList;