import _ from 'lodash';
import moment from 'moment';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import { getStore } from '../redux/store/configureStore';
import * as Service from './Service'

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

// export function getUser() {
//   return getStore().getState().app.user;
// }

// export function getCompany() {
//   return getStore().getState().app.company;
// }

// export function signOut() {
//   localStorage.setItem('user');
//   localStorage.setItem('auth', JSON.stringify(false));
//   localStorage.removeItem('company');
// }

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    })
  })
}

export function convertOrderInfoDisplayData(orderInfoObject) {
  //  Basic Info
  let [prefixMobile, owner_mobile] = _.split(orderInfoObject.owner_mobile, '-');
  if (!_.isEmpty(prefixMobile)) orderInfoObject.prefixMobile = prefixMobile;
  if (!_.isEmpty(owner_mobile)) orderInfoObject.owner_mobile = owner_mobile;
  if (_.isInteger(orderInfoObject.pick_up_time)) orderInfoObject.pick_up_time = moment.unix(orderInfoObject.pick_up_time);

  //  Backup Info
  if (!_.isEmpty(orderInfoObject.pet_birthday)) orderInfoObject.pet_birthday = moment(orderInfoObject.pet_birthday);
  let [contactPersonMobilePrefix, contact_person_mobile] = _.split(orderInfoObject.contact_person_mobile, '-');
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contactPersonMobilePrefix = contactPersonMobilePrefix;
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contact_person_mobile = contact_person_mobile;

  //  Deposit
  if (_.isInteger(orderInfoObject.payment_date)) orderInfoObject.payment_date = moment.unix(orderInfoObject.payment_date);

  //  Service Detail
  if (_.isInteger(orderInfoObject.meet_up_time)) orderInfoObject.meet_up_time = moment.unix(orderInfoObject.meet_up_time);
  if (_.isInteger(orderInfoObject.ceremony_time)) orderInfoObject.ceremony_time = moment.unix(orderInfoObject.ceremony_time);
  if (_.isInteger(orderInfoObject.ashes_pick_up_time)) orderInfoObject.ashes_pick_up_time = moment.unix(orderInfoObject.ashes_pick_up_time);
  if (!_.isEmpty(orderInfoObject.ceremony_hair_treatment)) {
    orderInfoObject.ceremony_hair_treatment = JSON.parse(orderInfoObject.ceremony_hair_treatment);
  } else {
    orderInfoObject.ceremony_hair_treatment = []
  }

  //  Ashes Space
  if (_.isInteger(orderInfoObject.ashes_space_start_date)) orderInfoObject.ashes_space_start_date = moment.unix(orderInfoObject.ashes_space_start_date);
  if (_.isInteger(orderInfoObject.ashes_space_end_date)) orderInfoObject.ashes_space_end_date = moment.unix(orderInfoObject.ashes_space_end_date);

  //  Plant Space
  if (_.isInteger(orderInfoObject.plant_space_start_date)) orderInfoObject.plant_space_start_date = moment.unix(orderInfoObject.plant_space_start_date);
  if (_.isInteger(orderInfoObject.plant_space_end_date)) orderInfoObject.plant_space_end_date = moment.unix(orderInfoObject.plant_space_end_date);

  return orderInfoObject
}

export async function fetchFormOption(type) {
  let results = await Service.call('get', `/api/form_option/${type}`);
  // console.log('results', results);
  if (results.status !== 1) {
    return;
  }

  let options = results.data.map((item) => {
    return { value: item.name }
  })

  return options
}

export async function exportToCSV(dataList, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(dataList);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}
