import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyHolidayListTable from './CompanyHolidayListTable';
import CompanyHolidayInfoModal from './CompanyHolidayInfoModal';


const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = "holiday_management";
const tableIDName = "company_user_id";

const CompanyHolidayList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const [holidayList, setholidayList] = useState([]);

  const title = t('company_holiday');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)


    let getdataList = []
    try {
      let url = "/api/company/holiday/list";
      let response = await Service.call('get', url);
      getdataList = response

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setholidayList(getdataList);
      setLoading(false)
    }
  }

  const handleFormSubmit = async (submitRecord) => {

    submitRecord.date =moment(submitRecord.date).unix()
    let submit = await Service.call('put', '/api/company/holiday', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true)
    let submit = await Service.call('put', '/api/item', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      item_id: selectedRecord.item_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/item', patchData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {

    let postData = {
      "company_holiday_id": deleteRecord
    }
    let submit = await Service.call('put', '/api/company/holiday/delete', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleIsActiveChange = async (item_id, value) => {
    setLoading(true)
    let postData = {
      item_id,
      is_active: value
    }
    let submit = await Service.call('patch', '/api/item/is_active', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({ item_id: 0 })
        }}
      >
        {t('add_item')}
      </Button>
      <Divider />

      <CompanyHolidayListTable
        holidayList={holidayList}

        deleteRecord={(record) => handleDeleteRecord(record)}

        loading={loading}
      />
      <CompanyHolidayInfoModal
        selectedRecord={selectedRecord}
        categoryList={categoryList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  )
}

export default CompanyHolidayList;