import React, { useState, useEffect } from 'react';
import {
  Tabs
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import * as Service from '../../core/Service';

import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserAcInfoForm from '../../components/UserAcInfoForm';
import UserAcStatusForm from '../../components/UserAcStatusForm';
import UserAcResetPasswordForm from '../../components/UserAcResetPasswordForm';
import UserAcQuotaList from '../../components/UserAcQuotaList';

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}


const CompanyUserAcContainer = (props) => {
  const { t } = useTranslation();
  let query = useQuery()
  let id = query.get("id")

  const [userObj, setUser] = useState({});

  useEffect(() => {
    fetchUser()
  }, []);


  const fetchUser = async () => {
    let result = []
    try {
      result = await Service.call('get', `/api/user/${id}`);
      let [mobilePrefix, mobile] = _.split(result.mobile, '-');
      result.mobilePrefix = mobilePrefix;
      result.mobile = mobile;
      setUser(result);
      console.log('result>>>', result)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }
  console.log('userObj>>>', userObj)

  return (
    <NavAndSideFrame {...props} title={`${t('edit')} ${t('user')}`} selectedKey="user_account">
      <Tabs type="card" defaultActiveKey="user_form" onChange={(key) => null}>
        <TabPane tab={t('user_info')} key="user_form">
          <UserAcInfoForm
            initialValues={userObj}
          />
        </TabPane>


        {id > 0 && (
          <TabPane tab={t('user_status')} key="userStatus">
            <UserAcStatusForm
              initialValues={userObj}
            />
          </TabPane>
        )}


        {id > 0 && (
          <TabPane tab={t('edit_password')} key="userPassword">
            <UserAcResetPasswordForm
              initialValues={userObj}
            />
          </TabPane>
        )}

        {id > 0 && (
          <TabPane tab={t('user_quota')} key="userQuota">
            <UserAcQuotaList
              initialValues={userObj}
            />
          </TabPane>
        )}



      </Tabs>

    </NavAndSideFrame>
  )
}

export default CompanyUserAcContainer;

