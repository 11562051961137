import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Button, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';

const debug = debugLib('app:pages/company/webStatusModal');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const CategoryInfoModal = (props) => {
  const { t } = useTranslation()
  const { selectedRecord, categoryList, modalVisible, setModalVisible, handleFormSubmit } = props

  if (selectedRecord) {
    selectedRecord.utime = moment.unix(selectedRecord.utime).format('YYYY-MM-DD HH:mm')
    selectedRecord.ctime = moment.unix(selectedRecord.ctime).format('YYYY-MM-DD HH:mm')
  }

  const [CategoryInfoForm] = Form.useForm();

  useEffect(() => {
    CategoryInfoForm.resetFields()
  }, [selectedRecord])  
  
  return (
    <Modal
      title={selectedRecord.item_category_id === 0 ? t('add_new_record') : t('edit_record')}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false)
      }}
    >
      <Form
        form={CategoryInfoForm}
        name="CategoryInfoForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.company_id = selectedRecord.company_id
          handleFormSubmit(formData)}}
      >
        {/* <Form.Item
          label={t('company_id')}
          name="company_id"
        >
          <Input disabled />
        </Form.Item> */}
        { selectedRecord.item_category_id === 0 ? null :
        (<Form.Item
          label={t('category_id')}
          name="item_category_id"
        >
          <Input disabled />
        </Form.Item>)}

        <Form.Item
          label={t('active_status')}
          name="is_active"
          initialValue={1}
        >
          <Select>
            <Option value={1}>{t('active')}</Option>
            <Option value={0}>{t('inactive')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('name')}
          name="category_name"
          rules={[{ required: true, message: 'Cannot leave empty' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('desc')}
          name="category_desc"
          rules={[{ required: true, message: 'Cannot leave empty' }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label={t('parent_type')}
          name="type"
        >
          <Select>
            {categoryList.map(
              (category)=> {
                return (<Option value={category.item_category_id}>{category.category_name}</Option>)
              }
            )}
          </Select>
        </Form.Item> */}

        <Form.Item
          label={t('sorting')}
          name="sorting"
          rules={[{ required: true, message: 'Need a number' }]}
        >
          <InputNumber />
        </Form.Item>

        {selectedRecord.item_category_id != 0 ?
        <Form.Item
          label={t('create_time')}
          
        >
          <p>{selectedRecord.ctime}</p>
        </Form.Item>
        : null}

        {selectedRecord.item_category_id != 0 ?
        <Form.Item
          label={t('utime')}
        >
          <p>{selectedRecord.utime}</p>
        </Form.Item>
        : null}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CategoryInfoModal;
