export const SET_ADMIN = 'SET_ADMIN';
export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN';
export const SET_COMPANY_USER = 'SET_COMPANY_USER';
export const SET_USER = 'SET_USER';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOADING = 'SET_LOADING';
export const SET_SIDER = 'SET_SIDER';
export const SET_MODAL_VISBLE = 'SET_MODAL_VISBLE';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_DRAWER_SELECTED_ITEM = 'SET_DRAWER_SELECTED_ITEM';

