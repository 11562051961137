
import React from 'react';
import { Radio, Form, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

const QuestionItemPart1 = ({ question }) => {
  const { t, i18n } = useTranslation();
  const { sort_order, question_id } = question;

  return (
    <Col span={24}>
      <Form.Item
        label={(<span style={{ ...styles.question_label }}>{sort_order}. {question[`title_${i18n.language}`]}</span>)}
        name={`question_${question_id}`}
        style={{ ...styles.formItem }}
      >
        <Radio.Group className={`custom-radio question-radio part-2-${i18n.language}`} style={{ width: '100%' }}>
          <Radio value={1}>{t('well_being_level_1')}</Radio>
          <Radio value={2}>{t('well_being_level_2')}</Radio>
          <Radio value={3}>{t('well_being_level_3')}</Radio>
          <Radio value={4}>{t('well_being_level_4')}</Radio>
          <Radio value={5}>{t('well_being_level_5')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Divider
        style={{
          margin: '5px 0px',
          borderTop: '1px solid #6e5333'
        }}
      />
    </Col>
  )
}

const styles = {
  question_label: {
    color: '#fff',
    fontSize: 22,
    fontWeight: 'bold'
  },
  formItem: {
    marginBottom: 0
  }
}

export { QuestionItemPart1 };
