import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Tabs, Spin
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation  } from 'react-i18next';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserAcListTable from './CompanyUserAcListTable';
import CompanyHolidayInfoModal from './CompanyHolidayInfoModal';
import {
  LoadingOutlined
} from '@ant-design/icons';
import queryString from 'query-string';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = "user_management";
const tableIDName = "company_user_id";
const { TabPane } = Tabs;

const CompanyUserAcList = (props) => {

  const [categoryList, setCategoryList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const { t } = useTranslation()
  const [holidayList, setholidayList] = useState([]);
 
  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key


  const title = t('user_info');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let getdataStatusIs1 = [];
    let getdataStatusIs2 = []; //for other status
    let getinactive_dataList = [];


    let getdataList = []
    try {
      let url = "/api/user/list";
      let response = await Service.call('get', url);

      for (let i = 0; i < response.length; i++) {
        if (response[i].status === 1) {
          getdataStatusIs1.push(response[i]);
        } else {
          getdataStatusIs2.push(response[i]);
        }
        if (response[i].is_active === 0) {
          getinactive_dataList.push(response[i]);
        }
      }

      getdataList = response


    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(getdataStatusIs1);
      setDataList2(getdataStatusIs2);
      setInactive_dataList(getinactive_dataList);

      setholidayList(getdataList);
      setLoading(false)
    }
  }

  const handleFormSubmit = async (submitRecord) => {

    submitRecord.date = moment(submitRecord.date).unix()
    let submit = await Service.call('put', '/api/company/holiday', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true)
    let submit = await Service.call('put', '/api/item', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      item_id: selectedRecord.item_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/item', patchData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {

    let postData = {
      "company_holiday_id": deleteRecord
    }
    let submit = await Service.call('put', '/api/company/holiday/delete', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleIsActiveChange = async (item_id, value) => {
    setLoading(true)
    let postData = {
      item_id,
      is_active: value
    }
    let submit = await Service.call('patch', '/api/item/is_active', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Link
        to={{
          pathname: "/company/user/form",
          search: "?id=0",
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            setModalVisible(true)
            setSelectedRecord({ item_id: 0 })
          }}
        >
          {t('add_user')}
        </Button>
      </Link>
      <Divider />

      <Tabs defaultActiveKey={defaultActiveKey}>
        <TabPane tab={t('confirmed')} key="1" >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <CompanyUserAcListTable
              holidayList={dataList}
              deleteRecord={(record) => handleDeleteRecord(record)}
              loading={loading}
              defaultActiveKey={1}
            />
          </Spin>
        </TabPane>
        <TabPane tab={t('pending')} key="2">
          <CompanyUserAcListTable
            holidayList={dataList2}
            deleteRecord={(record) => handleDeleteRecord(record)}
            loading={loading}
            defaultActiveKey={2}
          />
        </TabPane>

        <TabPane tab={t('disabled')} key="3">
          <CompanyUserAcListTable
            holidayList={inactive_dataList}
            deleteRecord={(record) => handleDeleteRecord(record)}
            loading={loading}
            defaultActiveKey={3}
          />
        </TabPane>


      </Tabs>


      {/* <CompanyUserAcListTable
        holidayList={holidayList}
        deleteRecord={(record) => handleDeleteRecord(record)}
        loading={loading}
      /> */}
      {/* <CompanyHolidayInfoModal
        selectedRecord={selectedRecord}
        categoryList={categoryList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      /> */}
    </NavAndSideFrame >
  )
}

export default CompanyUserAcList;