import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Card,
} from "antd";
import moment from "moment";
import * as Main from "../core/Main";
import * as Service from "../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import UserQuotaList from "./UserQuotaList";
import UserQuotaModal from "./UserQuotaModal";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const UserAcQuotaList = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const [number, setNumber] = useState("");
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const { initialValues } = props;

  const [UserForm] = Form.useForm();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    // setLoading(true)
    let dataList = [];
    try {
      let url = `/api/user_quota/list/Bykey/${initialValues.user_id}`;
      let response = await Service.call("get", url);
      dataList = response;

      if (dataList != []) {
        //  user
        url = `/api/user/list/${dataList[0]["company_id"]}`;
        let userArr = await Service.call("get", url);
        let userArrMap = _.keyBy(userArr, "user_id");

        // company user
        url = "/api/company/user";
        let companyUserArr = await Service.call("get", url);
        let companyUserArrMap = _.keyBy(
          companyUserArr["companyUsers"],
          "company_user_id"
        );

        // item info
        url = "/api/item/category/list";
        let categoryArr = await Service.call("get", url);
        let categoryArrMap = _.keyBy(categoryArr, "item_category_id");

        // mapping
        _.each(response, (quotaItem, i) => {
          dataList[i].username =
            userArrMap[quotaItem.user_id].first_name +
            " " +
            userArrMap[quotaItem.user_id].last_name;
          // dataList[i].companyUsername = companyUserArrMap[quotaItem.company_user_id].first_name + ' ' + companyUserArrMap[quotaItem.company_user_id].last_name;
          dataList[i].item_category_id =
            categoryArrMap[quotaItem.item_category_id].item_category_id;
          dataList[i].category_name =
            categoryArrMap[quotaItem.item_category_id].category_name;
        });
      }
    } catch (error) {
      console.error("error >>> ", error);
      // setLoading(false)
    } finally {
      // console.log(dataList);
      setDataList(dataList);
      setLoading(false);
      // setEditOption(false)
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    let submit = await Service.call("patch", "/api/user_quota", submitRecord);
    getAllData();
    setModalVisible(false);
  };

  return (
    <Card span={24}>
      <UserQuotaList
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        setModalVisible={(value) => setModalVisible(value)}
        modalVisible={modalVisible}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        selectedRecord={selectedRecord}
        dataList={dataList}
        getAllData={getAllData}
        // deleteRecord={(record) => handleDeleteRecord(record)}
        particularUserView
        loading={loading}
      />
    </Card>
  );
};

export default UserAcQuotaList;
