import React, { useState, useEffect } from 'react'
import {
  Divider, Row, Col, Input, Button, Modal, Typography,
} from 'antd';
import _ from 'lodash';
import {
  CloseOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MobileView, DesktopView } from './ResponsiveView';

import * as CommonActions from '../../redux/actions/common';
import BackgroundInfoImage_1 from '../../assets/330/newlife_CHI_web_4x-42.png';
import BackgroundInfoImage_2 from '../../assets/330/newlife_CHI_web_4x-37.png';
import BackgroundInfoImage_3 from '../../assets/330/newlife_CHI_web_4x-39.png';
import Action330 from '../../assets/330/newlife_CHI_web_4x-36.png';

const { Text, Title } = Typography;

const BackgroundInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Row style={{ paddingRight: 30 }} gutter={[0, 12]}>
      <Col span={24} style={{ marginBottom: 20 }}>
        <Row justify="end">
          <Col>
            <CloseOutlined style={{ fontSize: 24, color: '#fff' }} onClick={() => dispatch(CommonActions.setModalVisible(false))} />
          </Col>
        </Row>
      </Col>
      <div className="drawer-content-body">
        <Col>
          <Text style={{ ...styles.title, whiteSpace: "pre-wrap" }}>{t('background_info_title')}</Text>
        </Col>
        <Col>
          <Text style={{ ...styles.text, whiteSpace: "pre-wrap" }}>
            {t('background_info_desc')}
          </Text>
        </Col>
        <Col style={{ textAlign: 'center' }}>
          <img
            src={BackgroundInfoImage_1}
            style={{ width: '40%', margin: 'auto' }}
          />
        </Col>
        <Col>
          <Text style={{ color: '#6e5333' }}>{t('reference')}:</Text><br />
          <Text style={{ wordSpacing: 2, hyphens: 'auto', wordBreak: 'break-word', color: '#6e5333' }}>
            World Health Organization (1948) Constitution of the World Health Organization. World Health Organization.<span>https://apps.who.int/gb/bd/PD- F/bd47/EN/constitution-en.pdf?ua=1</span>
          </Text>
        </Col>
        <Divider style={{ color: '#6e5333' }} />
        <Col>
          <Text style={{ ...styles.title, marginBottom: 8 }}>
            {t('330_explain_title')}
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 12]}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Row style={{ textAlign: 'left' }} gutter={[18, 0]}>
                <Col span={8}>
                  <img src={BackgroundInfoImage_2} style={{ width: '100%' }} />
                </Col>
                <Col span={16}>
                  <Title level={2} style={{ ...styles.title }}>{t('330_text_1')}</Title>
                  <Text style={{ ...styles.text }}>{t('330_desc_1')}</Text>
                </Col>
                <MobileView><Divider style={{ color: '#6e5333', borderWidth: 2 }} /></MobileView>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Row style={{ textAlign: 'left' }} gutter={[18, 0]}>
                <Col span={8}>
                  <img src={BackgroundInfoImage_2} style={{ width: '100%' }} />
                </Col>
                <Col span={16}>
                  <Title level={2} style={{ ...styles.title }}>{t('330_text_2')}</Title>
                  <Text style={{ ...styles.text }}>{t('330_desc_2')}</Text>
                </Col>
                <MobileView><Divider style={{ color: '#6e5333', borderWidth: 2 }} /></MobileView>

              </Row>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Row style={{ textAlign: 'left' }} gutter={[18, 0]}>
                <Col span={8}>
                  <img src={BackgroundInfoImage_3} style={{ width: '100%' }} />

                </Col>
                <Col span={16}>
                  <Title level={2} style={{ ...styles.title }}>{t('330_text_3')}</Title>
                  <Text style={{ ...styles.text }}>{t('330_desc_3')}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider style={{ color: '#fff', borderWidth: 2 }} />
        <Row>
          <Text style={{ ...styles.text, whiteSpace: "pre-wrap" }}>
            {t('wbs_desc')}
          </Text>
        </Row>
        <Row gutter={[30, 0]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Text style={{ ...styles.text }}>
              {t('background_info_text_1')}:</Text>
            <br />
            <ul className="custom-ul-bullet">
              <li><Text style={{ ...styles.text }}>{t('background_info_point_1')}</Text></li>
              <li><Text style={{ ...styles.text }}>{t('background_info_point_2')}</Text></li>
            </ul>
            <Text style={{ ...styles.text }}>
              {t('background_info_text_2')}:</Text>
            <ul className="custom-ul-bullet">
              <li><Text style={{ ...styles.text }}>{t('background_info_point_3')}</Text></li>
              <li><Text style={{ ...styles.text }}>{t('background_info_point_4')}</Text></li>
              <li><Text style={{ ...styles.text }}>{t('background_info_point_5')}</Text></li>
            </ul>
          </Col>
        </Row>
        <Col>
          <Button
            style={{
              ...styles.button,
              borderColor: 'transparent',
              backgroundColor: '#6e5333',
              color: '#d4a12c',
              fontSize: 20,
              margin: '20px 0px 40px 0px',
              width: 'min-content'
            }}
            onClick={() => {
              dispatch(CommonActions.setDrawerSelectedItem('questionnaire_form'));
              dispatch(CommonActions.setModalVisible(true));
            }}
          >
            {t('questionnair_button')}
          </Button>
          <Divider style={{color: '#fff', borderWidth: 2}} />
        </Col>
        <Col>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'center' }}>
              <img alt="" src={Action330} style={{ width: '100%', margin: 'auto' }} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Text level={2} style={{ ...styles.text }}>
                {t('background_info_text_3')}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={12} >
              <a href="https://dayday330.newlife330.hk/#supportDay" target="_blank">
                <Button
                  style={{
                    ...styles.button,
                    borderColor: 'transparent',
                    backgroundColor: '#6e5333',
                    color: '#d4a12c',
                    fontSize: 20,
                  }}
                >
                  {t('button_sign')}
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Divider style={{ color: '#fff', borderWidth: 2 }} />
        <Col>
          <Text style={{ ...styles.referenceText }}>
            {t("reference")}
            <br />
            <br />
          </Text>
          <Text style={{ ...styles.referenceText }}>
            Chirico F. Spiritual well-being in the 21st century: It’s time to
            review the current WHO’s health definition? J Health Soc Sci.
            2016;1(1):11–6
            <br />
            <br />
            Keyes, C. L. M. (2002). The mental health continuum: From
            languishing to flourishing in life. Journal of Health and Social
            Behavior, 43, 207–222.
            <br />
            <br />
            Keyes,. L. M. (2004). The nexus of cardiovascular disease and
            depression revisited: The complete mental health perspective and the
            moderating role of age and gender. Aging and Mental Health, 8,
            266–274.
            <br />
            <br />
            Keyes, C. L. M. (2005). Mental illness and/or mental health?
            Investigating axioms of the complete state model of health. Journal
            of Consulting and Clinical Psychology, 73, 539–548.
            <br />
            <br />
            Keyes, C. L. M. (2006). Mental health in adolescence: Is America’s
            youth flourishing? American Journal of Orthopsychiatry, 76, 395–402.
            <br />
            <br />
            Keyes, C. L. M. (2007). Promoting and protecting mental health as
            flourishing: A complementary strategy for improving national mental
            health. American Psychologist, 62, 95–108.
            <br />
            <br />
            Keyes, C. L. M., Wissing, M., Potgieter, J., Temane, M., Kruger, A.,
            & van Rooy, S. (2008). Evaluation of the Mental Health
            <br />
            <br />
            Continuum—Short Form (MHC-SF) in Swetsana-speaking South Africans.
            Clinical Psychology and Psychotherapy, 15, 181–192.
            <br />
            <br />
            Linton, M. J., Dieppe, P., & Medina-Lara, A. (2016). Review of 99
            self-report measures for assessing well-being in adults: exploring
            dimensions of well-being and developments over time. BMJ open, 6(7),
            e010641.
            <br />
            <br />
            Westerhof, G. J., & Keyes, C. L. M. (2008). Geestelijke gezondheid
            is meer dan de afwezigheid van geestelijke ziekte [Mental health is
            more than the absence of mental illness]. Maandblad Geestelijke
            Volksgezondheid, 63, 808–820
            <br />
            <br />
            Sham, W. W., Yeung, G. T., Mak, W. W., & Powell, C. L. (2021).
            Comprehensive well-being scale: development and validation among
            Chinese in recovery of mental illness in Hong Kong. BMC
            Psychology, 9, 1-12.
          </Text>
        </Col>
      </div>
    </Row>
  )
}

const styles = {
  button: {
    height: 50,
    fontWeight: 'bold',
    borderColor: '#fff',
    color: '#d7633e',
    fontSize: 24,
    width: '100%',
    borderRadius: 12
  },
  title: {
    color: '#d7633e',
    fontSize: 30,
    fontWeight: 'bold',
  },
  text: {
    color: '#6e5333',
    fontSize: 17,
    fontWeight: '500'
  },
  referenceText: {
    color: '#6e5333',
    fontSize: 13,
    fontWeight: '500',
    whiteSpace: 'pre-wrap'
  }
}


export default BackgroundInfo;
