import _ from 'lodash';
import * as types from './types';
// import AsyncStorage from '@react-native-community/async-storage';
// import * as Main from '../../core/Main';

export function setConfig(appConfig) {
  return {
    type: types.SET_CONFIG,
    data: appConfig,
  };
}

export function setAdmin(admin) {
  return {
    type: types.SET_ADMIN,
    data: admin
    // data: _.pick(admin, [
    //   'admin_id',
    //   'email',
    //   'mobile',
    //   'level',
    //   'admin_photo',
    //   'admin_role',
    //   'is_active',
    //   'status',
    //   'username',
    //   'nickname'
    // ])
  };
}

export function setCompanyAdmin(company_admin) {
  return {
    type: types.SET_COMPANY_ADMIN,
    data: company_admin
  }
}

export function setCompanyUser(company_user) {
  return {
    type: types.SET_COMPANY_USER,
    data: company_user
  }
}

export function setUser(user) {
  return {
    type: types.SET_USER,
    data: user
  }
}

export function setAuth(state) {
  return {
    type: types.SET_AUTH,
    data: state
  };
}

export function setOrigin(admin) {
  return {
    type: types.SET_ORIGIN,
    data: admin
  };
}

export function setLoading(sider_status) {
  return {
    type: types.SET_LOADING,
    data: sider_status
  };
}

// export function setSider(sidebarVisible) {
//   return {
//     type: types.SET_SIDER,
//     data: sidebarVisible
//   };
// }

export function toggleSider(visible) {
  return {
    type: types.SET_SIDER,
    data: visible,
  };
}

export function setModalVisible(visible) {
  return {
    type: types.SET_MODAL_VISBLE,
    data: visible,
  };
}

export function setFormData(data) {
  return {
    type: types.SET_FORM_DATA,
    data: data,
  };
}

export function setDrawerSelectedItem(data) {
  return {
    type: types.SET_DRAWER_SELECTED_ITEM,
    data: data,
  };
}
// export function setCompany(company) {
//   return {
//     type: types.SET_COMPANY,
//     data: company
//   };
// }
