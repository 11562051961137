import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, Divider, InputNumber, Tooltip, Row, Col, Spin, Table, Modal, Popconfirm
} from 'antd';
import {
  LoadingOutlined, EditOutlined, RadarChartOutlined, CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const selectedKey = "survey";
const tableIDName = "question_id";
const title = "問卷詳情";

const SurveyFormContainer = () => {
  let location = useLocation();
  const { t } = useTranslation()
  const { survey_id } = queryString.parse(location.search);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="問題" key="1">
          <QuestionList survey_id={survey_id} />
        </TabPane>
        <TabPane tab="回應" key="2">
          <ResponseUserList survey_id={survey_id} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const QuestionList = (props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [question, setQuestion] = useState(false);

  useEffect(() => {
    setLoading(true)
    getInitialData();
    setLoading(false)
  }, []);

  const getInitialData = async () => {
    const surveyData = await Service.call('get', `/api/question?survey_id=${props.survey_id}`);
    setDataSource(_.orderBy(surveyData, ['sort_order', 'asc']))
  }

  const getColumns = () => {
    return [
      {
        title: "操作",
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <Row gutter={[8, 0]}>
              <Col>
                <Tooltip title="編緝">
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setModalVisible(true);
                      form.setFieldsValue(record);
                      setQuestion(record)
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={record.status === 1 ? "停用" : "啟用"}>
                  <Popconfirm
                    title={`確定要${record.status === 1 ? "停用" : "啟用"}?`}
                    onConfirm={async () => {
                      const nextStatus = record.status === 1 ? 0 : 1
                      await Service.call('patch', '/api/question', { question_id: record.question_id, status: nextStatus });
                      getInitialData();
                    }}
                  >
                    <Button
                      shape="circle"
                      style={{ marginRight: 4 }}
                      icon={record.status === 1 ? <CloseOutlined /> : <CheckOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: '排序',
        dataIndex: 'sort_order',
        sorter: (a, b) => a.sort_order - b.sort_order
      },
      {
        title: '編號',
        dataIndex: 'question_id',
        sorter: (a, b) => a.question_id - b.question_id
      },
      {
        title: '使用中',
        dataIndex: 'status',
        filters: [
          { text: '已停用', value: 0 },
          { text: '使用中', value: 1 },
        ],
        defaultFilteredValue: ['1'],
        onFilter: (value, record) => record.status == value,
        render: (value) => {
          if (value === 0) return '否'
          if (value === 1) return '是'
        }
      },
      {
        title: '類型',
        dataIndex: 'type',
        filters: [
          { text: 'CWBS F1', value: 'cwbs_f1' },
          { text: 'CWBS F2', value: 'cwbs_f2' },
        ],
        onFilter: (value, record) => record.type === value,
        render: (value) => {
          if (value === 'cwbs_f1') return 'CWBS F1'
          if (value === 'cwbs_f2') return 'CWBS F2'
        }
      },
      {
        title: '問題(中文)',
        dataIndex: 'title_zh_hant'
      },
      {
        title: '問題(英文)',
        dataIndex: 'title_en'
      }
    ]
  }

  const onFinish = async (formData) => {
    if (!question) {
      await Service.call('post', '/api/question', {
        ...formData,
        survey_id: props.survey_id
      });
      await getInitialData();
      setModalVisible(false);
      return
    }

    let dataObj = {
      ...formData,
      question_id: question.question_id,
    }
    await Service.call('patch', '/api/question', dataObj);
    await getInitialData();
    setModalVisible(false);
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setQuestion(null)
          form.resetFields();
        }}
      >
        新增問題
      </Button>
      <Divider />
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataSource}
          columns={getColumns()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
          loading={loading}
        />
      </Spin>
      <Modal
        title="編緝問題"
        closable
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="類型"
            name="type"
          >
            <Select>
              <Option value="cwbs_f1">CWBS F1</Option>
              <Option value="cwbs_f2">CWBS F2</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="題目(中文)"
            name="title_zh_hant"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="題目(英文)"
            name="title_en"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="順序"
            name="sort_order"
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">確定</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const ResponseUserList = (props) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [modalVisible, setModalVisible] = useState(false);
  // const [question, setQuestion] = useState(false);

  useEffect(() => {
    setLoading(true)
    getInitialData();
    setLoading(false)
  }, []);

  const getInitialData = async () => {
    let dataSource = await Service.call('get', `/api/response_user/list`);
    console.log('dataSource', dataSource);
    setDataSource(_.orderBy(dataSource, ['ctime'], ['desc']))
  }

  const getColumns = () => {
    return [
      {
        title: "操作",
        dataIndex: 'response_user_id',
        render: (value, record, key) => {
          return (
            <Row>
              <Col>
                <Tooltip title="數據">
                  <Link to={{
                    pathname: '/company/survey/user/response',
                    search: `?session_id=${record.session_id}&survey_id=${props.survey_id}`
                  }}>
                    <Button
                      shape="circle"
                      style={{ marginRight: 4 }}
                      icon={<RadarChartOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '年齡',
        dataIndex: 'age',
      },
      {
        title: '性別',
        dataIndex: 'gender',
        render: (value) => t(value)
      },
      {
        title: '婚姻狀況',
        dataIndex: 'marital_status',
        render: (value) => t(value)
      },
      {
        title: '教育程度',
        dataIndex: 'edu_level',
        render: (value) => t(value)
      },
      {
        title: '職業',
        dataIndex: 'job',
        render: (value) => t(value)
      },
      {
        title: '已簽署約章',
        dataIndex: 'is_signed',
        render: (value) => value === 0 ? '否' : '是'
      },
      {
        title: '簽署時間',
        dataIndex: 'signed_time',
        render: (value) => t(value)
      },
      {
        title: '不同意接收資訊',
        dataIndex: 'optout',
        render: (value) => t(value === 1 ? 'yes' : 'no')
      },
      {
        title: '提交時間',
        dataIndex: 'ctime',
        render: (value) => moment.unix(value).format('YYYY-MM-DD')
      },
    ]
  }

  const exportReport = async () => {
    setLoading(true);
    let surveyQuestions = await Service.call('get', `/api/question/listAll`);
    let responseList = await Service.call('get', `/api/response/list`);
    let responseUser = await Service.call('get', `/api/response_user/list`);
    responseList = _.groupBy(responseList, 'session_id');
    surveyQuestions = _.keyBy(surveyQuestions, 'question_id');

    let responseListMap = {}

    _.each(responseList, (item, key) => {
      let questionMap = {}
      let f1 = [];
      let f2 = [];
      _.map(item, (response) => {
        let question = surveyQuestions[response.question_id]
        if (!_.isEmpty(question)) {
          if (question.type === 'cwbs_f1') {
            f1.push({ answer: response.answer, sort_order: question.sort_order, question_id: response.question_id });
          }
          if (question.type === 'cwbs_f2') {
            f2.push({ answer: response.answer, sort_order: question.sort_order, question_id: response.question_id });
          }
        }
      });
      questionMap.f1 = f1;
      questionMap.f2 = f2;
      responseListMap[key] = questionMap;
    });

    let exportList = []

    _.map(responseUser, (item) => {
      let responses = responseListMap[item.session_id];
      if (!responses) return;
      let { f1, f2 } = responses;
      f1 = _.orderBy(f1, 'sort_order')
      f2 = _.orderBy(f2, 'sort_order')
      debug('CWBS F1', f1)
      debug('CWBS F2', f2)
      let questionObj = {};

      // for (const item of f1) {
      //   _.assign(questionObj, { [`${item.sort_order}-F1-${item.question_id})`]: item.answer })
      // }

      // for (const item of f2) {
      //   _.assign(questionObj, { [`${item.sort_order}-F2-${item.question_id})`]: item.answer })
      // }

      f1.map((f1Item) => _.assign(questionObj, { [`${f1Item.sort_order}-F1 id: ${f1Item.question_id}`]: f1Item.answer }))

      f2.map((f2Item) => _.assign(questionObj, { [`${f2Item.sort_order}-F2 id: ${f2Item.question_id}`]: f2Item.answer }))


      // Sort by question order instead of grouping f1 and f2
      const sortedQuestionObj = {}

      const sortedQuestionKeys = Object.keys(questionObj).sort((a, b) => Number(a.split('-')[0]) - Number(b.split('-')[0]))

      // for (const key of sortedQuestionKeys) {
      //   _.assign(sortedQuestionObj, { [key]: questionObj[key] })
      // }

      sortedQuestionKeys.map((key) => _.assign(sortedQuestionObj, { [key]: questionObj[key] }))

      // console.log('item.session_id', item.session_id);
      exportList.push({
        id: item.response_user_id,
        session_id: item.session_id,
        name: item.name,
        email: item.email,
        age: item.age,
        marital_status: t(item.marital_status),
        edu_level: t(item.edu_level),
        is_signed: item.is_signed ? '是' : '否',
        signed_time: t(item.signed_time),
        gender: t(item.gender),
        optout: item.optout ? '是' : '否',
        create_time: moment.unix(item.ctime).format('YYYY-MM-DD'),
        'Total CWBS F1': _.sumBy(responses.f1, 'answer'),
        'Total CWBS F2': _.sumBy(responses.f2, 'answer'),
        ...sortedQuestionObj,
      })
    });


    setLoading(false)
    console.log(exportList);
    Main.exportToCSV(exportList, 'excel');
    console.log({surveyQuestions, responseList, responseUser, responseListMap});
  }

  return (
    <>
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <Button
          loading={loading}
          type="primary"
          onClick={exportReport}
        >
          Export CSV
        </Button>
        <Divider />
        <Table
          rowKey="response_user_id"
          scroll={{ x: 'max-content' }}
          dataSource={dataSource}
          columns={getColumns()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
          loading={loading}
        />
      </Spin>
    </>
  )
}

export default SurveyFormContainer;
