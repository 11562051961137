import React, { useEffect, useState } from 'react';
import { Grid } from 'antd';
import _ from 'lodash';

const { useBreakpoint } = Grid;

const MobileView = ({ children }) => {
  const screens = useBreakpoint();
  const [element, setElement] = useState(null);
  useEffect(() => {
    const isMobile = (screens.xs || screens.sm) && !screens.md;
    if (isMobile) {
      setElement(children)
    } else {
      setElement(null)
    }
  }, [screens]);
  
  return <>{element}</>
}

const DesktopView = ({ children }) => {
  const screens = useBreakpoint();
  const [element, setElement] = useState(null);


  useEffect(() => {
    let isDesktop = screens.md || screens.lg || screens.xl || screens.xxl;
    if (isDesktop) {
      setElement(children)
    } else {
      setElement(null)
    }


  }, [screens]);
  
  return <>{element}</>
}

export { MobileView, DesktopView }
