
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col
} from 'antd';
import _ from 'lodash';
import fb from '../../assets/330/newlife_CHI_web_4x-29.png';
import instagram from '../../assets/330/newlife_CHI_web_4x-30.png';
import youtube from '../../assets/330/newlife_CHI_web_4x-31.png';
import email from '../../assets/330/newlife_CHI_web_4x-32.png';

const MediaGroup = (props) => {
  return (
    <Row gutter={[12, 0]}>
      <Col>
        <a href="https://www.facebook.com/newlife330hk/" target="_blank" rel="noreferrer" >
          <img src={fb} style={{ ...styles.icon, }} />
        </a>
      </Col>
      <Col>
        <a href="https://www.instagram.com/newlife330hk/" target="_blank" rel="noreferrer" >
          <img src={instagram} style={{ ...styles.icon, }} />
        </a>
      </Col>
      <Col>
        <a href="https://www.youtube.com/@330newlife9" target="_blank" rel="noreferrer" >
          <img src={youtube} style={{ ...styles.icon, }} />
        </a>
      </Col>
      <Col>
        <a href="mailto:dayday330@nlpra.org.hk" target="_blank" rel="noreferrer" >
          <img src={email} style={{ ...styles.icon, }} />
        </a>
      </Col>
    </Row>
  )
}

const styles = {
  icon: {
    width: 20,
    height: 20
  }
}

export default MediaGroup;
