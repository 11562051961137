/* eslint-disable import/prefer-default-export */

const getTypeFromValue = (value) => {
  if (value >= 79) {
    return 3;
  }

  if (value <= 65) {
    return 1;
  }

  return 2;
};

export const getAssetsFromData = (data) => {
  const { cwbs_f1: f1, cwbs_f2: f2 } = data;

  // Debug use only
  // const f1 = 36;
  // const f2 = 39;
  const type = getTypeFromValue(f1 + f2);

  const assets = {
    typeValue: type,
    title: `result_${type}_title`,
    desc: `result_${type}_desc`,
  };

  return assets;
};
