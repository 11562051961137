import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Button, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal
} from 'antd';
import moment from 'moment';
import * as Main from '../core/Main';
import * as Service from '../core/Service';
import NavAndSideFrame from './NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';

const debug = require('debug')('app:admin:client:src:pages:company:UserQuotaModal');

const { Option } = Select;

const UserQuotaModal = (props) => {
  const { t } = useTranslation()
  let location = useLocation();
  let { id } = queryString.parse(location.search);
  const [itemCategoryFilter, setItemCategoryFilter] = useState(0)
  const [itemCategory, setItemCategory] = useState([])
  const [userObj, setUserObj] = useState([])
  const [userID, setUserID] = useState(0)
  const [user_quota_amount, setUserQuotaAmount] = useState(0)
  const [formType, setformType] = useState(0);
  const { selectedRecord, modalVisible, setModalVisible, handleFormSubmit, particularUserView } = props
  // let setUserQuotaAmount = 0

  // if (selectedRecord) {
  //   selectedRecord.utime = moment.unix(selectedRecord.utime).format('YYYY-MM-DD HH:mm')
  //   selectedRecord.ctime = moment.unix(selectedRecord.ctime).format('YYYY-MM-DD HH:mm')
  // }

  const [QuotaInfoForm] = Form.useForm();

  useEffect(() => {
    QuotaInfoForm.resetFields()
    setUserQuotaAmount(selectedRecord.quota_amount)
    setItemCategoryFilter(selectedRecord.item_category_id)
    if (id) {
      setUserID(_.toInteger(id))
    }
  }, [selectedRecord])


  useEffect(() => {
    getItemCategoryList();
    getUserList();
  }, []);

  const getItemCategoryList = async () => {
    let itemCategoryArr = await Service.call('get', "/api/item/category/list");
    setItemCategory(itemCategoryArr)
  }

  const getUserList = async () => {
    try {
      let user_ListArr = await Service.call('get', "/api/user/list");
      setUserObj(user_ListArr)
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const handleUserChange = (user_id) => {
    getUserQuota(user_id, itemCategoryFilter)
  }

  const getUserQuota = async (user_id, item_categoryID) => {
    setUserID(user_id)

    if (item_categoryID > 0) {
      let user_ListArr = await Service.call('get', `/api/user_quota/list/${user_id}/${item_categoryID}`);
      if (!_.isUndefined(user_ListArr.data)) {
        setUserQuotaAmount(user_ListArr.data.quota_amount)
        setformType(1)
      } else {
        setUserQuotaAmount(0)
        setformType(0)
      }
    }
  }

  const handleItemCategoryFilterChange = (value) => {
    // value : Category ID
    if (value === 0) {
      setItemCategoryFilter(0)
    }
    if (value > 0) {
      setItemCategoryFilter(value)
    }
    getUserQuota(userID, value)
  }

  return (
    <Modal
      title={formType === 0 ? t('add_new_record') : t('edit_record')}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false)
      }}
    >
      <Form
        form={QuotaInfoForm}
        name="UserQuotaForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.company_id = selectedRecord.company_id
          handleFormSubmit(formData)
        }}
      >

        <Form.Item
          label={t('user')}
          name="user_id"
        >
          <Select
            showSearch
            disabled={particularUserView}
            onChange={handleUserChange}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              _.map(userObj, (user_itemRc) => <Option key={user_itemRc.user_id} value={user_itemRc.user_id}>{user_itemRc.first_name + " " + user_itemRc.last_name}</Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label={t('category')}
          name="item_category_id"
        >
          <Select
            // defaultValue={0}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Category"
            optionFilterProp="children"
            onChange={(value) => handleItemCategoryFilterChange(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              _.map(itemCategory, (itemCategoryRc) => <Option key={itemCategoryRc.item_category_id} value={itemCategoryRc.item_category_id}>{itemCategoryRc.category_name}</Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item label={t('user_quota')} name="user_quota">
          {user_quota_amount}
        </Form.Item>

        <Form.Item
          label={t("change_amount")}
          name="change_amount"
          rules={[{ required: true, message: "Need a number" }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UserQuotaModal;
