import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../t9UIComponents/t9Button';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import MyBookingCalendar from './MyBookingCalendar';
import { useSelector } from 'react-redux';


const debug = require('debug')('app:admin:client:src:AdvertisementList');

const selectedKey = 3;

const MyBookingCalendarContainer = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const { Option } = Select;
  const [categoryList, setCategoryList] = useState([]);
  const [ItemList, setItemList] = useState([]);
  const [BookingItem, setBookingItem] = useState([]);
  const [BookingStatusItem, setBookingStatusItem] = useState("0");
  const [SelectedItemList, setSelectedItemList] = useState()
  const [calendarEvents, setCalendarEvents] = useState([])
  const [itemCategory, setItemCategory] = useState([])
  const [itemCategoryFilter, setItemCategoryFilter] = useState(0)
  const [item, setItem] = useState([])
  const [itemFilter, setItemFilter] = useState("")

  // const [nextWeek, setnextWeek] = useState(0);
  // const [previousWeek, setpreviousWeek] = useState(0);

  // const [bookingItemStatus, setBookingItemStatus] = useState(0)
  const [bookingItemStatusFilter, setBookingItemStatusFilter] = useState(0)

  // For if user choose "All" catergory (First Select button)
  // if isFiltered == 0 , the item select button (second select button) disabled , else , abled
  const [isFiltered, setisFiltered] = useState(0)

  const [isStatus, setisStatus] = useState(0)


  let status_key = 0
  let user_id = useSelector(state => state.app.user.user_id)

  const title = t('my_booking');

  useEffect(() => {
    setLoading(true)
    getItemCategoryList();
    getItemList();
    getBookingItem(); // Booking infor
    setLoading(false)
  }, []);

  useEffect(() => {
    getBookingItem();
    // getItemList();
  }, [itemCategoryFilter, itemFilter, bookingItemStatusFilter])

  const getItemCategoryList = async () => {
    let itemCategoryArr = await Service.call('get', "/api/item/category/list");
    setItemCategory(itemCategoryArr)
  }

  const getItemList = async () => {
    let itemArr = await Service.call('get', "/api/item/list");
    // Filter Items
    if (itemCategoryFilter !== 0) {
      itemArr = _.filter(itemArr, ['item_category_id', itemCategoryFilter]);
    }
    setItem(itemArr)
  }

  const handleItemCategoryFilterChange = (value) => {
    if (value === 0) {
      setItemCategoryFilter(0)
    }
    if (value > 0) {
      setItemCategoryFilter(value)
    }
  }

  const handleItemFilterChange = (value) => {
    if (value.length > 0) {
      setItemFilter(value)
    }
  }

  const handleStatusFilterChange = (value) => {
    setBookingItemStatusFilter(value)
  }
  let nextWeek = 0
  let previousWeek = 0
  let thisweek_startof = moment().startOf('week').unix()
  let thisweek_endof = moment().endOf('week').unix()

  const selectDate = (prevOrNext, focusWeek) => {

    nextWeek = moment(focusWeek).endOf('week').unix()
    previousWeek = moment(focusWeek).startOf('week').unix()

    if (nextWeek !== 0) {
      thisweek_endof = nextWeek
    }

    if (previousWeek !== 0) {
      thisweek_startof = previousWeek
    }

    getBookingItem()
  }

  const getBookingItem = async () => {
    setLoading(true)
    let calendarData = []

    try {
      // Get & format calander data
      let itemArr = await Service.call('get', "/api/item/list");
      let itemArrMap = _.keyBy(itemArr, "item_id")

      // let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/1610586000/1610812799`)
      let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/${thisweek_startof}/${thisweek_endof}`)

      calendarData = _.filter(_.filter(bookingItemArr, 'status'), ['user_id', user_id]) 

      // Mapping Data
      _.each(calendarData, (bookingItem, i) => {
        calendarData[i].item_name = itemArrMap[bookingItem.item_id].item_name
        calendarData[i].title = itemArrMap[bookingItem.item_id].item_name
        // if (_.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1) {
        //   calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
        // }
        calendarData[i].item_category_id = itemArrMap[bookingItem.item_id].item_category_id
        calendarData[i].start = moment.unix(bookingItem.start_time).toDate()
        calendarData[i].end = moment.unix(bookingItem.end_time).toDate()
      })

      // Filter Items Category
      if (itemCategoryFilter !== 0) {
        calendarData = _.filter(calendarData, ['item_category_id', itemCategoryFilter]);
      }

      // Filter Items
      if (itemFilter.length > 0) {
        let calendarDataFiltered = []
        _.each(itemFilter, (itemFilterRc) => {
          itemFilterRc = _.toInteger(itemFilterRc)
          calendarDataFiltered.push(_.filter(calendarData, ['item_id', itemFilterRc]))
          calendarDataFiltered = _.flatten(calendarDataFiltered)
        })
        calendarData = calendarDataFiltered
      }

      // Filter Status
      if (bookingItemStatusFilter !== 0) {
        calendarData = _.filter(calendarData, ['status', bookingItemStatusFilter]);
      }

      console.log("test123", calendarData)

    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setCalendarEvents(calendarData)
    }
    setLoading(false)
  }
  //this is for test call back .
  const callback = async () => {
    console.log("call back")
  }


  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Row gutter={12}>
        <Col>
          <p>{t("category")}</p>
          <Select
            defaultValue={0}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Category"
            optionFilterProp="children"
            onChange={(value) => handleItemCategoryFilterChange(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option key={0} value={0}>All</Option>
            {
              _.map(itemCategory, (itemCategoryRc) => <Option key={itemCategoryRc.item_category_id} value={itemCategoryRc.item_category_id}>{itemCategoryRc.category_name}</Option>)
            }
          </Select>
        </Col>
        <Col>
          <p>{t("item")}</p>
          <Select
            mode="multiple"
            style={{ width: 300 }}
            placeholder="Search to Item"
            disabled={itemCategoryFilter > 0 && item.length > 0 ? false : true}
            // value={(isFiltered) ? SelectedItemList : []}
            onChange={(value) => handleItemFilterChange(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              _.map(item, (itemRc) => <Option key={itemRc.item_id}>{itemRc.item_name}</Option>)
            }
          </Select>
        </Col>
        <Col>
          <p>{t("status")}</p>
          <Select
            defaultValue={0}
            style={{ width: 200 }}
            placeholder="Search Status"
            onChange={(value) => handleStatusFilterChange(value)}
          >
            <Option value={0} key={0}>All</Option>
            <Option value={1} key={1}>Available</Option>
            <Option value={10} key={10}>Booked</Option>
          </Select>
        </Col>
      </Row>
      <Divider />
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <MyBookingCalendar
          BookingItem={calendarEvents}
          loading={loading}
          callBack={() => getBookingItem()}
          selectDateCallBack={(prevOrNext, focusWeek) => selectDate(prevOrNext, focusWeek)}
        />
      </Spin>
      <Divider />
    </NavAndSideFrame>
  )
}

export default MyBookingCalendarContainer;