import React, { Component, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import debugLib from 'debug';
import { useDispatch } from 'react-redux';
import { setConfig, setAdmin, setAuth, setCompanyAdmin, setCompanyUser, setUser } from './redux/actions/common'
import * as Service from './core/Service';
import _ from 'lodash';
import AppRoute from './AppRoute';
// import * as Service from './core/Service';

debugLib.enable('app:*');

let configOrigin = '';

message.config({
  top: '75%',
  maxCount: 1,
});

const AppContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    // setLoading(true);

    let resp = await Service.call('get', '/api/config');
    if (resp && resp.status > 0) {
      dispatch(setConfig(resp));
    } else {
      throw new Error('failed to get app config');
    }

    resp = await Service.call('get', `/api/login/admin`);
    if (resp && resp.status > 0) {
      dispatch(setAdmin(resp.userData[0]));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    // resp = await Service.call('get', `/api/login/get_company_admin`);
    // if (resp && resp.status > 0) {
    //   dispatch(setCompanyAdmin(resp.userData));
    //   dispatch(setAuth(true));
    //   setLoading(false);
    //   return;
    // }

    resp = await Service.call('get', `/api/login/get_company_user`);
    if (resp && resp.status > 0) {
      dispatch(setCompanyUser(resp.userData));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    resp = await Service.call('get', `/api/login/get_user`);
    if (resp && resp.status > 0) {
      dispatch(setUser(resp.userData));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    dispatch(setAuth(false));
    setLoading(false);
  }
  // async componentDidMount() {
  //   let allPermission = [];
  //   console.log('originObj>>>', originObj)
  //   if (originObj) {
  //     configOrigin = originObj.origin;
  //     allPermission = originObj.allPermission;
  //     // allPermission = originObj.allPermission;
  //   }
  //   getStore().dispatch(ActionCreators.setOrigin(configOrigin));

  //   let resp = await Service.call('get', '/api/admin');
  //   // console.log(`resp >> `, resp);

  //   if (!resp || resp.status <= 0) {
  //     // invalid session
  //   } else {
  //     this.props.setAdmin(resp);
  //     this.props.setAuth(true);
  //   }

  //   // Set Permission By CSS
  //   let myPermssion = []
  //   myPermssion = resp.permission.split(",")
  //   // console.log('myPermssion>>>', resp.permission)
  //   // const myPermssion = ['p_news', 'p_admin'];
  //   if (!_.includes(myPermssion, 'p_superadmin')) {
  //     const missingPermission = _.difference(allPermission, myPermssion);
  //     console.log('missingPermission>>>', missingPermission)
  //     const el = document.getElementsByTagName('body')[0];

  //     _.each(missingPermission, (perm) => {
  //       el.classList.add(`${perm}_none`);
  //     });
  //   }

  //   this.setState({loading: false});
  // }

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <div>
      <AppRoute />
    </div>
  );
}

export default AppContainer;